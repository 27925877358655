import React from 'react'
import Box from '@mui/material/Box'
import SpaceBetweenSection from '../Elements/SpaceBetweenSection'
import { Skeleton } from '@mui/material'

export const SpaceBetweenSectionPlaceholder: React.FC = () => {
  return (
    <SpaceBetweenSection
      leftAlignContent="center"
      left={
        <Box minWidth={'180px'}>
          <Skeleton width="100%" variant="rounded" animation="pulse" />
        </Box>
      }
      right={
        <Box minWidth={'150px'} alignContent="center">
          <Skeleton
            width="100"
            variant="rounded"
            animation="pulse"
            height="36.5px"
          />
        </Box>
      }
    />
  )
}
