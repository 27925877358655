import { UserCommunity } from '../api/swagger'

export enum RequestType {
  UNIQUE_ROLES_FOR_ACTOR = 'UNIQUE_FOR_ROLES',
  All_ACTIVE_ROLES = 'All_ACTIVE_ROLES',
}

/**
 * Retrieves distinct roles for a user within different communities based on the provided parameters.
 *
 * @param {Object} params - The parameters for fetching roles.
 * @param {UserCommunity[]} params.userCommunitiesWithRoles - The list of user communities with their roles.
 * @param {boolean} [params.includeOnlyActiveRoles=true] - A flag indicating whether to include only active roles.
 * @param {RequestType} [params.requestType=RequestType.UNIQUE_ROLES_FOR_ACTOR] - The type of request to determine the return values.
 *
 * @returns {UserCommunity[]} - An array of distinct user communities based on the roles and the request type.
 * 
 * The function processes the roles as follows:
 * - It separates communities into `parentCommunities` (those without an actorKey) and `userRoles` (valid roles based on the hasActiveRoles flag).
 * - If `includeOnlyActiveRoles` is false, it merges roles that share the same actorKey, concatenating their communities while preserving the order.
 * - Depending on the `requestType`, it returns either unique roles by actorKey or communityKey, ensuring no duplicates.
 */

export const distinctRolesByRequest = ({
  userCommunitiesWithRoles,
  includeOnlyActiveRoles = true,
  requestType = RequestType.UNIQUE_ROLES_FOR_ACTOR,
}: {
  userCommunitiesWithRoles: UserCommunity[]
  includeOnlyActiveRoles?: boolean
  requestType?: RequestType
}): UserCommunity[] => {
  let userRoles: UserCommunity[] = []
  const parentCommunities: UserCommunity[] = []

  userCommunitiesWithRoles.forEach((userCommunity) => {
    if (!userCommunity.actorKey && includeOnlyActiveRoles) {
      parentCommunities.push(userCommunity)
    } else if (userCommunity.validNow === includeOnlyActiveRoles) {
      userRoles.push(userCommunity)
    }
  })

  if (!includeOnlyActiveRoles) {
    const mergedCommunities = userRoles.reduce(
      (acc: UserCommunity[], current) => {
        const existing = acc.find((item) => item.actorKey === current.actorKey)

        if (existing) {
          existing.community = `${existing.community}, ${current.community}`
        } else {
          acc.push({ ...current })
        }

        return acc
      },
      []
    )

    userRoles = Object.values(mergedCommunities)
  }

  if (requestType === RequestType.All_ACTIVE_ROLES) {
    return [...parentCommunities, ...userRoles]
  }

  return [
    ...parentCommunities,
    ...new Map<number | undefined, UserCommunity>(
      userRoles.map((role) => [role.actorKey, role])
    ).values(),
  ]
}
