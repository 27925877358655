/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 * Request body for the changePassword route. Must provide either 'oldPassword' OR 'resetToken'. Providing both will be considered invalid.
 * @export
 * @interface ChangePasswordRequestBody
 */
export interface ChangePasswordRequestBody {
  /**
   * Required if user is logged in and changing their password. The password currently set on the account. NOTE: Must provide either 'oldPassword' OR 'resetToken'. Providing both will be considered invalid.
   * @type {string}
   * @memberof ChangePasswordRequestBody
   */
  oldPassword?: string
  /**
   * Required for password reset. The unique one time use token sent to the user during a password reset request. NOTE: Must provide either 'oldPassword' OR 'resetToken'. Providing both will be considered invalid.
   * @type {string}
   * @memberof ChangePasswordRequestBody
   */
  resetToken?: string
  /**
   * The new password for the user account. Passwords should be at least 8 characters long, have at least 1 lowercase, and at least 1 uppercase letter.
   * @type {string}
   * @memberof ChangePasswordRequestBody
   */
  newPassword: string
}

export function ChangePasswordRequestBodyFromJSON(
  json: any
): ChangePasswordRequestBody {
  return ChangePasswordRequestBodyFromJSONTyped(json, false)
}

export function ChangePasswordRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChangePasswordRequestBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    oldPassword: !exists(json, 'oldPassword') ? undefined : json['oldPassword'],
    resetToken: !exists(json, 'resetToken') ? undefined : json['resetToken'],
    newPassword: json['newPassword'],
  }
}

export function ChangePasswordRequestBodyToJSON(
  value?: ChangePasswordRequestBody | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    oldPassword: value.oldPassword,
    resetToken: value.resetToken,
    newPassword: value.newPassword,
  }
}
