import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import TableContainer from '@mui/material/TableContainer'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFeeLabelMap } from '../../hooks/useFeeLabelMap'
import {
  Discount,
  Fee,
  SendTuitionInvoiceRequestBody,
  TuitionInvoice,
  TuitionPayment,
} from '../../swagger'
import getLocaleCurrencyForAmount from '../../utils/getLocaleCurrencyForAmount'
import ActionButtons from '../Buttons/ActionButtons'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import TextButton, { TextButtonVariant } from '../Buttons/TextButton'
import TableHeaders from '../Interfaces/TableHeaders'
import ConfirmationModal from '../Modals/ConfirmationModal'
import LedgerTable, { LedgerRow } from '../Table/LedgerTable'
import CardFormHeader from './CardFormHeader'
import { styled } from '@mui/system'
import { LoadingContext } from '../Context/LoadingContext'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import useLoadingContext from '../../hooks/useLoadingContext'
import { dateToSlashStringReinterpretedAsLocal } from '../../utils/dateUtility'
import CCLogo from '../../Images/Branding/cc-combination-blue.png'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import IconTextButton, {
  IconTextButtonVariant,
} from '../Buttons/IconTextButton'
import PrintIcon from '@mui/icons-material/Print'

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3, 4, 4),
  maxWidth: 1200,
  color: theme.palette.primary.main,
}))

const StyledImg = styled(Box)(({}) => ({
  '@media print': {
    maxWidth: '150px',
  },
}))

export function sumAmounts(values: Fee[]): number
export function sumAmounts(values: Discount[]): number
export function sumAmounts(values: TuitionPayment[]): number
export function sumAmounts(
  values: (Fee | Discount | TuitionPayment)[]
): number {
  return values.reduce((acc, curr) => acc + curr.amount.amount, 0)
}

const MAX_CHAR_LIMIT_FOR_COMMENT = 255

export type DiscountActionType = 'add' | 'edit' | 'remove'

export enum InvoiceVariant {
  /** An editable invoice preview to send. */
  New,
  /** A non-editable invoice that has already been sent. */
  Sent,
}

export interface InvoiceCardProps {
  showSendInvoiceButton: boolean
  isSubLicensedCommunity: boolean
  tuitionInvoice: TuitionInvoice
  onCloseInvoice: () => void
  sendInvoice: (
    body: SendTuitionInvoiceRequestBody,
    callback?: () => void
  ) => void
}

const InvoiceCard: React.FC<InvoiceCardProps> = ({
  showSendInvoiceButton,
  isSubLicensedCommunity,
  tuitionInvoice,
  onCloseInvoice,
  sendInvoice: sendInvoiceForParent,
}) => {
  const isDesktop = useShowOnDesktop()
  const theme = useTheme()
  const { t } = useTranslation()
  const feeLabelMap = useFeeLabelMap(t)

  const { InvoiceCard } = useLoadingIds()
  const loadingId = InvoiceCard.sendTuitionInvoice
  const { addLoadingIds } = React.useContext(LoadingContext)

  const [
    isSendInvoiceConfirmationModalOpen,
    setIsSendInvoiceConfirmationModalOpen,
  ] = useState(false)

  const [disableConfirmationSubmit, setDisableConfirmationSubmit] =
    useState(false)

  const [comment, setComment] = useState(tuitionInvoice.comment ?? '')

  const currencyCode = tuitionInvoice.currencyCode

  const handleSendInvoiceConfirmation = async (
    event: React.FormEvent<HTMLDivElement>
  ) => {
    event.preventDefault()

    setDisableConfirmationSubmit(true)

    addLoadingIds([loadingId])
  }

  const handleConfirmationModalClose = () => {
    setDisableConfirmationSubmit(false)
    setIsSendInvoiceConfirmationModalOpen(false)
  }

  const sendInvoice = async () => {
    // Prepare request body
    const body: SendTuitionInvoiceRequestBody = {
      comment: !comment ? undefined : comment,
      invoiceKey: tuitionInvoice.invoiceKey as number,
      // Discounts are now ignored on the backend in favor of entering discounts on the billing tables. There is no need to send an array of discounts.
    }

    sendInvoiceForParent(body, () => {
      handleConfirmationModalClose()
    })
  }

  useLoadingContext({
    asyncFunction: sendInvoice,
    loadingId: loadingId,
  })

  const commonLeftTableHeader: TableHeaders = {
    label: '',
    align: 'left',
    cssProps: {
      color: theme.palette.primary.main,
      paddingLeft: 0,
      borderLeft: '1px solid #fff',
      borderRight: '1px solid #fff',
    },
  }

  const commonInvoiceTableHeader: TableHeaders = {
    ...commonLeftTableHeader,
    align: 'center',
    cssProps: {
      ...commonLeftTableHeader.cssProps,
      padding: theme.spacing(1),
      backgroundColor: theme.palette.hover.main,
    },
  }

  const commonCellCssProps = {
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderLeft: '1px solid #fff',
    borderRight: '1px solid #fff',
    width: '20%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }

  const paymentReceived = t('Invoice.PaymentReceived', 'Payment Received')

  const printInvoice = () => {
    window.print()
  }

  const PageButtons = showSendInvoiceButton ? (
    <React.Fragment>
      <TextButton
        id="cancel"
        variant={TextButtonVariant.Cancel}
        onClick={onCloseInvoice}
      />
      <ContainedButton
        id="sendInvoice"
        variant={ContainedButtonVariant.SendInvoice}
        onClick={() => setIsSendInvoiceConfirmationModalOpen(true)}
      />
    </React.Fragment>
  ) : (
    <>
      <IconTextButton
        id="printInvoice"
        variant={IconTextButtonVariant.PrintInvoice}
        startIcon={<PrintIcon />}
        onClick={printInvoice}
      />
      <ContainedButton
        id="closeInvoice"
        variant={ContainedButtonVariant.Close}
        onClick={onCloseInvoice}
      />
    </>
  )

  const confirmationTitle = `${t(
    'Invoice.SendConfirmationModal.Header',
    'Clicking "Send Invoice" will email a copy of the invoice to'
  )} ${tuitionInvoice.parentEmail}`

  const DialogContent = isSubLicensedCommunity &&
    tuitionInvoice &&
    !tuitionInvoice.hasAssignedTutor && (
      <Typography variant="body1" align="center">
        {t(
          'Invoice.SendConfirmationModal.Body',
          '*** Warning: A tutor has not been assigned! Assign a tutor before sending the invoice. If you proceed without assigning a tutor, manual invoice changes will be needed after a tutor is assigned.'
        )}
      </Typography>
    )

  const DialogActions = (
    <ActionButtons
      primaryButtonLabel={ContainedButtonVariant.SendInvoice}
      secondaryButtonLabel={TextButtonVariant.Cancel}
      secondaryClick={() => setIsSendInvoiceConfirmationModalOpen(false)}
      disablePrimaryButton={disableConfirmationSubmit}
    />
  )

  const confirmationModal = (
    <ConfirmationModal
      isOpen={isSendInvoiceConfirmationModalOpen}
      dialogTitle={confirmationTitle}
      dialogContent={DialogContent}
      dialogActions={DialogActions}
      handleFormSubmit={handleSendInvoiceConfirmation}
    />
  )

  const {
    city = '',
    state = '',
    zip = '',
  } = tuitionInvoice.parentAddress ? tuitionInvoice.parentAddress : {}
  const addressSecondLine = `${city} ${state} ${zip}`

  const { requesterAddress } = tuitionInvoice.enrollments[0]

  const directorAddressSecondLine = `${
    requesterAddress?.city ? requesterAddress?.city + ',' : ''
  } ${requesterAddress?.state ?? ''} ${requesterAddress?.zip ?? ''}`

  const { requesterPhoneNumber, requesterEmail } = tuitionInvoice.enrollments[0]

  const noRefundableMessage = t(
    'Invoice.NoRefundableMessage',
    'All tuition and fees are non-refundable. The application fee and enrollment fee may be transferable to a new community according to relocation guidelines. Enrollment fees should be paid directly to Classical Conversations, Inc. through CC Connected prior to attending community.'
  )

  const paymentsInfoMessage = t(
    'Invoice.PaymentsInfoMessage',
    'Make all checks payable to: {{directorName}}',
    {
      directorName: tuitionInvoice.enrollments[0].requesterName,
    }
  )

  const thanksMessage = t('Invoice.ThanksMessage', 'Thank You!')

  const InvoiceHeader = (
    <Box role="heading" aria-label="invoiceHeader">
      <Grid container justifyContent="space-between">
        <Grid item xs={4} sm={6}>
          <StyledImg>
            <Box
              component="img"
              sx={{
                height: 'auto',
                width: '100%',
                maxWidth: '150px',
              }}
              src={CCLogo}
              alt={t('Invoice.Logo', 'Classical Conversations Logo')}
            />
          </StyledImg>
        </Grid>
        <Grid
          item
          xs={8}
          sm={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="subtitle1"
            align="right"
            sx={{
              fontSize: isDesktop ? '2.5em' : '1.5em',
              color: theme.palette.grey[300],
              fontStyle: 'italic',
              fontWeight: 'bolder',
              lineHeight: '0.8em',
            }}
          >
            {`${t('Invoice.Title', 'Statement')}`}
          </Typography>
          <Typography variant="subtitle1">
            {`${t('Invoice.Date', 'Date')}:`}
            <Box
              component={'span'}
              sx={{
                paddingLeft: theme.spacing(1),
                fontWeight: 'bolder',
              }}
            >
              {tuitionInvoice.invoiceDate &&
                dateToSlashStringReinterpretedAsLocal(
                  tuitionInvoice.invoiceDate
                )}
            </Box>
          </Typography>
        </Grid>
      </Grid>

      <Typography
        variant="h5"
        sx={{
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
        }}
      >
        {t('Invoice.CommunityName', '{{communityName}} Community', {
          communityName: tuitionInvoice.enrollments[0].communityName,
        })}
      </Typography>

      <Box display={'flex'} flexDirection={'column'}>
        <Box display={'flex'}>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 'bolder',
              lineHeight: '1.3em',
              mr: theme.spacing(1)
            }}
          >
            {`${t('Invoice.BillTo', 'Bill To')}: `}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              lineHeight: '1.3em',
            }}
          >
            {t('Invoice.ParentName', '{{parentName}}', {
              parentName: tuitionInvoice.parentName || '',
            })}
          </Typography>
        </Box>
        <Typography variant="subtitle1" sx={{ lineHeight: '1.3em' }}>
          {tuitionInvoice.parentAddress?.streetAddress1}
        </Typography>
        <Typography variant="subtitle1" sx={{ lineHeight: '1.3em' }}>
          {addressSecondLine}
        </Typography>
        <Typography variant="subtitle1" mt={2}>
          {tuitionInvoice.parentEmail}
        </Typography>
      </Box>
    </Box>
  )

  const InvoiceFooter = (
    <Box role="region" aria-label="invoiceFooter">
      <Typography variant="body1" component={'p'} mt={4} align="center">
        {noRefundableMessage}
      </Typography>

      <Typography variant="body1" component={'p'} mt={4} align="center">
        {paymentsInfoMessage}
      </Typography>

      <Typography variant="h6" component={'p'} mt={4} align="center">
        {thanksMessage}
      </Typography>

      <Typography variant="body1" component={'p'} mt={4} align="center">
        {tuitionInvoice.enrollments[0].requesterAddress?.streetAddress1}
      </Typography>

      <Typography variant="body1" component={'p'} align="center">
        {directorAddressSecondLine}
      </Typography>

      <Typography variant="body1" component={'p'} mt={4} align="center">
        {t(
          'Invoice.DirectorContactInfo',
          'Phone: {{phone}} | Email: {{email}}',
          {
            phone: requesterPhoneNumber,
            email: requesterEmail,
          }
        )}
      </Typography>
    </Box>
  )

  return (
    <React.Fragment>
      {confirmationModal}
      <StyledCard>
        <CardFormHeader header={<></>} buttons={PageButtons} />

        {InvoiceHeader}

        <Grid container>
          <Grid item xs={12} md={12}>
            {tuitionInvoice.enrollments.map(
              ({
                studentKey,
                programKey,
                studentName,
                programType,
                semesterOneStartDate,
                fees,
                discounts,
                payments,
                total,
              }) => {
                const yearOfSemesterOneStartDate = new Date(
                  semesterOneStartDate
                ).getUTCFullYear()

                const programAndStudentName = t(
                  'Invoice.ProgramAndStudentName',
                  'For {{programType}} - {{yearOfSemesterOneStartDate}}: {{studentName}}',
                  {
                    programType,
                    yearOfSemesterOneStartDate,
                    studentName,
                  }
                )

                const studentFees: LedgerRow[] = fees
                  .filter((fee) => fee.amount.amount > 0)
                  .map((fee, index) => {
                    const { feeType, amount } = fee
                    const { amount: value, currencyCode } = amount
                    const cashAmount = getLocaleCurrencyForAmount(
                      value,
                      currencyCode
                    )
                    const invoiceDate =
                      index === 0
                        ? tuitionInvoice.invoiceDate
                          ? dateToSlashStringReinterpretedAsLocal(
                              tuitionInvoice.invoiceDate
                            )
                          : ''
                        : ''

                    return {
                      cells: [
                        {
                          content: invoiceDate,
                          align: 'left',
                          color: 'medium',
                          cellCssProps: { ...commonCellCssProps },
                        },
                        {
                          content: '',
                          align: 'left',
                          cellCssProps: { ...commonCellCssProps },
                        },
                        {
                          content: feeLabelMap[feeType],
                          align: 'left',
                          color: 'medium',
                          cellCssProps: { ...commonCellCssProps },
                        },
                        {
                          content: cashAmount,
                          align: 'right',
                          color: 'main',
                          cellCssProps: { ...commonCellCssProps },
                        },
                      ],
                    }
                  })

                let studentDiscounts = discounts as unknown as
                  | LedgerRow[]
                  | undefined

                let studentPayments = payments as unknown as
                  | LedgerRow[]
                  | undefined

                const allFees = [...studentFees]
                const emptyCell = ''
                if (!!discounts) {
                  studentDiscounts = discounts
                    .filter((discount) => discount.amount.amount > 0)
                    .map(({ desc, amount }) => {
                      const { amount: value, currencyCode } = amount
                      const cashAmount = getLocaleCurrencyForAmount(
                        value,
                        currencyCode
                      )

                      return {
                        cells: [
                          {
                            // We nee this empty cell to align the description with the other cells
                            content: emptyCell,
                            align: 'left',
                            color: 'medium',
                            cellCssProps: { ...commonCellCssProps },
                          },
                          {
                            content: emptyCell,
                            align: 'left',
                            cellCssProps: { ...commonCellCssProps },
                          },
                          {
                            content: desc,
                            align: 'left',
                            color: 'medium',
                            cellCssProps: { ...commonCellCssProps },
                          },
                          {
                            content: `- ${cashAmount}`,
                            align: 'right',
                            color: 'main',
                            cellCssProps: { paddingRight: theme.spacing(1) },
                          },
                        ],
                      }
                    })

                  allFees.push(...studentDiscounts)
                }

                if (!!payments) {
                  studentPayments = payments.map(
                    ({ paymentDate, desc, amount }) => {
                      const { amount: value, currencyCode } = amount
                      const cashAmount = getLocaleCurrencyForAmount(
                        value,
                        currencyCode
                      )
                      const paymentDateValue = paymentDate
                        ? dateToSlashStringReinterpretedAsLocal(paymentDate)
                        : ''

                      return {
                        cells: [
                          {
                            content: paymentDateValue,
                            align: 'left',
                            color: 'medium',
                            cellCssProps: { ...commonCellCssProps },
                          },
                          {
                            content: desc,
                            align: 'left',
                            color: 'medium',
                            cellCssProps: { ...commonCellCssProps },
                          },
                          {
                            content: paymentReceived,
                            align: 'left',
                            color: 'medium',
                            cellCssProps: { ...commonCellCssProps },
                          },
                          {
                            content: `- ${cashAmount}`,
                            align: 'right',
                            color: 'main',
                            cellCssProps: { ...commonCellCssProps },
                          },
                        ],
                      }
                    }
                  )
                  allFees.push(...studentPayments)
                }

                allFees.push(
                  {
                    cells: [
                      {
                        colSpan: 4,
                        content: t('Invoice.Subtotal', 'Program Subtotal'),
                        align: 'right',
                        color: 'main',
                        cellCssProps: {
                          ...commonCellCssProps,
                          backgroundColor: theme.palette.hover.main,
                        },
                      },
                    ],
                  },
                  {
                    cells: [
                      {
                        // We need this empty cell to align the amount with the other cells
                        content: emptyCell,
                        align: 'left',
                        colSpan: 3,
                        cellCssProps: {
                          backgroundColor: theme.palette.primary.contrastText,
                          border: '0',
                        },
                      },
                      {
                        content: getLocaleCurrencyForAmount(
                          total.amount,
                          currencyCode
                        ),
                        align: 'right',
                        color: 'main',
                        cellCssProps: {
                          backgroundColor:
                            theme.palette.customBackground.progressBar.bottom,
                          paddingRight: theme.spacing(1),
                        },
                      },
                    ],
                  }
                )

                return (
                  <Box
                    component="section"
                    aria-labelledby={`studentEnrollment${studentKey}`}
                    key={`${programKey}-${studentKey}`}
                  >
                    <Typography
                      id={`studentEnrollment${studentKey}`}
                      variant="subtitle1"
                      sx={{
                        marginTop: theme.spacing(2),
                        marginBottom: theme.spacing(2),
                      }}
                    >
                      {programAndStudentName}
                    </Typography>
                    <TableContainer
                      sx={{
                        padding: theme.spacing(0.4),
                        border: `1px solid ${theme.palette.hover.main}`,
                      }}
                    >
                      <LedgerTable
                        cssProps={{ marginBottom: theme.spacing(0) }}
                        striped
                        tableHeaders={[
                          {
                            ...commonInvoiceTableHeader,
                            label: t('Invoice.Header.Date', 'Date'),
                          },
                          {
                            ...commonInvoiceTableHeader,
                            label: t(
                              'Invoice.Header.PaymentNotes',
                              'Payment Notes'
                            ),
                          },
                          {
                            ...commonInvoiceTableHeader,
                            label: t(
                              'Invoice.Header.Description',
                              'Description'
                            ),
                          },
                          {
                            ...commonInvoiceTableHeader,
                            label: t('Invoice.Header.Amount', 'Amount'),
                          },
                        ]}
                        rows={allFees}
                      />
                    </TableContainer>
                  </Box>
                )
              }
            )}
            <IndividualLineItem
              bold
              lineItem={t('Invoice.TotalDue', 'Total Due')}
              displayAmount={getLocaleCurrencyForAmount(
                tuitionInvoice.total.amount,
                currencyCode
              )}
            />
            {showSendInvoiceButton ? (
              <TextField
                id="invoice-message"
                label={t(
                  'Invoice.MessageArea',
                  'Additional messages, payment instructions, etc.'
                )}
                fullWidth
                multiline
                margin="normal"
                rows={5}
                variant="filled"
                value={comment}
                inputProps={{ maxLength: MAX_CHAR_LIMIT_FOR_COMMENT }}
                helperText={t(
                  'Invoice.InvoiceCard.Comment.HelperText',
                  '255 character max'
                )}
                onChange={(e) => setComment(e.target.value)}
                disabled={!showSendInvoiceButton}
              />
            ) : (
              <>
                <TableContainer
                  sx={{
                    mt: theme.spacing(2),
                    padding: theme.spacing(0.4),
                    border: `1px solid ${theme.palette.hover.main}`,
                  }}
                >
                  <LedgerTable
                    ariaLabel="invoice-comments-table"
                    cssProps={{ marginBottom: theme.spacing(0) }}
                    tableHeaders={[
                      {
                        ...commonInvoiceTableHeader,
                        label: t('Invoice.Comments', 'Comments'),
                      },
                    ]}
                    rows={[
                      {
                        cells: [
                          {
                            content: comment,
                            align: 'left',
                            color: 'medium',
                            colSpan: 2,
                            cellCssProps: {
                              ...commonCellCssProps,
                              border: '0',
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </TableContainer>
              </>
            )}
            {InvoiceFooter}
          </Grid>
        </Grid>
      </StyledCard>
    </React.Fragment>
  )
}

const IndividualLineItem: React.FC<{
  bold?: boolean
  lineItem: string
  displayAmount: string
}> = ({ bold = false, lineItem, displayAmount }) => {
  const theme = useTheme()
  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="flex-end"
      flexDirection={'column'}
    >
      <Typography
        variant="subtitle2"
        sx={{
          color: bold ? theme.palette.primary.main : theme.palette.primary.dark,
          ...(bold && {
            fontWeight: 900,
          }),
          width: '25%',
          padding: theme.spacing(1),
          textAlign: 'right',
          backgroundColor: theme.palette.hover.main,
          marginRight: theme.spacing(0.2),
        }}
      >
        {lineItem}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{
          ...(bold && {
            fontWeight: 900,
          }),
          color: theme.palette.primary.main,
          padding: theme.spacing(1),
          // We set this to a specific value to ensure the individual line items align
          width: '25%',
          textAlign: 'right',
          backgroundColor: theme.palette.customBackground.progressBar.bottom,
          marginTop: theme.spacing(0.5),
          marginRight: theme.spacing(0.2),
        }}
      >
        {displayAmount}
      </Typography>
    </Box>
  )
}

export default InvoiceCard
