/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  RemoveLicensingEnrollmentRequestBodyLicensingEnrollments,
  RemoveLicensingEnrollmentRequestBodyLicensingEnrollmentsFromJSON,
  RemoveLicensingEnrollmentRequestBodyLicensingEnrollmentsToJSON,
} from './'

/**
 * The body to remove licensing enrollment, receives an array of objects with the required identifiers: studentKey, programKey and feeTypeKey that will determine the records to be deleted.
 * @export
 * @interface RemoveLicensingEnrollmentRequestBody
 */
export interface RemoveLicensingEnrollmentRequestBody {
  /**
   * An array of objects with the identifiers studentKey, programKey and feeTypeKey
   * @type {Array<RemoveLicensingEnrollmentRequestBodyLicensingEnrollments>}
   * @memberof RemoveLicensingEnrollmentRequestBody
   */
  licensingEnrollments: Array<RemoveLicensingEnrollmentRequestBodyLicensingEnrollments>
}

export function RemoveLicensingEnrollmentRequestBodyFromJSON(
  json: any
): RemoveLicensingEnrollmentRequestBody {
  return RemoveLicensingEnrollmentRequestBodyFromJSONTyped(json, false)
}

export function RemoveLicensingEnrollmentRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RemoveLicensingEnrollmentRequestBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    licensingEnrollments: (json['licensingEnrollments'] as Array<any>).map(
      RemoveLicensingEnrollmentRequestBodyLicensingEnrollmentsFromJSON
    ),
  }
}

export function RemoveLicensingEnrollmentRequestBodyToJSON(
  value?: RemoveLicensingEnrollmentRequestBody | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    licensingEnrollments: (value.licensingEnrollments as Array<any>).map(
      RemoveLicensingEnrollmentRequestBodyLicensingEnrollmentsToJSON
    ),
  }
}
