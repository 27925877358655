/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  RoleAgreementDocumentSelectOption,
  RoleAgreementDocumentSelectOptionFromJSON,
  RoleAgreementDocumentSelectOptionToJSON,
  RoleAgreementTemplateSelectOption,
  RoleAgreementTemplateSelectOptionFromJSON,
  RoleAgreementTemplateSelectOptionToJSON,
} from './'

/**
 *
 * @export
 * @interface InviteTeamMemberOptions
 */
export interface InviteTeamMemberOptions {
  /**
   * **DEPRECATED - Use roleAgreementDocuments** A list of roles that the logged in user is able to contract/license for along with the agreement templates pertaining to that role.
   * @type {Array<RoleAgreementTemplateSelectOption>}
   * @memberof InviteTeamMemberOptions
   */
  roleAgreementTemplates?: Array<RoleAgreementTemplateSelectOption>
  /**
   * A list of roles that the logged in user is able to contract/license for along with the agreement documents pertaining to that role.
   * @type {Array<RoleAgreementDocumentSelectOption>}
   * @memberof InviteTeamMemberOptions
   */
  roleAgreementDocuments: Array<RoleAgreementDocumentSelectOption>
  /**
   * The date to which the actor is valid in date format: `yyyy-MM-dd`.
   * @type {Date}
   * @memberof InviteTeamMemberOptions
   */
  defaultRoleValidToDate: Date
}

export function InviteTeamMemberOptionsFromJSON(
  json: any
): InviteTeamMemberOptions {
  return InviteTeamMemberOptionsFromJSONTyped(json, false)
}

export function InviteTeamMemberOptionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InviteTeamMemberOptions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    roleAgreementTemplates: !exists(json, 'roleAgreementTemplates')
      ? undefined
      : (json['roleAgreementTemplates'] as Array<any>).map(
          RoleAgreementTemplateSelectOptionFromJSON
        ),
    roleAgreementDocuments: (json['roleAgreementDocuments'] as Array<any>).map(
      RoleAgreementDocumentSelectOptionFromJSON
    ),
    defaultRoleValidToDate: new Date(json['defaultRoleValidToDate']),
  }
}

export function InviteTeamMemberOptionsToJSON(
  value?: InviteTeamMemberOptions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    roleAgreementTemplates:
      value.roleAgreementTemplates === undefined
        ? undefined
        : (value.roleAgreementTemplates as Array<any>).map(
            RoleAgreementTemplateSelectOptionToJSON
          ),
    roleAgreementDocuments: (value.roleAgreementDocuments as Array<any>).map(
      RoleAgreementDocumentSelectOptionToJSON
    ),
    defaultRoleValidToDate: value.defaultRoleValidToDate
      .toISOString()
      .substr(0, 10),
  }
}
