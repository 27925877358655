interface PrepareOptions<T> {
  key?: string
  mapper?: (item: T) => string
}

/**
 * Simply mapping a list object's props in a template string
 * produces commas which are unsightly. This util specifically
 * removes those after mapping the desired list items in a
 * paragraph tag.
 *
 * @param list - A list of objects with k-v pairs
 * @param key - The key desired to index within an item of list
 * @returns a string of p-wrapped items
 */
export const prepareListForHtmlString = <T>(
  list: Array<T>,
  opts?: PrepareOptions<T>
): string => {
  const { key, mapper } = opts ?? {}
  if (!key && !mapper) {
    throw new Error('Either key or mapper is required.')
  }
  if (list.some((item) => typeof item !== 'object')) {
    throw new Error('The list must be an array of objects.')
  }

  return list
    .map(mapper ? mapper : (item) => `<p>${item[key as keyof typeof item]}</p>`)
    .join('')
    .replace(',', '')
}
