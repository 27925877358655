/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface CreateTranscriptYearCourseWork
 */
export interface CreateTranscriptYearCourseWork {
  /**
   *
   * @type {string}
   * @memberof CreateTranscriptYearCourseWork
   */
  courseName: string
  /**
   *
   * @type {string}
   * @memberof CreateTranscriptYearCourseWork
   */
  letterGrade: string
  /**
   * Credits received for the course.
   * @type {number}
   * @memberof CreateTranscriptYearCourseWork
   */
  credits: number
}

export function CreateTranscriptYearCourseWorkFromJSON(
  json: any
): CreateTranscriptYearCourseWork {
  return CreateTranscriptYearCourseWorkFromJSONTyped(json, false)
}

export function CreateTranscriptYearCourseWorkFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateTranscriptYearCourseWork {
  if (json === undefined || json === null) {
    return json
  }
  return {
    courseName: json['courseName'],
    letterGrade: json['letterGrade'],
    credits: json['credits'],
  }
}

export function CreateTranscriptYearCourseWorkToJSON(
  value?: CreateTranscriptYearCourseWork | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    courseName: value.courseName,
    letterGrade: value.letterGrade,
    credits: value.credits,
  }
}
