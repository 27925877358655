/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { FeeType, FeeTypeFromJSON, FeeTypeToJSON } from './'

/**
 *
 * @export
 * @interface DefaultFeeStructures
 */
export interface DefaultFeeStructures {
  /**
   *
   * @type {FeeType}
   * @memberof DefaultFeeStructures
   */
  feeType: FeeType
  /**
   * The minimum cost the fee can be set on a program for this fee type within the region. Displayed in mills: $1 is 1000 mills
   * @type {number}
   * @memberof DefaultFeeStructures
   */
  minimumAmount: number
  /**
   * The default cost that this fee type will be set to if not overwritten on the program. Displayed in mills: $1 is 1000 mills
   * @type {number}
   * @memberof DefaultFeeStructures
   */
  defaultTotalAmount: number
  /**
   * Whether the fee can be set to anything other than the defaultTotalAmount. If false, this fee type will always use the defaultTotalAmount.
   * @type {boolean}
   * @memberof DefaultFeeStructures
   */
  allowEditTotal: boolean
}

export function DefaultFeeStructuresFromJSON(json: any): DefaultFeeStructures {
  return DefaultFeeStructuresFromJSONTyped(json, false)
}

export function DefaultFeeStructuresFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DefaultFeeStructures {
  if (json === undefined || json === null) {
    return json
  }
  return {
    feeType: FeeTypeFromJSON(json['feeType']),
    minimumAmount: json['minimumAmount'],
    defaultTotalAmount: json['defaultTotalAmount'],
    allowEditTotal: json['allowEditTotal'],
  }
}

export function DefaultFeeStructuresToJSON(
  value?: DefaultFeeStructures | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    feeType: FeeTypeToJSON(value.feeType),
    minimumAmount: value.minimumAmount,
    defaultTotalAmount: value.defaultTotalAmount,
    allowEditTotal: value.allowEditTotal,
  }
}
