import React, { useMemo, useState } from 'react'
import {
  ActionableTable,
  ActionableTableColumn,
} from '../Table/ActionableTable'
import { Action } from '../Table/RowActions'
import { useTranslation } from 'react-i18next'
import {
  PaginationResponse,
  TranscriptOverview,
  TranscriptParchmentRequestStatus,
} from '../../swagger'
import { useNavigate } from 'react-router'
import { useTheme } from '@mui/material/styles'
import { Delete, Send, Visibility, Check } from '@mui/icons-material'
import { transcriptsApi } from '../../api/swagger'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { LoadingContext } from '../Context/LoadingContext'
import useEndpoint from '../../hooks/useEndpoint'
import {
  SnackbarSeverity,
  useSnackbarContext,
} from '../Context/SnackbarContext'
import ConfirmForParchmentModal from '../Modals/ConfirmForParchmentModal'
import { useAuth } from '../Routes/AuthProvider'
import { DataGridPropsWithoutDefaultValue } from '@mui/x-data-grid/models/props/DataGridProps'
import TranscriptConfirmDeleteModal from '../Modals/TranscriptConfirmDeleteModal'
import TranscriptConfirmMarkAsSentModal from '../Modals/TranscriptConfirmMarkAsSentModal'

export interface TranscriptsTableProps
  extends Pick<
    DataGridPropsWithoutDefaultValue,
    'onRowSelectionModelChange' | 'rowSelectionModel'
  > {
  transcripts: TranscriptOverview[]
  handlePaginationChange?: (pagination: PaginationResponse) => void
  pagination?: PaginationResponse
  ariaLabelledBy?: string
  canCreateTranscript: boolean
  triggerRefresh?: () => void
}

export const TranscriptsTable: React.FC<TranscriptsTableProps> = ({
  transcripts,
  handlePaginationChange,
  pagination,
  ariaLabelledBy,
  onRowSelectionModelChange,
  rowSelectionModel,
  canCreateTranscript,
  triggerRefresh,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()
  const auth = useAuth()
  const { TranscriptFormCard, UseDeleteTranscript } = useLoadingIds()
  const { addLoadingIds } = React.useContext(LoadingContext)
  const { setSnackbarState, setSnackbarMessage, setSnackbarSeverity } =
    useSnackbarContext()
  const { sendTranscriptToParchment: sendTranscriptToParchmentLoadingId } =
    TranscriptFormCard

  const { deleteTranscript: deleteTranscriptLoadingId } = UseDeleteTranscript
  const { markParchmentAsSent: markParchmentAsSentLoadingId } =
    TranscriptFormCard

  const [transcriptKeyToSend, setTranscriptKeyToSend] = useState<
    number | undefined
  >()
  const [transcriptKeyToDelete, setTranscriptKeyToDelete] = useState<
    number | undefined
  >()
  const [transcriptToMarkAsSent, setTranscriptToMarkAsSent] = useState<
    TranscriptOverview | undefined
  >()

  const [showConfirmParchmentModal, setShowConfirmParchmentModal] =
    useState(false)
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
  const [showConfirmMarkAsSentModal, setShowConfirmMarkAsSentModal] =
    useState(false)

  const canViewTranscript =
    auth.permissionAbility.can('view', 'Transcript') ||
    auth.userDetails.actingAs === 'parent'

  const canPrintTranscript = auth.permissionAbility.can('print', 'Transcript')

  const hasParchmentAdminAbility = auth.permissionAbility.can('parchmentAdmin', 'Transcript')

  useEndpoint({
    swaggerCall: () =>
      transcriptsApi.sendTranscriptToParchment({
        transcriptKey: transcriptKeyToSend as number,
      }),
    loadingId: sendTranscriptToParchmentLoadingId,
    successMessage: t(
      'Transcripts.TranscriptsTable.Actions.SendToParchmentSuccess',
      'Transcript has successfully been requested to send to parchment.'
    ),
    genericErrorMessage: t(
      'Transcripts.TranscriptsTable.Actions.SendToParchmentFail',
      'An unknown error occurred requesting to send transcript to parchment.'
    ),
    dontCall: () => !transcriptKeyToSend,
    failureCallback: (error, done) => {
      if ((error as Response).status === 400) {
        setSnackbarMessage(
          t(
            'Transcripts.TranscriptsTable.Actions.SendToParchmentFieldsNotMet',
            'One or more fields required by parchment have not been filled out. Please add the missing field(s) and try again.'
          )
        )
        setSnackbarSeverity(SnackbarSeverity.Error)
        setSnackbarState(true)

        done()
      }
    },
  })

  useEndpoint({
    swaggerCall: () =>
      transcriptsApi.deleteTranscript({
        transcriptKey: transcriptKeyToDelete as number,
      }),
    loadingId: deleteTranscriptLoadingId,
    successMessage: t(
      'Transcripts.TranscriptsTable.Actions.DeleteTranscriptSuccess',
      'Transcript has successfully been deleted.'
    ),
    genericErrorMessage: t(
      'Transcripts.TranscriptsTable.Actions.DeleteTranscriptFailure',
      'An unknown error occurred requesting to delete transcript.'
    ),
    dontCall: () => !transcriptKeyToDelete,
    successCallback: () => {
      triggerRefresh?.()
    },
  })

  useEndpoint({
    swaggerCall: () =>
      transcriptsApi.markParchmentAsSent({
        body: {
          transcriptKeys: [transcriptToMarkAsSent?.transcriptKey as number],
        },
      }),
    loadingId: markParchmentAsSentLoadingId,
    successMessage: t(
      'Transcripts.TranscriptsTable.Actions.MarkAsSentSuccess',
      'Transcript has successfully been marked as sent.'
    ),
    genericErrorMessage: t(
      'Transcripts.TranscriptsTable.Actions.DeleteTranscriptFailure',
      'An unknown error occurred requesting to mark as sent a transcript.'
    ),
    dontCall: () => !transcriptToMarkAsSent?.transcriptKey,
    successCallback: () => {
      triggerRefresh?.()
    },
  })

  const ActionableTableColumnsHeader: ActionableTableColumn[] = [
    {
      fieldName: 'transcriptKey',
      columnHeaderName: t('Transcripts.Table.Header.Id', 'Transcript Id'),
      sortable: true,
      align: 'left',
      headerAlign: 'left',
      sortingOrder: ['desc', 'asc'],
    },
    {
      fieldName: 'transcriptName',
      columnHeaderName: t('Transcripts.Table.Header.Name', 'Transcript Name'),
      sortable: true,
      align: 'left',
      headerAlign: 'left',
    },
    {
      fieldName: 'studentName',
      columnHeaderName: t('Transcripts.Table.Header.Child', 'Child Name'),
      sortable: true,
      align: 'left',
      headerAlign: 'left',
    },
    {
      fieldName: 'requestStatus',
      columnHeaderName: t(
        'Transcripts.Table.Header.RequestStatus',
        'Parchment Request Status'
      ),
      sortable: true,
      align: 'left',
      headerAlign: 'left',
    },
  ]

  // canViewTranscript
  const rowActions: Action<TranscriptOverview>[] = useMemo<
    Action<TranscriptOverview>[]
  >(() => {
    const viewTranscriptAction: Action<TranscriptOverview> = {
      actionName: t('Transcripts.Table.RowAction.View', 'View'),
      actionFunction: (row) => {
        if (!row?.transcriptKey) return
        navigate(`/transcripts/transcript-details/${row.transcriptKey}`)
      },
      actionKey: `view`,
      actionIcon: <Visibility style={{ minWidth: theme.spacing(4) }} />,
    }
    const sendToParchmentAction: Action<TranscriptOverview> = {
      actionName: t(
        'Transcripts.Table.RowAction.SendToParchment',
        'Send To Parchment'
      ),
      actionFunction: (row) => {
        setTranscriptKeyToSend(row?.transcriptKey)
        setShowConfirmParchmentModal(true)
      },
      actionKey: `send`,
      actionIcon: <Send style={{ minWidth: theme.spacing(4) }} />,
    }

    const deleteTranscriptAction: Action<TranscriptOverview> = {
      actionName: t('Transcripts.Table.RowAction.Delete', 'Delete'),
      actionFunction: (row) => {
        setTranscriptKeyToDelete(row?.transcriptKey)
        setShowConfirmDeleteModal(true)
      },
      actionKey: `delete`,
      actionIcon: <Delete style={{ minWidth: theme.spacing(4) }} />,
    }

    const markAsSentAction: Action<TranscriptOverview> = {
      actionName: t('Transcripts.Table.RowAction.MarkAsSent', 'Mark as sent'),
      actionFunction: (row) => {
        setTranscriptToMarkAsSent(row)
        setShowConfirmMarkAsSentModal(true)
      },
      actionKey: `markAsSent`,
      actionIcon: <Check style={{ minWidth: theme.spacing(4) }} />,
      hide:() => !hasParchmentAdminAbility,
    }

    const actions = []
    if (canViewTranscript) {
      actions.push(viewTranscriptAction)
    }
    if (auth.userDetails.actingAs === 'parent') {
      actions.push(deleteTranscriptAction, sendToParchmentAction)
    }
    if (canPrintTranscript) {
      actions.push(markAsSentAction)
    }
    return actions
  }, [
    canViewTranscript,
    navigate,
    t,
    theme,
    auth.userDetails.actingAs,
    canPrintTranscript,
    hasParchmentAdminAbility,
  ])

  if (auth.userDetails.actingAs !== 'parent') {
    // actors get more data on their screen
    ActionableTableColumnsHeader.push({
      fieldName: 'parentName',
      columnHeaderName: t('Transcripts.Table.Header.ParentName', 'Parent Name'),
      sortable: true,
      align: 'left',
      headerAlign: 'left',
    })
    ActionableTableColumnsHeader.push({
      fieldName: 'parentUsername',
      columnHeaderName: t(
        'Transcripts.Table.Header.ParentEmail',
        'Parent Email'
      ),
      sortable: true,
      align: 'left',
      headerAlign: 'left',
    })
  }

  const hideConfirmParchmentModal = () => {
    setShowConfirmParchmentModal(false)
  }

  const hideConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false)
  }

  const hideConfirmMarkAsSentModal = () => {
    setShowConfirmMarkAsSentModal(false)
  }

  const noResultsMessage = canCreateTranscript
    ? t(
        'Transcripts.Transcripts.Table.NoTranscripts.CanAdd',
        'You currently have no transcripts. Select the + to create one now!'
      )
    : t(
        'Transcripts.Transcripts.Table.NoTranscripts.CannotAdd',
        'You currently have no transcripts.'
      )

  // NOTE: It seems like there's an issue where the styling is incorrect *unless* there are
  // row actions passed in
  return (
    <>
      <ConfirmForParchmentModal
        isOpen={showConfirmParchmentModal}
        onConfirm={() => {
          addLoadingIds([sendTranscriptToParchmentLoadingId])
          hideConfirmParchmentModal()
        }}
        onCancel={hideConfirmParchmentModal}
      />
      <TranscriptConfirmDeleteModal
        isOpen={showConfirmDeleteModal}
        onConfirm={() => {
          addLoadingIds([deleteTranscriptLoadingId])
          hideConfirmDeleteModal()
        }}
        onCancel={hideConfirmDeleteModal}
      />
      <TranscriptConfirmMarkAsSentModal
        isOpen={showConfirmMarkAsSentModal}
        onConfirm={() => {
          addLoadingIds([markParchmentAsSentLoadingId])
          hideConfirmMarkAsSentModal()
        }}
        onCancel={hideConfirmMarkAsSentModal}
        transcriptName={transcriptToMarkAsSent?.transcriptName ?? ''}
      />
      <ActionableTable
        checkboxSelection={transcripts.length > 0}
        onRowSelectionModelChange={onRowSelectionModelChange}
        rowSelectionModel={rowSelectionModel}
        ariaLabelledBy={ariaLabelledBy}
        columns={ActionableTableColumnsHeader}
        rows={transcripts}
        rowActions={rowActions.length ? rowActions : undefined}
        pagination={pagination}
        handlePaginationChange={handlePaginationChange}
        noResultsMessage={noResultsMessage}
        isRowSelectable={(params) =>
          params.row.requestStatus ===
          TranscriptParchmentRequestStatus.Requested
        }
      />
    </>
  )
}

export default TranscriptsTable
