import React from 'react'
import { Box, Skeleton, SkeletonProps, useTheme } from '@mui/material'
import { CustomCard } from '../Card/ElevatedCard'

export const ElevatedCardPlaceholder: React.FC = () => {
  const theme = useTheme()
  const commonProps: SkeletonProps = {
    animation: 'pulse',
    variant: 'rounded',
    sx: { mb: 2 },
  }
  return (
    <Box flexDirection={'row'} display="flex" justifyContent={'space-between'}>
      <CustomCard
        borderColor={`${theme.palette.program.challenge}`}
        navigateFn={() =>
          void (
            {
              // purposefully No-op
            }
          )
        }
        // Will we ever have more than one?
        cardKey={-1}
      >
        <Skeleton width="25%" {...commonProps} />
        <Skeleton width="50%" {...commonProps} />
        <Skeleton width="65%" {...commonProps} />
        <Skeleton width="40%" {...commonProps} />
      </CustomCard>
    </Box>
  )
}
