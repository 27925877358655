/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface FetchAcademicYearsResponseAcademicYears
 */
export interface FetchAcademicYearsResponseAcademicYears {
  /**
   *
   * @type {number}
   * @memberof FetchAcademicYearsResponseAcademicYears
   */
  year: number
  /**
   *
   * @type {boolean}
   * @memberof FetchAcademicYearsResponseAcademicYears
   */
  isCurrent?: boolean
  /**
   *
   * @type {boolean}
   * @memberof FetchAcademicYearsResponseAcademicYears
   */
  isDefault?: boolean
}

export function FetchAcademicYearsResponseAcademicYearsFromJSON(
  json: any
): FetchAcademicYearsResponseAcademicYears {
  return FetchAcademicYearsResponseAcademicYearsFromJSONTyped(json, false)
}

export function FetchAcademicYearsResponseAcademicYearsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FetchAcademicYearsResponseAcademicYears {
  if (json === undefined || json === null) {
    return json
  }
  return {
    year: json['year'],
    isCurrent: !exists(json, 'isCurrent') ? undefined : json['isCurrent'],
    isDefault: !exists(json, 'isDefault') ? undefined : json['isDefault'],
  }
}

export function FetchAcademicYearsResponseAcademicYearsToJSON(
  value?: FetchAcademicYearsResponseAcademicYears | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    year: value.year,
    isCurrent: value.isCurrent,
    isDefault: value.isDefault,
  }
}
