import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import DashboardCard from '../../Card/DashboardCard'
import { useAccountContext } from '../../Context/AccountContext'
import DynamicBreadcrumbs from '../../Elements/DynamicBreadcrumbs'
import { Typography } from '@mui/material'
import EmptyPage from '../../Elements/EmptyPage'
import useLoadingContext from '../../../hooks/useLoadingContext'
import LoadingProgress from '../../Elements/LoadingProgress'
import { LoadingContext } from '../../Context/LoadingContext'
import { useLoadingIds } from '../../../hooks/useLoadingIds'
import { Outlet, useLocation } from 'react-router'
import { useUser } from '../../../UserContext'
import { useAuth } from '../../Routes/AuthProvider'

const DashboardTab: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { selectedActorRole } = useUser()
  const { directorDashboard } = useAccountContext()
  const { userDetails } = useAuth()
  const { dashboardStatistics, licensing } = directorDashboard
  const { addLoadingIds, loadingIds } = React.useContext(LoadingContext)

  const { DashboardTab: dashboardTab } = useLoadingIds()
  useLoadingContext({
    asyncFunction: directorDashboard.fetchDirectorDashboardInformation,
    loadingId: dashboardTab.fetchDashboard,
  })

  /**
   * This effect is used to add the loading id for the dashboard tab when the current location is the dashboard route.
   * This fetches the dashboard information when the user switch the acting as role, and it's not a parent.
   */
  useEffect(() => {
    if (
      location.pathname.includes('/account/dashboard') &&
      userDetails.actingAs !== 'parent'
    ) {
      addLoadingIds([dashboardTab.fetchDashboard])
    }
  }, [
    userDetails.actingAs,
    addLoadingIds,
    dashboardTab.fetchDashboard,
    location.pathname,
  ])

  if (loadingIds.has(dashboardTab.fetchDashboard)) {
    return <LoadingProgress />
  }

  if (!directorDashboard.myPrograms) {
    return (
      <EmptyPage
        message={
          <Typography variant="subtitle2" component="p">
            {t('Dashboard.NoPrograms.EmptyPage', 'No Programs Available')}
          </Typography>
        }
      />
    )
  }

  /**TODO: Research about the use of Outlets and Layouts to refactor this section, for more info
   *  see: https://classicalconversations.my.workfront.com/task/64a6e2ad000e754b523b2a5bba454026/updates
   * */
  const actorRoleValid = ` ${selectedActorRole?.subtitle && '/'} ${
    selectedActorRole?.subtitle ?? ''
  }`

  const actorRoleName = `${selectedActorRole?.name.toString()} ${actorRoleValid}`

  if (location.pathname === '/account/dashboard') {
    return (
      <>
        <DynamicBreadcrumbs
          breadcrumbs={[
            {
              label: t(
                'Account.Dashboard.Breadcrumb',
                '{{actorRoleName}} - Dashboard',
                { actorRoleName }
              ),
            },
          ]}
        />
        <DashboardCard
          licensing={licensing}
          programs={dashboardStatistics?.myProgramsTotal}
          outstandingTuition={dashboardStatistics?.outstandingTuitionTotal}
          pendingEnrollments={dashboardStatistics?.pendingEnrollmentsTotal}
          invitations={dashboardStatistics?.outstandingInvitationsTotal}
        />
      </>
    )
  }
  return <Outlet />
}

export default DashboardTab
