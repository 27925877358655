import { ProgramType, SingleActorMetricResult } from '../../../swagger'
import { dateToSlashStringReinterpretedAsLocal } from '../../../utils/dateUtility'
import { ProgramData } from '../../Card/DirectorDashboardDetails'

export const parseActorsMetrics = (
  actorsMetrics: SingleActorMetricResult[]
): ProgramData[] => {
  if (actorsMetrics.length === 0) return []

  const actorMetricsByObj: { [k: string]: string }[] = []
  /**
   * This seems like overkill, but this is the current conversion of the actorsMetrics
   * We've got to do this again for the downlineMetrics
   */
  for (const { metricValues } of actorsMetrics) {
    if (!metricValues) continue
    for (const metricValue of metricValues) {
      const actorMetricObj: { [k: string]: string } = {}
      for (const [key, value] of Object.entries(metricValue)) {
        actorMetricObj[key] = `${value}`
      }
      actorMetricsByObj.push(actorMetricObj)
    }
  }
  // We shouldn't get here but it's a failsafe in case metricValues isn't available or it is and it's empty
  if (actorMetricsByObj.length === 0) return []
  const programsToRender: ProgramData[] = []
  for (const {
    programKey,
    actionLink,
    programTypeKey: programType,
    communityKey,
    communityName,
    academicYear,
    total,
    validFrom,
    validTo,
    Fullname,
    Rolename,
  } of actorMetricsByObj) {
    programsToRender.push({
      actionLink,
      programKey: +programKey,
      programType: programType as ProgramType,
      communityKey: +communityKey,
      communityName,
      year: +academicYear,
      total: +total,
      validFrom: !isNaN(new Date(validFrom).getTime())
        ? dateToSlashStringReinterpretedAsLocal(new Date(validFrom))
        : '',
      validTo: !isNaN(new Date(validTo).getTime())
        ? dateToSlashStringReinterpretedAsLocal(new Date(validTo))
        : '',
      Fullname,
      Rolename,
    })
  }
  return programsToRender
}
