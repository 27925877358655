import { FetchMetricMetricIdEnum } from '../../../swagger'

export const getMetricIdForRoute = (path: string): FetchMetricMetricIdEnum => {
  const lastPart = path.split('/').slice(-1).pop() ?? ''
  const metricIds = Object.values(FetchMetricMetricIdEnum)

  return metricIds.filter((id) =>
    new RegExp(id, 'i').test(lastPart.split('-').join(''))
  )[0] as FetchMetricMetricIdEnum
}
