import React from 'react'
import { useTranslation } from 'react-i18next'
import { StudentInfo } from '../../../../hooks/useBreakdownDataLicensingFee'
import getLocaleCurrencyForAmount from '../../../../utils/getLocaleCurrencyForAmount'
import { LedgerCell, LedgerRow } from '../../../Table/LedgerTable'
import { BreakdownForPDF } from './BreakdownForPDF'
import { borderDashed, fontFamily } from './licensingBillingPdfStyles'

export enum BreakDownLedgerPDFVariants {
  Balance = 'Balance',
  Normal = 'Normal',
}

export interface NormalVariantProps {
  variant: BreakDownLedgerPDFVariants.Normal
  firstStudent: StudentInfo
  additionalStudent: StudentInfo
  subtotal: number
  headerLabel: string
}

export interface BalanceVariantProps {
  variant: BreakDownLedgerPDFVariants.Balance
  totalOwed: number
  totalPaid: number
  balance: number
  currencyCode: string
  programName: string
}

interface BreakdownLedgerRowForPDF {
  content?: string
  cssTextProps?: React.CSSProperties
}

const buildBreakdownLedgerRows = (
  elements: Array<BreakdownLedgerRowForPDF>
): LedgerRow => {
  // Seeing as we always put in the empty cells.
  while (elements.length !== 4) {
    elements.push({})
  }
  const cells: LedgerCell[] = []
  for (let i = 0; i < 4; i++) {
    const { content, cssTextProps } = elements[i] ?? {
      content: '',
      cssTextProps: undefined,
    }
    cells.push({
      content: content ?? '',
      align: i === 0 ? 'left' : 'right',
      cssTextProps: cssTextProps,
    })
  }
  return { cells }
}

export const BreakdownLedgerForPDF: React.FC<
  NormalVariantProps | BalanceVariantProps
> = (props) => {
  const { t } = useTranslation()

  let rows: LedgerRow[] = []
  let label = ''

  if (props.variant === BreakDownLedgerPDFVariants.Balance) {
    rows = [
      buildBreakdownLedgerRows([
        {
          content: t('BreakdownLedger.PDF.Balance.Row.TotalOwed', 'Total Owed'),
        },
        {},
        {},
        {
          content: `$ ${getLocaleCurrencyForAmount(
            props.totalOwed,
            props.currencyCode
          )}`,
        },
      ]),
      buildBreakdownLedgerRows([
        {
          content: t('BreakdownLedger.PDF.Balance.Row.TotalPaid', 'Total Paid'),
        },
        {},
        {},
        {
          content: `${
            props.totalPaid > 0 ? '-' : ''
          } $ ${getLocaleCurrencyForAmount(
            props.totalPaid,
            props.currencyCode
          )}`,
        },
      ]),
      buildBreakdownLedgerRows([
        {
          content: t(
            'BreakdownLedger.PDF.Balance.Row.Balance',
            'Balance for {{program}}',
            {
              program: props.programName,
            }
          ),
          cssTextProps: {
            borderTop: borderDashed,
            fontWeight: 'bold',
            fontFamily: fontFamily,
          },
        },
        {
          cssTextProps: {
            borderTop: borderDashed,
          },
        },
        {
          cssTextProps: {
            borderTop: borderDashed,
          },
        },
        {
          content: `$ ${getLocaleCurrencyForAmount(
            props.balance,
            props.currencyCode
          )}`,
          cssTextProps: {
            fontWeight: 'bold',
            fontFamily: fontFamily,
            borderTop: borderDashed,
          },
        },
      ]),
    ]
  } else {
    rows = [
      buildBreakdownLedgerRows([
        {
          content: t(
            'BreakdownLedger.PDF.Normal.Row.FirstStudents',
            'First Students'
          ),
        },
        {
          content: `$ ${getLocaleCurrencyForAmount(
            props.firstStudent.feeAmount,
            props.firstStudent.currencyCode
          )}`,
        },
        { content: `${props.firstStudent.numberOfStudents}` },
        {
          content: `$ ${getLocaleCurrencyForAmount(
            props.firstStudent.studentInfoSubTotal,
            props.firstStudent.currencyCode
          )}`,
        },
      ]),
      buildBreakdownLedgerRows([
        {
          content: t(
            'BreakdownLedger.PDF.Normal.Row.AdditionalStudents',
            'Additional Students'
          ),
        },
        {
          content: `$ ${getLocaleCurrencyForAmount(
            props.additionalStudent.feeAmount,
            props.additionalStudent.currencyCode
          )}`,
        },
        { content: `${props.additionalStudent.numberOfStudents}` },
        {
          content: `$ ${getLocaleCurrencyForAmount(
            props.additionalStudent.studentInfoSubTotal,
            props.additionalStudent.currencyCode
          )}`,
        },
      ]),
      buildBreakdownLedgerRows([
        {},
        {},
        {},
        {
          content: `$ ${getLocaleCurrencyForAmount(
            props.subtotal,
            props.firstStudent.currencyCode
          )}`,
          cssTextProps: {
            fontWeight: 'bold',
            fontFamily: fontFamily,
            borderTop: borderDashed,
          },
        },
      ]),
    ]
    label = props.headerLabel
  }

  return <BreakdownForPDF breakdownRows={rows} headerLabel={label} />
}
