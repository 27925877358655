import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { Countries, Country } from '../../swagger'
import { OperationIds } from '../../swagger/operationIdEnum'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { SnackbarSeverity, useSnackbarContext } from './SnackbarContext'
import { useAuth } from '../Routes/AuthProvider'
import { LoadingContext } from './LoadingContext'
import { addressApi, extractedErrorObject } from '../../api/swagger'
import useLoadingContext from '../../hooks/useLoadingContext'
import { sortByName } from '../../hooks/useStatesForCountry'

export const defaultCountryContextValue = {
  countryOptions: [] as Country[],
  updateCountryOptions: (countryOptions: Country[]): void => {
    console.warn(
      `The CountryContext.updateCountryOptions was called with value ${countryOptions}. Did you forget to use a CountryProvider?`
    )
  },

  countryOptionsLoadingId: OperationIds.FetchCountries,
}

export const CountryContext = React.createContext(defaultCountryContextValue)

export type TestCountryContextConfig =  typeof defaultCountryContextValue

export const useCountryContext = (): typeof defaultCountryContextValue =>
  React.useContext(CountryContext)
interface CountryProviderProps extends PropsWithChildren {
  /** Useful when needing to override and spy on values or setters in testing. */
  testConfig?: Partial<TestCountryContextConfig>
}
export const CountryProvider: React.FC<CountryProviderProps> = ({ testConfig ,children }) => {
  const availableLoadingIds = useLoadingIds()

  const fallbackCountries = useRef<Country[]>([
    { name: 'United States', countryCode: 'US' },
  ])

  const [countryOptions, setCountryOptions] = useState<Country[]>(
    fallbackCountries.current
  )
  const updateCountryOptions = (countryOptions: Country[]) => {
    setCountryOptions(countryOptions)
  }

  const countryOptionsLoadingId =
    availableLoadingIds.AccountContext.fetchCountries

  const { addLoadingIds } = React.useContext(LoadingContext)
  const { setSnackbarMessage, setSnackbarSeverity, setSnackbarState } =
    useSnackbarContext()
  const { isLoggedIn } = useAuth()

  const fetchCountries = async () => {
    let fetchedCountries: Countries
    try {
      fetchedCountries = await addressApi.fetchCountries({})

      /**
       * Only update the countries if we have any. They're already defaulted to
       * the fallback countries
       */
      if (fetchedCountries.data) {
        const countryOptions = fetchedCountries.data.sort(sortByName)
        updateCountryOptions(countryOptions)
      }
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown Code',
        message: (e as unknown as Error).message,
      }
      setSnackbarSeverity?.(SnackbarSeverity.Error)
      setSnackbarMessage?.(errorObject.message)
      setSnackbarState?.(true)
    }
  }

  useLoadingContext({
    loadingId: countryOptionsLoadingId,
    asyncFunction: fetchCountries,
  })

  /**
   *  Fetch countries to populate dropdowns.
   */
  useEffect(() => {
    if (
      isLoggedIn &&
      countryOptions.every(
        (option, index) =>
          option.countryCode === fallbackCountries.current[index].countryCode
      )
    )
      addLoadingIds([countryOptionsLoadingId])
  }, [
    isLoggedIn,
    countryOptions,
    addLoadingIds,
    countryOptionsLoadingId,
    fallbackCountries,
  ])

  const value = {
    countryOptions,
    updateCountryOptions,
    countryOptionsLoadingId: availableLoadingIds.AccountContext.fetchCountries,
    ...testConfig
  } as typeof defaultCountryContextValue

  return (
    <CountryContext.Provider value={value}>{children}</CountryContext.Provider>
  )
}

export default CountryProvider
