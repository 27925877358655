/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  AcademicYearPrograms,
  AcademicYearProgramsFromJSON,
  Communities,
  CommunitiesFromJSON,
  FetchDashboardResponse,
  FetchDashboardResponseFromJSON,
  UserCommunity,
  UserCommunityFromJSON,
  UserProfile,
  UserProfileEditBody,
  UserProfileEditBodyToJSON,
  UserProfileFromJSON,
  UserTeam,
  UserTeamFromJSON,
} from '../models'
import * as runtime from '../runtime'

export interface EditUserProfileRequest extends runtime.BaseRequestParameters {
  body?: UserProfileEditBody
}

export interface FetchCommunitiesForUserRequest
  extends runtime.BaseRequestParameters {
  userId: string
  page?: number
  pageSize?: number
  orderBy?: Array<string>
  academicYear?: number
  search?: string
}

export interface FetchProgramsForUserRequest
  extends runtime.BaseRequestParameters {
  userId: string
}

export interface FetchTeamForUserRequest extends runtime.BaseRequestParameters {
  userId: string
  page?: number
  pageSize?: number
  orderBy?: Array<string>
  actorKey?: number
}

export interface FetchUserCommunitiesRequest
  extends runtime.BaseRequestParameters {
  userId: string
  validNow?: boolean
}

export interface FetchUserProfileRequest extends runtime.BaseRequestParameters {
  userId: string
}

export interface FetchUserProfileImageRequest
  extends runtime.BaseRequestParameters {
  userId: string
}

/**
 *
 */
export class UsersApi extends runtime.BaseAPI {
  /**
   * Edits the user’s profile information.
   */
  async editUserProfileRaw(
    requestParameters: EditUserProfileRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/u/`,
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: UserProfileEditBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Edits the user’s profile information.
   */
  async editUserProfile(
    requestParameters: EditUserProfileRequest
  ): Promise<void> {
    await this.editUserProfileRaw(requestParameters)
  }

  /**
   *    Calling this for the \'me\' will give you a list of communities the current user is currently enrolled in or managing. Providing a \'userId\' will give you a list of communities that specific user manages or is enrolled in, if the requesting user is allowed to do so.      ## FILTERING:   - search: search text must be at least 3 characters in length. Including this query parameter will match your search text to a community\'s name and support rep name.   - academicYear: will return communities within the specific year     ## PAGINATION:   - Pagination is only utilized for managedCommunities, and has no effect on the results of memberCommunities.   - If pagination query parameters are included, the request will be paginated and ordered by the specified page, pageSize, and orderBy.   - orderBy: valid order by properties include: name. Supplying anything else will result in an error. - The requested pagination will be returned in the response, including the total count of all data, which will allow the requester to calculate the total amount of pages available with the current pageSize.
   * List of communities enrolled or managed by a user.
   */
  async fetchCommunitiesForUserRaw(
    requestParameters: FetchCommunitiesForUserRequest
  ): Promise<runtime.ApiResponse<Communities>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling fetchCommunitiesForUser.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters['pageSize'] = requestParameters.pageSize
    }

    if (requestParameters.orderBy) {
      queryParameters['orderBy'] = requestParameters.orderBy.join(
        runtime.COLLECTION_FORMATS['csv']
      )
    }

    if (requestParameters.academicYear !== undefined) {
      queryParameters['academicYear'] = requestParameters.academicYear
    }

    if (requestParameters.search !== undefined) {
      queryParameters['search'] = requestParameters.search
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/u/{userId}/communities`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(requestParameters.userId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CommunitiesFromJSON(jsonValue)
    )
  }

  /**
   *    Calling this for the \'me\' will give you a list of communities the current user is currently enrolled in or managing. Providing a \'userId\' will give you a list of communities that specific user manages or is enrolled in, if the requesting user is allowed to do so.      ## FILTERING:   - search: search text must be at least 3 characters in length. Including this query parameter will match your search text to a community\'s name and support rep name.   - academicYear: will return communities within the specific year     ## PAGINATION:   - Pagination is only utilized for managedCommunities, and has no effect on the results of memberCommunities.   - If pagination query parameters are included, the request will be paginated and ordered by the specified page, pageSize, and orderBy.   - orderBy: valid order by properties include: name. Supplying anything else will result in an error. - The requested pagination will be returned in the response, including the total count of all data, which will allow the requester to calculate the total amount of pages available with the current pageSize.
   * List of communities enrolled or managed by a user.
   */
  async fetchCommunitiesForUser(
    requestParameters: FetchCommunitiesForUserRequest
  ): Promise<Communities> {
    const response = await this.fetchCommunitiesForUserRaw(requestParameters)
    return await response.value()
  }

  /**
   * Returns necessary information to show within an actor\'s dashboard.
   */
  async fetchDashboardRaw(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<runtime.ApiResponse<FetchDashboardResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/dashboard`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FetchDashboardResponseFromJSON(jsonValue)
    )
  }

  /**
   * Returns necessary information to show within an actor\'s dashboard.
   */
  async fetchDashboard(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<FetchDashboardResponse> {
    const response = await this.fetchDashboardRaw(requestParameters)
    return await response.value()
  }

  /**
   *  Lists programs available to the user by academic year. \'me\' lists programs available for the current user, \'userId:\' provides programs available for the given user. When the specified userId is \'me\' or the same as the requester\'s userKey, programs will be returned that the requester can see.  PERMISSIONS FOR ME/CURRENT USER KEY: - No permissions: A user can always see the programs that they are \'in\'. Being in a program means that the user has an active enrollment in the program, OR is the director or tutor of the program and the director/tutor role is active. - Program/view[their team\'s] allows the requester to view programs in their downline team. - Program/view[any] allows the requester to view all programs.  If the specified userId is not \'me\' or the same as the logged in user, the requester must be able to see the requested user. The returned programs are the intersection between the programs that the requester can see, AND the programs that the requested user can see. This follows the same rules as above. A 400 status code will be returned if the requester cannot see the requested user, or if the requested user does not exist.  PERMISSIONS FOR OTHER USER KEY: - User/view[their team\'s] allows the requester returns programs of the requested user if that user is within the requester\'s team (downline or direct supervisor). - User/view[any] allows the requester to see programs of all users. - The intersection between programs the requester and the requested user can see is determined by the same Program/view permissions above for BOTH users.
   * List of programs by academic year available for the specified user.
   */
  async fetchProgramsForUserRaw(
    requestParameters: FetchProgramsForUserRequest
  ): Promise<runtime.ApiResponse<Array<AcademicYearPrograms>>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling fetchProgramsForUser.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/u/{userId}/programs`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(requestParameters.userId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(AcademicYearProgramsFromJSON)
    )
  }

  /**
   *  Lists programs available to the user by academic year. \'me\' lists programs available for the current user, \'userId:\' provides programs available for the given user. When the specified userId is \'me\' or the same as the requester\'s userKey, programs will be returned that the requester can see.  PERMISSIONS FOR ME/CURRENT USER KEY: - No permissions: A user can always see the programs that they are \'in\'. Being in a program means that the user has an active enrollment in the program, OR is the director or tutor of the program and the director/tutor role is active. - Program/view[their team\'s] allows the requester to view programs in their downline team. - Program/view[any] allows the requester to view all programs.  If the specified userId is not \'me\' or the same as the logged in user, the requester must be able to see the requested user. The returned programs are the intersection between the programs that the requester can see, AND the programs that the requested user can see. This follows the same rules as above. A 400 status code will be returned if the requester cannot see the requested user, or if the requested user does not exist.  PERMISSIONS FOR OTHER USER KEY: - User/view[their team\'s] allows the requester returns programs of the requested user if that user is within the requester\'s team (downline or direct supervisor). - User/view[any] allows the requester to see programs of all users. - The intersection between programs the requester and the requested user can see is determined by the same Program/view permissions above for BOTH users.
   * List of programs by academic year available for the specified user.
   */
  async fetchProgramsForUser(
    requestParameters: FetchProgramsForUserRequest
  ): Promise<Array<AcademicYearPrograms>> {
    const response = await this.fetchProgramsForUserRaw(requestParameters)
    return await response.value()
  }

  /**
   * It will fetch the direct team members (actors) for the logged in user if userId = me, or the direct team for the provided userId, including those who either me or userID is supported by.   If actorKey is passed in the query string, then it will only pull the teams for that specific actor.
   * Fetch user\'s supported actors (team members) and actors they\'re supported by. All of them with their associated users and roles
   */
  async fetchTeamForUserRaw(
    requestParameters: FetchTeamForUserRequest
  ): Promise<runtime.ApiResponse<UserTeam>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling fetchTeamForUser.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters['pageSize'] = requestParameters.pageSize
    }

    if (requestParameters.orderBy) {
      queryParameters['orderBy'] = requestParameters.orderBy.join(
        runtime.COLLECTION_FORMATS['csv']
      )
    }

    if (requestParameters.actorKey !== undefined) {
      queryParameters['actorKey'] = requestParameters.actorKey
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/u/{userId}/team`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(requestParameters.userId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserTeamFromJSON(jsonValue)
    )
  }

  /**
   * It will fetch the direct team members (actors) for the logged in user if userId = me, or the direct team for the provided userId, including those who either me or userID is supported by.   If actorKey is passed in the query string, then it will only pull the teams for that specific actor.
   * Fetch user\'s supported actors (team members) and actors they\'re supported by. All of them with their associated users and roles
   */
  async fetchTeamForUser(
    requestParameters: FetchTeamForUserRequest
  ): Promise<UserTeam> {
    const response = await this.fetchTeamForUserRaw(requestParameters)
    return await response.value()
  }

  /**
   * Returns the user’s communities and their roles therein, if any.
   */
  async fetchUserCommunitiesRaw(
    requestParameters: FetchUserCommunitiesRequest
  ): Promise<runtime.ApiResponse<Array<UserCommunity>>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling fetchUserCommunities.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.validNow !== undefined) {
      queryParameters['validNow'] = requestParameters.validNow
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/u/{userId}/roles`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(requestParameters.userId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(UserCommunityFromJSON)
    )
  }

  /**
   * Returns the user’s communities and their roles therein, if any.
   */
  async fetchUserCommunities(
    requestParameters: FetchUserCommunitiesRequest
  ): Promise<Array<UserCommunity>> {
    const response = await this.fetchUserCommunitiesRaw(requestParameters)
    return await response.value()
  }

  /**
   * Returns the user’s profile.
   */
  async fetchUserProfileRaw(
    requestParameters: FetchUserProfileRequest
  ): Promise<runtime.ApiResponse<UserProfile>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling fetchUserProfile.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/u/{userId}`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(requestParameters.userId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserProfileFromJSON(jsonValue)
    )
  }

  /**
   * Returns the user’s profile.
   */
  async fetchUserProfile(
    requestParameters: FetchUserProfileRequest
  ): Promise<UserProfile> {
    const response = await this.fetchUserProfileRaw(requestParameters)
    return await response.value()
  }

  /**
   * Redirects to the URL of the user’s profile image.
   */
  async fetchUserProfileImageRaw(
    requestParameters: FetchUserProfileImageRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling fetchUserProfileImage.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/u/{userId}/image`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(requestParameters.userId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Redirects to the URL of the user’s profile image.
   */
  async fetchUserProfileImage(
    requestParameters: FetchUserProfileImageRequest
  ): Promise<void> {
    await this.fetchUserProfileImageRaw(requestParameters)
  }
}
