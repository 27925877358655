/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The action link returned from fetchMetrics
 * @export
 * @enum {string}
 */
export enum ActionLink {
  LicensingDue = '/account/new-dashboard/licensing-due',
  InProgressInvitations = '/account/new-dashboard/in-progress-invitations',
  PendingEnrollments = '/account/new-dashboard/pending-enrollments',
  UnassignedDirectors = '/account/new-dashboard/unassigned-directors',
  OutstandingTuition = '/account/new-dashboard/outstanding-tuition',
  Programs = '/account/new-dashboard/programs',
}

export function ActionLinkFromJSON(json: any): ActionLink {
  return ActionLinkFromJSONTyped(json, false)
}

export function ActionLinkFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ActionLink {
  return json as ActionLink
}

export function ActionLinkToJSON(value?: ActionLink | null): any {
  return value as any
}
