/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  DashboardStyleEnum,
  DashboardStyleEnumFromJSON,
  DashboardStyleEnumToJSON,
} from './'

/**
 *
 * @export
 * @interface SingleDownlineMetricResult
 */
export interface SingleDownlineMetricResult {
  /**
   * The actor key in the downline.
   * @type {number}
   * @memberof SingleDownlineMetricResult
   */
  downlineActorKey?: number
  /**
   * The name of the person in the downline.
   * @type {string}
   * @memberof SingleDownlineMetricResult
   */
  downlineName?: string
  /**
   * The name of the role in the downline.
   * @type {string}
   * @memberof SingleDownlineMetricResult
   */
  downlineRole?: string
  /**
   * The computed value of the metric for the downline. Different meaning depending on the metric that's been calculated.
   * @type {number}
   * @memberof SingleDownlineMetricResult
   */
  downlineMetricValue?: number
  /**
   *
   * @type {DashboardStyleEnum}
   * @memberof SingleDownlineMetricResult
   */
  downlineMetricStyle?: DashboardStyleEnum
}

export function SingleDownlineMetricResultFromJSON(
  json: any
): SingleDownlineMetricResult {
  return SingleDownlineMetricResultFromJSONTyped(json, false)
}

export function SingleDownlineMetricResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SingleDownlineMetricResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    downlineActorKey: !exists(json, 'downlineActorKey')
      ? undefined
      : json['downlineActorKey'],
    downlineName: !exists(json, 'downlineName')
      ? undefined
      : json['downlineName'],
    downlineRole: !exists(json, 'downlineRole')
      ? undefined
      : json['downlineRole'],
    downlineMetricValue: !exists(json, 'downlineMetricValue')
      ? undefined
      : json['downlineMetricValue'],
    downlineMetricStyle: !exists(json, 'downlineMetricStyle')
      ? undefined
      : DashboardStyleEnumFromJSON(json['downlineMetricStyle']),
  }
}

export function SingleDownlineMetricResultToJSON(
  value?: SingleDownlineMetricResult | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    downlineActorKey: value.downlineActorKey,
    downlineName: value.downlineName,
    downlineRole: value.downlineRole,
    downlineMetricValue: value.downlineMetricValue,
    downlineMetricStyle: DashboardStyleEnumToJSON(value.downlineMetricStyle),
  }
}
