/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import { ProgramType, ProgramTypeFromJSON, ProgramTypeToJSON } from './'

/**
 *
 * @export
 * @interface LicensingPaymentDetails
 */
export interface LicensingPaymentDetails {
  /**
   *
   * @type {number}
   * @memberof LicensingPaymentDetails
   */
  licensingPaymentKey: number
  /**
   *
   * @type {number}
   * @memberof LicensingPaymentDetails
   */
  programKey: number
  /**
   *
   * @type {ProgramType}
   * @memberof LicensingPaymentDetails
   */
  programType?: ProgramType
  /**
   * The starting date for the program's first semester in date format: `yyyy-MM-dd`.
   * @type {Date}
   * @memberof LicensingPaymentDetails
   */
  semesterOneStartDate?: Date
  /**
   * The name of the program's community.
   * @type {string}
   * @memberof LicensingPaymentDetails
   */
  communityName?: string
  /**
   * The unique identifier of the program's community
   * @type {number}
   * @memberof LicensingPaymentDetails
   */
  communityKey?: number
}

export function LicensingPaymentDetailsFromJSON(
  json: any
): LicensingPaymentDetails {
  return LicensingPaymentDetailsFromJSONTyped(json, false)
}

export function LicensingPaymentDetailsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LicensingPaymentDetails {
  if (json === undefined || json === null) {
    return json
  }
  return {
    licensingPaymentKey: json['licensingPaymentKey'],
    programKey: json['programKey'],
    programType: !exists(json, 'programType')
      ? undefined
      : ProgramTypeFromJSON(json['programType']),
    semesterOneStartDate: !exists(json, 'semesterOneStartDate')
      ? undefined
      : new Date(json['semesterOneStartDate']),
    communityName: !exists(json, 'communityName')
      ? undefined
      : json['communityName'],
    communityKey: !exists(json, 'communityKey')
      ? undefined
      : json['communityKey'],
  }
}

export function LicensingPaymentDetailsToJSON(
  value?: LicensingPaymentDetails | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    licensingPaymentKey: value.licensingPaymentKey,
    programKey: value.programKey,
    programType: ProgramTypeToJSON(value.programType),
    semesterOneStartDate:
      value.semesterOneStartDate === undefined
        ? undefined
        : value.semesterOneStartDate.toISOString().substr(0, 10),
    communityName: value.communityName,
    communityKey: value.communityKey,
  }
}
