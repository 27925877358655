/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  CashAmount,
  CashAmountFromJSON,
  CashAmountToJSON,
  PaymentMethodKey,
  PaymentMethodKeyFromJSON,
  PaymentMethodKeyToJSON,
  PaymentStatusKey,
  PaymentStatusKeyFromJSON,
  PaymentStatusKeyToJSON,
} from './'

/**
 *
 * @export
 * @interface LicensingInternationalPayment
 */
export interface LicensingInternationalPayment {
  /**
   * The unique identifier of the payment.
   * @type {number}
   * @memberof LicensingInternationalPayment
   */
  paymentKey: number
  /**
   * The date and time the payment was initiated.
   * @type {Date}
   * @memberof LicensingInternationalPayment
   */
  begunAt: Date
  /**
   * Identifier of the user associated with the payment.
   * @type {number}
   * @memberof LicensingInternationalPayment
   */
  userKey: number
  /**
   *
   * @type {PaymentMethodKey}
   * @memberof LicensingInternationalPayment
   */
  paymentMethodKey: PaymentMethodKey
  /**
   *
   * @type {CashAmount}
   * @memberof LicensingInternationalPayment
   */
  amount: CashAmount
  /**
   *
   * @type {CashAmount}
   * @memberof LicensingInternationalPayment
   */
  tax?: CashAmount
  /**
   *
   * @type {CashAmount}
   * @memberof LicensingInternationalPayment
   */
  total: CashAmount
  /**
   * Optional Acumatica order ID.
   * @type {string}
   * @memberof LicensingInternationalPayment
   */
  orderId?: string
  /**
   *
   * @type {PaymentStatusKey}
   * @memberof LicensingInternationalPayment
   */
  paymentStatusKey: PaymentStatusKey
  /**
   * Description or notes for the payment.
   * @type {string}
   * @memberof LicensingInternationalPayment
   */
  desc?: string
  /**
   * Username of the actor who made the payment.
   * @type {string}
   * @memberof LicensingInternationalPayment
   */
  paidBy?: string
  /**
   * Unique identifier for the coordinator's region.
   * @type {number}
   * @memberof LicensingInternationalPayment
   */
  regionKey: number
  /**
   * Name of the coordinator's region.
   * @type {string}
   * @memberof LicensingInternationalPayment
   */
  regionName: string
}

export function LicensingInternationalPaymentFromJSON(
  json: any
): LicensingInternationalPayment {
  return LicensingInternationalPaymentFromJSONTyped(json, false)
}

export function LicensingInternationalPaymentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LicensingInternationalPayment {
  if (json === undefined || json === null) {
    return json
  }
  return {
    paymentKey: json['paymentKey'],
    begunAt: new Date(json['begunAt']),
    userKey: json['userKey'],
    paymentMethodKey: PaymentMethodKeyFromJSON(json['paymentMethodKey']),
    amount: CashAmountFromJSON(json['amount']),
    tax: !exists(json, 'tax') ? undefined : CashAmountFromJSON(json['tax']),
    total: CashAmountFromJSON(json['total']),
    orderId: !exists(json, 'orderId') ? undefined : json['orderId'],
    paymentStatusKey: PaymentStatusKeyFromJSON(json['paymentStatusKey']),
    desc: !exists(json, 'desc') ? undefined : json['desc'],
    paidBy: !exists(json, 'paidBy') ? undefined : json['paidBy'],
    regionKey: json['regionKey'],
    regionName: json['regionName'],
  }
}

export function LicensingInternationalPaymentToJSON(
  value?: LicensingInternationalPayment | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    paymentKey: value.paymentKey,
    begunAt: value.begunAt.toISOString().substr(0, 10),
    userKey: value.userKey,
    paymentMethodKey: PaymentMethodKeyToJSON(value.paymentMethodKey),
    amount: CashAmountToJSON(value.amount),
    tax: CashAmountToJSON(value.tax),
    total: CashAmountToJSON(value.total),
    orderId: value.orderId,
    paymentStatusKey: PaymentStatusKeyToJSON(value.paymentStatusKey),
    desc: value.desc,
    paidBy: value.paidBy,
    regionKey: value.regionKey,
    regionName: value.regionName,
  }
}
