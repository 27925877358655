import React, { useEffect, useState } from 'react'
import DynamicBreadcrumbs from '../Elements/DynamicBreadcrumbs'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { ElevatedCardVariants } from './ElevatedCard'
import { useAccountContext } from '../Context/AccountContext'
import { Dashboard, ProgramDashboard } from '../../swagger'
import CardFormHeader from './CardFormHeader'
import Header, { HeaderVariant } from '../Elements/Header'
import { useMountEffect } from '../../hooks/useMountEffect'
import ProgramRenderer from '../Programs/ProgramRenderer'
import useSortedByTypeDashboardPrograms from '../../hooks/useSortedDashboardPrograms'
import useLoadingContext from '../../hooks/useLoadingContext'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { LoadingContext } from '../Context/LoadingContext'
// Change to swagger import later

export enum DirectorDashboardDetailsVariants {
  pendingEnrollments = 'pendingEnrollments',
  outstandingTuition = 'outstandingTuition',
  outstandingEnrollments = 'outstandingEnrollments',
  outstandingInvitations = 'outstandingInvitations',
  programs = 'programs',
  billing = 'Billing',
}

export type ProgramData = ProgramDashboard &
  Dashboard & {
    Fullname?: string
    Rolename?: string
    validTo?: string
    validFrom?: string
    actionLink?: string
  }

const DirectorDashboardDetails: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { directorDashboard } = useAccountContext()

  const { DashboardTab } = useLoadingIds()

  const { addLoadingIds } = React.useContext(LoadingContext)

  useLoadingContext({
    asyncFunction: directorDashboard.fetchDirectorDashboardInformation,
    loadingId: DashboardTab.fetchDashboard,
  })

  useMountEffect(() => {
    addLoadingIds([DashboardTab.fetchDashboard])
  })

  const {
    myPrograms,
    outstandingInvitations,
    pendingEnrollments,
    outstandingTuition,
  } = directorDashboard
  const location: { state: { variant: DirectorDashboardDetailsVariants } } =
    useLocation()

  const { variant } = location.state

  const isProgram = variant === DirectorDashboardDetailsVariants.programs
  const isPendingEnrollment =
    variant === DirectorDashboardDetailsVariants.pendingEnrollments
  const isOutstandingTuition =
    variant === DirectorDashboardDetailsVariants.outstandingTuition
  const isInvitations =
    variant === DirectorDashboardDetailsVariants.outstandingInvitations

  const [programData, setProgramData] = useState<ProgramData[] | undefined>([])
  const sortedPrograms = useSortedByTypeDashboardPrograms(
    myPrograms as ProgramData[]
  )
  const sortedPendingEnrollments = useSortedByTypeDashboardPrograms(
    pendingEnrollments ?? []
  )
  const sortedOutstandingTuition = useSortedByTypeDashboardPrograms(
    outstandingTuition ?? []
  )
  const sortedInvitations = useSortedByTypeDashboardPrograms(
    outstandingInvitations ?? []
  )

  useEffect(() => {
    if (isProgram) setProgramData(sortedPrograms)
    if (isPendingEnrollment)
      setProgramData(sortedPendingEnrollments as Dashboard[])
    if (isOutstandingTuition)
      setProgramData(sortedOutstandingTuition as Dashboard[])
    if (isInvitations) setProgramData(sortedInvitations as Dashboard[])
  }, [
    isProgram,
    isPendingEnrollment,
    isOutstandingTuition,
    isInvitations,
    sortedPrograms,
    sortedInvitations,
    sortedPendingEnrollments,
    sortedOutstandingTuition,
  ])

  const header = () => {
    switch (variant) {
      case DirectorDashboardDetailsVariants.pendingEnrollments:
        return t(
          'Account.Dashboard.PendingEnrollments.Header',
          'Pending Enrollments'
        )
      case DirectorDashboardDetailsVariants.outstandingTuition:
        return t(
          'Account.Dashboard.OutstandingTuition.Header',
          'Outstanding Tuition'
        )
      case DirectorDashboardDetailsVariants.outstandingInvitations:
        return t(
          'Account.Dashboard.InProgressInvitations.Header',
          'In Progress Invitations'
        )
      case DirectorDashboardDetailsVariants.programs:
        return t('Account.Dashboard.Programs.Header', 'Programs')
    }
  }

  return (
    <>
      <DynamicBreadcrumbs
        breadcrumbs={[
          {
            label: t(
              'Account.Dashboard.Breadcrumb.MyDashboard',
              'My Dashboard'
            ),
            onClick: () => navigate(-1),
          },
          {
            ...(isProgram && {
              label: t('Account.Dashboard.Breadcrumb.Programs', 'Programs'),
            }),
            ...(isPendingEnrollment && {
              label: t(
                'Account.Dashboard.Breadcrumb.PendingEnrollments',
                'Pending Enrollments'
              ),
            }),
            ...(isOutstandingTuition && {
              label: t(
                'Account.Dashboard.Breadcrumb.OutstandingTuition',
                'Outstanding Tuition'
              ),
            }),
            ...(isInvitations && {
              label: t(
                'Account.Dashboard.Breadcrumb.InProgressInvitations',
                'In Progress Invitations'
              ),
            }),
          },
        ]}
      />
      <CardFormHeader
        headerContainerProps={{ margin: 0 }}
        header={
          <Header
            headerName={header()}
            component="h1"
            variant={HeaderVariant.Card}
          />
        }
      />

      <ProgramRenderer
        type={ElevatedCardVariants.DirectorDashboard}
        programs={programData ?? []}
        isOutstandingTuition={isOutstandingTuition}
        isPendingEnrollment={isPendingEnrollment}
        isInvitation={isInvitations}
      />
    </>
  )
}

export default DirectorDashboardDetails
