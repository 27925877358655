/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  TuitionPayments,
  TuitionPaymentsFromJSON,
  TuitionPaymentsToJSON,
} from './'

/**
 * The request body for adding a tuition payments
 * @export
 * @interface AddTuitionPaymentRequestBody
 */
export interface AddTuitionPaymentRequestBody {
  /**
   * The unique identifier for the invoice the tuition payment is for
   * @type {number}
   * @memberof AddTuitionPaymentRequestBody
   */
  invoiceKey: number
  /**
   * The total amount of the tuition payments being added
   * @type {number}
   * @memberof AddTuitionPaymentRequestBody
   */
  totalAmount: number
  /**
   * Date the user received the payment from the parent for the program
   * @type {Date}
   * @memberof AddTuitionPaymentRequestBody
   */
  dateReceived?: Date
  /**
   * Description that goes along with the tuition payment
   * @type {string}
   * @memberof AddTuitionPaymentRequestBody
   */
  description: string
  /**
   * If true, this is adding a director discount for a family's tuition billing. If left empty, defaults to false
   * @type {boolean}
   * @memberof AddTuitionPaymentRequestBody
   */
  isDiscount?: boolean
  /**
   * The tuition payments to add
   * @type {Array<TuitionPayments>}
   * @memberof AddTuitionPaymentRequestBody
   */
  tuitionPayments: Array<TuitionPayments>
}

export function AddTuitionPaymentRequestBodyFromJSON(
  json: any
): AddTuitionPaymentRequestBody {
  return AddTuitionPaymentRequestBodyFromJSONTyped(json, false)
}

export function AddTuitionPaymentRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddTuitionPaymentRequestBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    invoiceKey: json['invoiceKey'],
    totalAmount: json['totalAmount'],
    dateReceived: !exists(json, 'dateReceived')
      ? undefined
      : new Date(json['dateReceived']),
    description: json['description'],
    isDiscount: !exists(json, 'isDiscount') ? undefined : json['isDiscount'],
    tuitionPayments: (json['tuitionPayments'] as Array<any>).map(
      TuitionPaymentsFromJSON
    ),
  }
}

export function AddTuitionPaymentRequestBodyToJSON(
  value?: AddTuitionPaymentRequestBody | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    invoiceKey: value.invoiceKey,
    totalAmount: value.totalAmount,
    dateReceived:
      value.dateReceived === undefined
        ? undefined
        : value.dateReceived.toISOString(),
    description: value.description,
    isDiscount: value.isDiscount,
    tuitionPayments: (value.tuitionPayments as Array<any>).map(
      TuitionPaymentsToJSON
    ),
  }
}
