import { Theme } from '@mui/material/styles'
import { DashboardStyleEnum } from '../../../swagger'
import { Color } from '../../../stylesheet'

export const getDashboardColor = (
  color: DashboardStyleEnum,
  theme: Theme
): Color => {
  switch (color.toLowerCase()) {
    case DashboardStyleEnum.Normal:
    default:
      return theme.palette.textOrIcon.onBackground
    case DashboardStyleEnum.Warning:
      return theme.palette.textOrIcon.warning
    case DashboardStyleEnum.Late:
      return theme.palette.error.main
  }
}

export default { getDashboardColor }
