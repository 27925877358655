/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  EnrollmentStatus,
  EnrollmentStatusFromJSON,
  EnrollmentStatusToJSON,
} from './'

/**
 * Body to update enrollment status
 * @export
 * @interface EnrollmentStatusUpdate
 */
export interface EnrollmentStatusUpdate {
  /**
   * The unique identifier of the student the enrollment into the program is for.
   * @type {number}
   * @memberof EnrollmentStatusUpdate
   */
  studentKey: number
  /**
   * Unique identifier for the program the student is enrolled in.
   * @type {number}
   * @memberof EnrollmentStatusUpdate
   */
  programKey: number
  /**
   *
   * @type {EnrollmentStatus}
   * @memberof EnrollmentStatusUpdate
   */
  status: EnrollmentStatus
}

export function EnrollmentStatusUpdateFromJSON(
  json: any
): EnrollmentStatusUpdate {
  return EnrollmentStatusUpdateFromJSONTyped(json, false)
}

export function EnrollmentStatusUpdateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnrollmentStatusUpdate {
  if (json === undefined || json === null) {
    return json
  }
  return {
    studentKey: json['studentKey'],
    programKey: json['programKey'],
    status: EnrollmentStatusFromJSON(json['status']),
  }
}

export function EnrollmentStatusUpdateToJSON(
  value?: EnrollmentStatusUpdate | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    studentKey: value.studentKey,
    programKey: value.programKey,
    status: EnrollmentStatusToJSON(value.status),
  }
}
