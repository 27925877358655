/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import { CashAmount, CashAmountFromJSON, CashAmountToJSON } from './'

/**
 *
 * @export
 * @interface TuitionPayments2
 */
export interface TuitionPayments2 {
  /**
   * The unique identifier for the tuition payment
   * @type {number}
   * @memberof TuitionPayments2
   */
  tuitionPaymentKey?: number
  /**
   * The unique identifier for the student the tuition payment is for
   * @type {number}
   * @memberof TuitionPayments2
   */
  studentKey: number
  /**
   * The unique identifier for the program the tuition payment is for
   * @type {number}
   * @memberof TuitionPayments2
   */
  programKey: number
  /**
   *
   * @type {CashAmount}
   * @memberof TuitionPayments2
   */
  amount: CashAmount
}

export function TuitionPayments2FromJSON(json: any): TuitionPayments2 {
  return TuitionPayments2FromJSONTyped(json, false)
}

export function TuitionPayments2FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TuitionPayments2 {
  if (json === undefined || json === null) {
    return json
  }
  return {
    tuitionPaymentKey: !exists(json, 'tuitionPaymentKey')
      ? undefined
      : json['tuitionPaymentKey'],
    studentKey: json['studentKey'],
    programKey: json['programKey'],
    amount: CashAmountFromJSON(json['amount']),
  }
}

export function TuitionPayments2ToJSON(value?: TuitionPayments2 | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    tuitionPaymentKey: value.tuitionPaymentKey,
    studentKey: value.studentKey,
    programKey: value.programKey,
    amount: CashAmountToJSON(value.amount),
  }
}
