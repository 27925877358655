import React from 'react'
import ElevatedCard, { ElevatedCardVariants } from '../Card/ElevatedCard'
import { Program } from '../../swagger'
import { useNavigate } from 'react-router'
import { Box, Typography } from '@mui/material'

import { ProgramData } from '../Card/DirectorDashboardDetails'
import { ProgramDetailsTab } from './ProgramDetails'
import { styled } from '@mui/system'
import EmptyPage from '../Elements/EmptyPage'
import { useTranslation } from 'react-i18next'

interface ProgramElevatedCard {
  type: ElevatedCardVariants.Programs
  programs: Program[]
  academicYear?: number
}

export interface DashboardDetailsProgramCard {
  type: ElevatedCardVariants.DirectorDashboard
  programs: ProgramData[]
  isOutstandingTuition?: boolean
  isPendingEnrollment?: boolean
  isInvitation?: boolean
}

type ProgramRendererProps = ProgramElevatedCard | DashboardDetailsProgramCard

const ElevatedCardContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 2, 4, 0),
  width: 'auto',
}))

const ProgramRenderer: React.FunctionComponent<ProgramRendererProps> = (
  props
) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const isDirectorDashboardView =
    props.type === ElevatedCardVariants.DirectorDashboard

  const navigateToProgram = (
    programsKey: number,
    communityId: number,
    actionLink?: string
  ) => {
    navigate(
      {
        pathname:
          isDirectorDashboardView && !!actionLink
            ? actionLink
            : `/communities/community-details/${communityId}/programs/program-details/${programsKey}`,
      },
      {
        state: isDirectorDashboardView
          ? {
              initiallySelectedTab:
                props.isPendingEnrollment || props.isOutstandingTuition
                  ? ProgramDetailsTab.enrollments
                  : props.isInvitation
                  ? ProgramDetailsTab.invites
                  : ProgramDetailsTab.programDetails,
            }
          : {
              academicYear: props.academicYear,
            },
      }
    )
  }

  if (isDirectorDashboardView) {
    //return Empty page with the appropriate element name.
    if (!!props.programs && props.programs.length === 0) {
      return (
        <EmptyPage
          message={
            <Typography variant="subtitle2" component="p">
              {props.isPendingEnrollment &&
                t(
                  'Dashboard.NoEnrollments.EmptyPendingEnrollments',
                  `No Pending Enrollments Available`
                )}
              {props.isOutstandingTuition &&
                t(
                  'Dashboard.NoEnrollments.EmptyOutstandingTuition',
                  `No Outstanding Tuition Available`
                )}
              {props.isInvitation &&
                t(
                  'Dashboard.NoInvitations.EmptyInProgressInvitations',
                  `No In Progress Invitations Available`
                )}
            </Typography>
          }
        />
      )
    }
    return (
      <Box display="flex" flexWrap="wrap" flexDirection="row">
        {!!props.programs &&
          props.programs.map((program) => (
            <ElevatedCardContainer key={program.programKey}>
              <ElevatedCard
                variant={ElevatedCardVariants.DirectorDashboard}
                communityKey={program.communityKey}
                communityName={program.communityName}
                programKey={program.programKey}
                year={program.year}
                type={program.programType}
                total={program.total}
                isPendingEnrollment={!!props.isPendingEnrollment}
                isOutstandingTuition={!!props.isOutstandingTuition}
                isInvitation={!!props.isInvitation}
                navigateToProgram={() =>
                  navigateToProgram(
                    program.programKey,
                    program.communityKey,
                    program.actionLink
                  )
                }
              />
            </ElevatedCardContainer>
          ))}
      </Box>
    )
  }

  return (
    <Box display="flex" flexWrap="wrap" flexDirection="row">
      {props.programs.map((program) => (
        <ElevatedCardContainer key={program.programsKey}>
          <ElevatedCard
            variant={ElevatedCardVariants.Programs}
            navigateToProgramDetails={() =>
              navigateToProgram(program.programsKey, program.communityId)
            }
            programsKey={program.programsKey}
            type={program.type}
            status={program.status}
            director={program.director}
            communityId={program.communityId}
            communityName={program.communityName}
            // FIXME: Actually render location name once CCP1-717 is done.
            // location={program.address?.streetAddress1}
            address={program.address}
            semesterOneStartDate={program.semesterOneStartDate}
            dayOfTheWeek={program.dayOfTheWeek}
            // FIXME: Show street2!
          />
        </ElevatedCardContainer>
      ))}
    </Box>
  )
}

export default ProgramRenderer
