/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface UserRoleAssignment
 */
export interface UserRoleAssignment {
  /**
   * Unique identifier for the actor performing the specified role for this user.
   * @type {number}
   * @memberof UserRoleAssignment
   */
  actorKey: number
  /**
   * Unique identifier for this role.
   * @type {number}
   * @memberof UserRoleAssignment
   */
  roleKey: number
  /**
   * Name of this role.
   * @type {string}
   * @memberof UserRoleAssignment
   */
  name: string
  /**
   * name of the region.
   * @type {string}
   * @memberof UserRoleAssignment
   */
  region: string
  /**
   * Unique identifier for a region.
   * @type {number}
   * @memberof UserRoleAssignment
   */
  regionKey: number
  /**
   * Name of the supervisor that this user reports to when acting with this role. Defaults to the supervisor's username if first/last name not available.
   * @type {string}
   * @memberof UserRoleAssignment
   */
  supervisor?: string
  /**
   * Unique identifier for the supervisor role Key that this user reports to when acting with this role.
   * @type {number}
   * @memberof UserRoleAssignment
   */
  supervisorRoleKey?: number
  /**
   * Unique identifier for the actor performing the specified role for this supervisor.
   * @type {number}
   * @memberof UserRoleAssignment
   */
  supervisorActorKey?: number
  /**
   * The userKey for the supervisor assigned by the system when the user was first created.
   * @type {number}
   * @memberof UserRoleAssignment
   */
  supervisorUserKey?: number
  /**
   * The date the user account obtained this role.
   * @type {Date}
   * @memberof UserRoleAssignment
   */
  validFrom: Date
  /**
   * The date in which after the user account will no longer have this role.
   * @type {Date}
   * @memberof UserRoleAssignment
   */
  validTo: Date
}

export function UserRoleAssignmentFromJSON(json: any): UserRoleAssignment {
  return UserRoleAssignmentFromJSONTyped(json, false)
}

export function UserRoleAssignmentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserRoleAssignment {
  if (json === undefined || json === null) {
    return json
  }
  return {
    actorKey: json['actorKey'],
    roleKey: json['roleKey'],
    name: json['name'],
    region: json['region'],
    regionKey: json['regionKey'],
    supervisor: !exists(json, 'supervisor') ? undefined : json['supervisor'],
    supervisorRoleKey: !exists(json, 'supervisorRoleKey')
      ? undefined
      : json['supervisorRoleKey'],
    supervisorActorKey: !exists(json, 'supervisorActorKey')
      ? undefined
      : json['supervisorActorKey'],
    supervisorUserKey: !exists(json, 'supervisorUserKey')
      ? undefined
      : json['supervisorUserKey'],
    validFrom: new Date(json['validFrom']),
    validTo: new Date(json['validTo']),
  }
}

export function UserRoleAssignmentToJSON(
  value?: UserRoleAssignment | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    actorKey: value.actorKey,
    roleKey: value.roleKey,
    name: value.name,
    region: value.region,
    regionKey: value.regionKey,
    supervisor: value.supervisor,
    supervisorRoleKey: value.supervisorRoleKey,
    supervisorActorKey: value.supervisorActorKey,
    supervisorUserKey: value.supervisorUserKey,
    validFrom: value.validFrom.toISOString(),
    validTo: value.validTo.toISOString(),
  }
}
