import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Region,
  Role,
  UserAccountListing,
  UserListingRoleAssignment,
} from '../../swagger'
import { UserRoleAssignment } from '../Admin/Roles/RolesTable'
import ActionButtons from '../Buttons/ActionButtons'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import BasicModal from './BasicModal'
import {
  Box,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { Search } from '@mui/icons-material'
import { TextButtonVariant } from '../Buttons/TextButton'
import { toFormattedDateString } from '../../utils/toFormattedDateString'
import { dateToDashString } from '../../utils/dateUtility'
import Autocomplete from '@mui/material/Autocomplete'
import { LoadingContext } from '../Context/LoadingContext'
import DateField, { DateFieldVariant } from '../Elements/DateField'
import fullName from '../../utils/fullName'
import useLoadingContext from '../../hooks/useLoadingContext'
import { reinterpretYearMonthDayAsLocalTime } from '../../utils/reinterpretYearMonthDayAsLocalTime'
import { FieldValidity } from '../Interfaces/FieldValidity'
import useValidationMessages from '../../hooks/useValidationMessages'
import { useAuth } from '../Routes/AuthProvider'
import { DEFAULT_SELECTED_REGION } from '../../utils/constants'

export interface AssignRoleArgs {
  roleKey: number
  supervisorActorKey?: number
  supervisorName: string
  roleName: string
  region: string
  regionKey: number
  validFrom: Date
  validTo?: Date
}

export interface EditRoleArgs extends AssignRoleArgs {
  actorKey: number
}

interface RoleModalProps {
  isOpen: boolean
  onClose: () => void
  initialRole?: UserRoleAssignment
  roleOptions?: Role[]
  userAccounts: UserAccountListing[]
  handleEditRole: (args: EditRoleArgs) => Promise<void>
  handleAssignRole: (args: AssignRoleArgs) => Promise<void>
  primaryButtonLoadingId: string
  primaryButtonCallback: () => void
  regions: Region[]
}

const today = new Date()
const sixMonthsFromToday = new Date()
sixMonthsFromToday.setMonth(today.getMonth() + 6)

const emptyRole: UserRoleAssignment = {
  actorKey: -1,
  roleKey: -1,
  region: '',
  regionKey: -1,
  name: '',
  supervisor: undefined,
  validFrom: today,
  validTo: sixMonthsFromToday,
}

enum RoleModalVariant {
  AssignRole = 'assignRole',
  EditRole = 'editRole',
  ExpireRole = 'expireRole',
}

enum RoleModelValidationMessageTypes {
  Default = 'default',
  StartDate = 'startDate',
  EndDate = 'endDate',
  BeforeMax = 'beforeMax',
  AfterMin = 'afterMin',
  StartDateGreaterThanEndDate = 'startDateGreaterThanEndDate',
}

export const RoleModal: React.FC<RoleModalProps> = ({
  isOpen,
  onClose,
  initialRole,
  roleOptions = [],
  userAccounts,
  handleEditRole,
  handleAssignRole,
  primaryButtonLoadingId,
  primaryButtonCallback,
  regions,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { permissionAbility } = useAuth()

  const isInEditMode = !!initialRole
  const [role, setRole] = useState(emptyRole)

  const [startDate, setStartDate] = useState(dateToDashString(today))
  const [endDate, setEndDate] = useState('')

  /** The most we really want to set for the future is right before Y10K. The world might end like Y2K... */
  const minimumRoleDate = useRef(
    new Date(Date.UTC(new Date().getFullYear() - 2, 0, 1))
  )
  const maximumRoleDate = useRef(new Date(Date.UTC(9999, 11, 31, 23, 59, 59)))

  const [selectedSupervisor, setSelectedSupervisor] =
    useState<UserAccountListing>()

  const [selectedSupervisorRole, setSelectedSupervisorRole] =
    useState<UserListingRoleAssignment>()

  const [isRoleValid, setIsRoleValid] = useState(true)
  const [isRegionValid, setIsRegionValid] = useState(true)
  const [isSupervisorRoleValid, setIsSupervisorRoleValid] = useState(true)
  const [isStartDateGreaterThanEndDate, setIsStartDateGreaterThanEndDate] =
    useState(true)

  const [currentRoleModalMode, setCurrentRoleModalMode] =
    useState<RoleModalVariant>()

  const { removeLoadingIds } = React.useContext(LoadingContext)

  const hasViewAnyRegion = permissionAbility.can('view', 'Region')

  // If the user does not have all regions in view, the field should not be displayed
  const regionsAreNotEmpty = regions.length > 1

  //Modal receives all available accounts to be used in the supervisor autocomplete dropdown
  //Only accounts with roles assigned should show in the supervisor autocomplete dropdown
  const supervisorAccounts = userAccounts.filter(
    (account) => account.roles.length > 0
  )

  useEffect(() => {
    if (!!regions && regions.length > 0) {
      setRole((prevRole) => ({
        ...prevRole,
        region: DEFAULT_SELECTED_REGION,
        regionKey:
          regions.find((region) => region.name === DEFAULT_SELECTED_REGION)
            ?.regionsKey ?? -1,
      }))
    }
  }, [regions])

  // Initialize our local state with the passed in initialRole and sets current mode for modal
  useEffect(() => {
    if (initialRole) {
      setRole(initialRole)
      setStartDate(
        dateToDashString(
          reinterpretYearMonthDayAsLocalTime(initialRole.validFrom)
        )
      )
      setEndDate(
        initialRole.validTo
          ? dateToDashString(
              reinterpretYearMonthDayAsLocalTime(initialRole.validTo)
            )
          : ''
      )
      setCurrentRoleModalMode(RoleModalVariant.EditRole)
    } else {
      setCurrentRoleModalMode(RoleModalVariant.AssignRole)
    }
  }, [initialRole])

  // Initialize the search field with the selected supervisor, otherwise undefined
  useEffect(() => {
    //Find supervisor by firstName, lastName and role type.
    //Avoid selecting an account with the same user name but wrong role
    const foundSupervisorUserAccount = userAccounts.find(
      ({ userKey, roles }) =>
        userKey === role.supervisorUserKey &&
        roles.some(
          (supervisorRole) => supervisorRole.roleKey === role.supervisorRoleKey
        )
    )

    setSelectedSupervisor(foundSupervisorUserAccount)

    //Set the current supervisor actor role as the default selected supervisor role, to be populated when editing the role
    if (foundSupervisorUserAccount) {
      setSelectedSupervisorRole(
        foundSupervisorUserAccount.roles
          .filter(
            (supervisor) =>
              supervisor.validFrom <= today &&
              supervisor.validTo > today &&
              supervisor.actorKey === role.supervisorActorKey
          )
          .shift()
      )
    }
  }, [role, userAccounts])

  // Build the options to populate the auto-complete box
  const supervisorRoleOptions = (selectedSupervisor?.roles ?? []).filter(
    (role) => role.validFrom <= today && role.validTo > today
  )

  const [roleModalValidity, setRoleModalValidity] = useState<{
    validFrom: FieldValidity
    validTo: FieldValidity
  }>({
    validFrom: { input: true, afterMin: true, beforeMax: true },
    validTo: { input: true, afterMin: true, beforeMax: true },
  })

  const validationMessageMap = useValidationMessages([
    {
      field: RoleModelValidationMessageTypes.Default,
      message: t(
        'Roles.RoleModal.ErrorMessage',
        'Something went wrong. Please make sure you have filled out the required fields'
      ),
    },
    {
      field: RoleModelValidationMessageTypes.StartDate,
      message: t(
        'Roles.RoleModal.ValidationMessage.StartDate',
        'Please enter a valid Start Date.'
      ),
    },
    {
      field: RoleModelValidationMessageTypes.EndDate,
      message: t(
        'Roles.RoleModal.ValidationMessage.EndDate',
        'Please enter a valid End Date.'
      ),
    },
    {
      field: RoleModelValidationMessageTypes.BeforeMax,
      message: t(
        'Roles.RoleModal.ValidationMessage.BeforeMax',
        'Please enter a valid date on or before'
      ),
    },
    {
      field: RoleModelValidationMessageTypes.AfterMin,
      message: t(
        'Roles.RoleModal.ValidationMessage.AfterMin',
        'Please enter a valid date on or after'
      ),
    },
    {
      field: RoleModelValidationMessageTypes.StartDateGreaterThanEndDate,
      message: t(
        'Roles.RoleModal.ValidationMessage.StartDateGreaterThanEndDate',
        'End Date must be after Start Date.'
      ),
    },
  ])

  const roleStartDateHelperText = useMemo(() => {
    if (!roleModalValidity.validFrom.input) {
      return validationMessageMap.get(RoleModelValidationMessageTypes.StartDate)
    } else if (!roleModalValidity.validFrom.beforeMax) {
      return `${validationMessageMap.get(
        RoleModelValidationMessageTypes.BeforeMax
      )} ${dateToDashString(
        reinterpretYearMonthDayAsLocalTime(maximumRoleDate.current)
      )}`
    } else if (!roleModalValidity.validFrom.afterMin) {
      return `${validationMessageMap.get(
        RoleModelValidationMessageTypes.AfterMin
      )} ${dateToDashString(
        reinterpretYearMonthDayAsLocalTime(minimumRoleDate.current)
      )}`
    } else {
      return null
    }
  }, [
    maximumRoleDate,
    minimumRoleDate,
    roleModalValidity.validFrom.afterMin,
    roleModalValidity.validFrom.beforeMax,
    roleModalValidity.validFrom.input,
    validationMessageMap,
  ])

  const roleEndDateHelperText = useMemo(() => {
    if (!roleModalValidity.validTo.input) {
      return validationMessageMap.get(RoleModelValidationMessageTypes.StartDate)
    } else if (!roleModalValidity.validTo.beforeMax) {
      return `${validationMessageMap.get(
        RoleModelValidationMessageTypes.BeforeMax
      )} ${dateToDashString(
        reinterpretYearMonthDayAsLocalTime(maximumRoleDate.current)
      )}`
    } else if (!roleModalValidity.validTo.afterMin) {
      return `${validationMessageMap.get(
        RoleModelValidationMessageTypes.AfterMin
      )} ${dateToDashString(
        reinterpretYearMonthDayAsLocalTime(minimumRoleDate.current)
      )}`
    } else if (!isStartDateGreaterThanEndDate) {
      return validationMessageMap.get(
        RoleModelValidationMessageTypes.StartDateGreaterThanEndDate
      )
    } else {
      return null
    }
  }, [
    roleModalValidity.validTo.input,
    roleModalValidity.validTo.beforeMax,
    roleModalValidity.validTo.afterMin,
    isStartDateGreaterThanEndDate,
    validationMessageMap,
    maximumRoleDate,
    minimumRoleDate,
  ])

  const checkFieldValidity = () => {
    setIsRoleValid(!!role.name)
    setIsRegionValid(!!role.region)
    if (!!selectedSupervisor) {
      setIsSupervisorRoleValid(!!selectedSupervisorRole)
    }
  }

  const areAllFieldsValid = () => {
    return (
      !!selectedSupervisor &&
      role.name !== '' &&
      (startDate < endDate || (!!startDate && endDate === '')) &&
      (!!selectedSupervisor ? !!selectedSupervisorRole : true)
    )
  }

  const handleFormSubmit = async (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handlePrimaryButtonClick = async () => {
    checkFieldValidity()
    // Check for errors in form
    if (areAllFieldsValid()) {
      // Set start date's time to midnight
      const startDateWithTime = new Date(toFormattedDateString(startDate))
      startDateWithTime.setHours(0, 0, 0, 0)

      // Set end date's time to 11:59pm
      const endDateWithTime = new Date(endDate)
      endDateWithTime.setHours(23, 59, 59, 999)

      const today = new Date()
      today.setHours(0, 0, 0, 0)

      switch (currentRoleModalMode) {
        case RoleModalVariant.EditRole:
          if (today > endDateWithTime) {
            // Removing LoadingIds to allow the confirmation modal to display to the admin
            removeLoadingIds([primaryButtonLoadingId])
            setCurrentRoleModalMode(RoleModalVariant.ExpireRole)
          } else {
            const supervisorName =
              !!selectedSupervisor?.firstName && !!selectedSupervisor?.lastName
                ? fullName({
                    firstName: selectedSupervisor?.firstName,
                    lastName: selectedSupervisor?.lastName,
                  })
                : initialRole?.supervisor ?? ''

            await handleEditRole({
              actorKey: role.actorKey,
              roleKey: role.roleKey,
              region: role.region,
              regionKey: role.regionKey,
              supervisorActorKey: selectedSupervisorRole?.actorKey,
              supervisorName,
              roleName: role?.name ?? initialRole?.name ?? '',
              validFrom: startDateWithTime,
              validTo: endDate !== '' ? endDateWithTime : undefined,
            })
          }
          break
        case RoleModalVariant.AssignRole:
          if (today > endDateWithTime) {
            setCurrentRoleModalMode(RoleModalVariant.ExpireRole)
          } else {
            const supervisorName =
              !!selectedSupervisor?.firstName && !!selectedSupervisor?.lastName
                ? fullName({
                    firstName: selectedSupervisor?.firstName,
                    lastName: selectedSupervisor?.lastName,
                  })
                : ''

            await handleAssignRole({
              roleKey: role.roleKey,
              supervisorActorKey: selectedSupervisorRole?.actorKey,
              roleName: role?.name ?? '',
              region: role.region,
              regionKey: role.regionKey,
              supervisorName,
              validFrom: startDateWithTime,
              validTo: endDate !== '' ? endDateWithTime : undefined,
            })
          }
          break
        case RoleModalVariant.ExpireRole:
          if (isInEditMode) {
            const supervisorName =
              !!selectedSupervisor?.firstName && !!selectedSupervisor?.lastName
                ? fullName({
                    firstName: selectedSupervisor?.firstName,
                    lastName: selectedSupervisor?.lastName,
                  })
                : initialRole?.supervisor ?? ''

            await handleEditRole({
              actorKey: role.actorKey,
              roleKey: role.roleKey,
              region: role.region,
              regionKey: role.regionKey,
              supervisorActorKey: selectedSupervisorRole?.actorKey,
              supervisorName,
              roleName: role?.name ?? initialRole?.name ?? '',
              validFrom: startDateWithTime,
              validTo: endDate !== '' ? endDateWithTime : undefined,
            })
          } else {
            const supervisorName =
              !!selectedSupervisor?.firstName && !!selectedSupervisor?.lastName
                ? fullName({
                    firstName: selectedSupervisor?.firstName,
                    lastName: selectedSupervisor?.lastName,
                  })
                : ''

            await handleAssignRole({
              roleKey: role.roleKey,
              supervisorActorKey: selectedSupervisorRole?.actorKey,
              roleName: role?.name,
              region: role.region,
              regionKey: role.regionKey,
              supervisorName,
              validFrom: startDateWithTime,
              validTo: endDate !== '' ? endDateWithTime : undefined,
            })
          }
          break
      }
    }
  }

  useLoadingContext({
    asyncFunction: handlePrimaryButtonClick,
    loadingId: primaryButtonLoadingId,
    callback: primaryButtonCallback,
  })

  const resetModalState = () => {
    setRole(initialRole ?? emptyRole)
    setIsRoleValid(true)
    setIsStartDateGreaterThanEndDate(true)
    setIsSupervisorRoleValid(true)
    setSelectedSupervisorRole(undefined)
  }

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentSelectedRole = roleOptions?.find(
      (it) => it.name === event.target.value
    )

    setRole((prevState) => ({
      ...prevState,
      name: currentSelectedRole?.name ?? '',
      roleKey: currentSelectedRole?.roleKey ?? -1,
    }))

    /**
     * Reset the selected supervisor to undefined when the role change
     * if the supervisor is not reset the assign button show as enable,
     * since it count supervisor field as filled
     **/
    setSelectedSupervisorRole(undefined)
  }

  const handleRegionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentSelectedRegion = regions?.find(
      (it) => it.name === event.target.value
    )

    setRole((prevState) => ({
      ...prevState,
      region: currentSelectedRegion?.name ?? '',
      regionKey: currentSelectedRegion?.regionsKey ?? -1,
    }))
  }

  const handleDateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value?: string,
    validity?: FieldValidity
  ) => {
    if (event.target.name === 'validFrom') {
      setStartDate(event.target.value ?? value)
    } else {
      setEndDate(event.target.value ?? value)
      setIsStartDateGreaterThanEndDate(startDate < endDate)
    }

    setRoleModalValidity((prevRoleModalValidity) => {
      return {
        ...prevRoleModalValidity,
        [event.target.name]: validity ?? {
          input: true,
          afterMin: true,
          beforeMax: true,
        },
      }
    })
  }

  const DialogTitle = () => {
    switch (currentRoleModalMode) {
      case RoleModalVariant.AssignRole:
      default:
        return t('RoleModal.Title.AssignRole', 'Assign Role')
      case RoleModalVariant.EditRole:
        return t('RoleModal.Title.EditRole', 'Edit Role')
      case RoleModalVariant.ExpireRole:
        return t(
          'RoleModal.Title.ExpireRoleConfirmation',
          'You are about to remove the role {{roleName}} for this user.',
          { roleName: role.name }
        )
    }
  }

  const DialogContent = () => {
    if (currentRoleModalMode === RoleModalVariant.ExpireRole) {
      return (
        <Box>
          <Typography variant="body1" component="p" align="center">
            {t(
              'RoleModal.Body.ExpireRoleConfirmation',
              'This action is not reversible and by confirming “Yes” an email will be sent informing the user, team leaders, HR and Accounting that the role has been ended.'
            )}
          </Typography>
        </Box>
      )
    } else {
      return (
        <Box>
          <TextField
            id="roleField"
            name="name"
            variant="filled"
            label={t('RoleModal.Label.Role', 'Role')}
            value={
              !!roleOptions && roleOptions.length > 0 ? role.name ?? '' : ''
            }
            onChange={handleRoleChange}
            fullWidth
            disabled={isInEditMode}
            select
            margin="normal"
            error={!isRoleValid}
            helperText={
              !isRoleValid && t('RoleModal.Error.Role', 'Please select a role')
            }
          >
            {!!roleOptions && roleOptions.length > 0 ? (
              roleOptions.map((option) => (
                <MenuItem
                  key={`${option.roleKey}-${option.name}`}
                  value={option.name}
                >
                  {option.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>
                {t('RoleModal.MenuItem.NoOptions', 'No Options')}
              </MenuItem>
            )}
          </TextField>
          {regionsAreNotEmpty && (
            <TextField
              id="regionsField"
              name="region"
              variant="filled"
              label={t('RoleModal.Label.Regions', 'Region')}
              value={
                !!regions && regions.length > 0
                  ? role.region || DEFAULT_SELECTED_REGION
                  : ''
              }
              onChange={handleRegionChange}
              fullWidth
              disabled={!hasViewAnyRegion}
              select
              margin="normal"
              error={!isRegionValid}
              helperText={
                !isRegionValid &&
                t('RoleModal.InvalidRegion', 'Please select a region')
              }
            >
              {!!regions && regions.length > 0 ? (
                regions.map((option) => (
                  <MenuItem
                    key={`${option.name}-${option.regionsKey}`}
                    value={option.name}
                  >
                    {option.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>
                  {t('RoleModal.MenuItem.NoOptions', 'No Options')}
                </MenuItem>
              )}
            </TextField>
          )}
          <DateField
            dateVariant={DateFieldVariant.RoleStartDate}
            name="validFrom"
            value={startDate}
            type="date"
            onChangeForParent={handleDateChange}
            margin="normal"
            maxDate={maximumRoleDate.current}
            error={
              !roleModalValidity.validFrom.input ||
              !roleModalValidity.validFrom.afterMin ||
              !roleModalValidity.validFrom.beforeMax
            }
            helperTextForParent={roleStartDateHelperText}
          />
          <DateField
            dateVariant={DateFieldVariant.RoleEndDate}
            name="validTo"
            value={endDate}
            type="date"
            onChangeForParent={handleDateChange}
            margin="normal"
            maxDate={maximumRoleDate.current}
            error={
              !isStartDateGreaterThanEndDate ||
              !roleModalValidity.validTo.input ||
              !roleModalValidity.validTo.afterMin ||
              !roleModalValidity.validTo.beforeMax
            }
            helperTextForParent={roleEndDateHelperText}
          />

          <Autocomplete
            id="supervisor"
            sx={{
              color: theme.palette.primary.light,
            }}
            popupIcon={null}
            fullWidth
            autoComplete
            clearOnEscape
            noOptionsText={t(
              'RoleModal.TeamLeaderSearch.NoResults',
              'No Team Leader found'
            )}
            ListboxProps={{
              // This style is needed so the list of elements does not overflow out of the modal
              style: { maxHeight: '150px' },
            }}
            options={supervisorAccounts}
            getOptionLabel={(option) =>
              `${option.firstName} ${option.lastName}`
            }
            //Add renderOption and provide a unique key, to avoid inaccurate search results
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.userKey}>
                {option.firstName} {option.lastName}
              </Box>
            )}
            value={selectedSupervisor ?? null}
            onChange={(_event, value) => {
              setSelectedSupervisor(value ?? undefined)

              // Clear the supervisor role when the supervisor changes
              setSelectedSupervisorRole(undefined)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label={t('RoleModal.Label.TeamLeader', 'Search Team Leader')}
                fullWidth
                margin="normal"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment
                      id="emailIcon"
                      // This style is needed b/c of a MUI bug where the start adornment is misaligned
                      style={{ marginTop: 0 }}
                      disablePointerEvents={true}
                      position="start"
                    >
                      <Search
                        sx={{
                          fill: theme.palette.textOrIcon.searchIcon,
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          <TextField
            id="supervisorRole"
            name="supervisorRole"
            sx={{
              minHeight: 74,
            }}
            variant="filled"
            label={t('RoleModal.Label.TeamLeaderRole', 'Team Leader Role')}
            value={JSON.stringify(selectedSupervisorRole) ?? ''}
            disabled={!selectedSupervisor}
            onChange={(e) => {
              setSelectedSupervisorRole(JSON.parse(e.target.value))
            }}
            select
            fullWidth
            margin="normal"
            error={!!selectedSupervisor && !isSupervisorRoleValid}
            helperText={
              !selectedSupervisor
                ? t(
                    'RoleModal.HelperText.SelectTeamLeader',
                    'Please select a team leader first.'
                  )
                : isSupervisorRoleValid
                ? t(
                    'RoleModal.HelperText.SelectTeamLeader',
                    'Please select a team leader role.'
                  )
                : undefined
            }
          >
            {supervisorRoleOptions.length > 0 ? (
              supervisorRoleOptions.map((supervisorRole) => {
                const supervisorRoleObj = JSON.stringify(supervisorRole)
                return (
                  <MenuItem
                    key={supervisorRoleObj}
                    value={supervisorRoleObj}
                    style={{
                      whiteSpace: 'normal',
                      overflow: 'visible',
                    }}
                  >
                    {supervisorRole.name} / {supervisorRole.regionName} /{' '}
                    {supervisorRole.actorKey} /{' '}
                    {supervisorRole.validFrom.getFullYear()}-
                    {supervisorRole.validTo.getFullYear()}
                  </MenuItem>
                )
              })
            ) : (
              <MenuItem disabled>
                {t('RoleModal.MenuItem.NoOptions', 'No Options')}
              </MenuItem>
            )}
          </TextField>
        </Box>
      )
    }
  }

  return (
    <BasicModal
      isOpen={isOpen}
      handleFormSubmit={handleFormSubmit}
      disableForm
      maxWidth="xs"
      dialogContent={DialogContent()}
      dialogActions={
        <ActionButtons
          primaryButtonLabel={
            isInEditMode
              ? currentRoleModalMode === 'expireRole'
                ? ContainedButtonVariant.YesRemove
                : ContainedButtonVariant.Save
              : ContainedButtonVariant.Assign
          }
          secondaryButtonLabel={TextButtonVariant.Cancel}
          secondaryClick={onClose}
          primaryButtonLoadingId={primaryButtonLoadingId}
          useBaseButton
          disablePrimaryButton={
            !((selectedSupervisorRole?.actorKey ?? 0) > 0) ||
            !((role.roleKey ?? 0) > 0)
          }
        />
      }
      dialogTitle={DialogTitle()}
      afterClose={resetModalState}
      labelledBy="role-form-title"
    />
  )
}

export default RoleModal
