/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface SingleActorMetricResult
 */
export interface SingleActorMetricResult {
  /**
   * A string of text that's associated with a certain metric value.
   * @type {string}
   * @memberof SingleActorMetricResult
   */
  metricText?: string
  /**
   *
   * @type {Array<object>}
   * @memberof SingleActorMetricResult
   */
  metricValues?: Array<object>
  /**
   * A link to somewhere else on P1 with more information regarding the metric.
   * @type {string}
   * @memberof SingleActorMetricResult
   */
  actionLink?: string
}

export function SingleActorMetricResultFromJSON(
  json: any
): SingleActorMetricResult {
  return SingleActorMetricResultFromJSONTyped(json, false)
}

export function SingleActorMetricResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SingleActorMetricResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    metricText: !exists(json, 'metricText') ? undefined : json['metricText'],
    metricValues: !exists(json, 'metricValues')
      ? undefined
      : json['metricValues'],
    actionLink: !exists(json, 'actionLink') ? undefined : json['actionLink'],
  }
}

export function SingleActorMetricResultToJSON(
  value?: SingleActorMetricResult | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    metricText: value.metricText,
    metricValues: value.metricValues,
    actionLink: value.actionLink,
  }
}
