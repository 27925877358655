/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import { ProgramType, ProgramTypeFromJSON, ProgramTypeToJSON } from './'

/**
 *
 * @export
 * @interface EnrollmentInviteProgram
 */
export interface EnrollmentInviteProgram {
  /**
   * Unique identifier for the program the invite is sent for.
   * @type {number}
   * @memberof EnrollmentInviteProgram
   */
  programKey: number
  /**
   *
   * @type {ProgramType}
   * @memberof EnrollmentInviteProgram
   */
  programType: ProgramType
  /**
   * The starting date for the program's first semester in date format: `yyyy-MM-dd`.
   * @type {Date}
   * @memberof EnrollmentInviteProgram
   */
  semesterOneStartDate?: Date
  /**
   * The number of skipped spots for a enrollment invite program.
   * @type {number}
   * @memberof EnrollmentInviteProgram
   */
  skippedSpots?: number
  /**
   * Number of offered student spots for this program on the invite
   * @type {number}
   * @memberof EnrollmentInviteProgram
   */
  offeredSpots: number
}

export function EnrollmentInviteProgramFromJSON(
  json: any
): EnrollmentInviteProgram {
  return EnrollmentInviteProgramFromJSONTyped(json, false)
}

export function EnrollmentInviteProgramFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnrollmentInviteProgram {
  if (json === undefined || json === null) {
    return json
  }
  return {
    programKey: json['programKey'],
    programType: ProgramTypeFromJSON(json['programType']),
    semesterOneStartDate: !exists(json, 'semesterOneStartDate')
      ? undefined
      : new Date(json['semesterOneStartDate']),
    skippedSpots: !exists(json, 'skippedSpots')
      ? undefined
      : json['skippedSpots'],
    offeredSpots: json['offeredSpots'],
  }
}

export function EnrollmentInviteProgramToJSON(
  value?: EnrollmentInviteProgram | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    programKey: value.programKey,
    programType: ProgramTypeToJSON(value.programType),
    semesterOneStartDate:
      value.semesterOneStartDate === undefined
        ? undefined
        : value.semesterOneStartDate.toISOString().substr(0, 10),
    skippedSpots: value.skippedSpots,
    offeredSpots: value.offeredSpots,
  }
}
