/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface AuthorizeDotNetEventWebhookRequestBodyPayload
 */
export interface AuthorizeDotNetEventWebhookRequestBodyPayload {
  /**
   * Indicates the status of the transaction. I.E. APPROVED, DECLINED, ERROR, or HELD
   * @type {number}
   * @memberof AuthorizeDotNetEventWebhookRequestBodyPayload
   */
  responseCode: number
  /**
   * Unique merchant ID. Corresponds to the refId field in the Authorize.net API and enables you to match webhook notifications with API requests
   * @type {string}
   * @memberof AuthorizeDotNetEventWebhookRequestBodyPayload
   */
  merchantReferenceId?: string
  /**
   * A number provided by the merchant service provider for the transaction
   * @type {string}
   * @memberof AuthorizeDotNetEventWebhookRequestBodyPayload
   */
  authCode?: string
  /**
   * The address verification response indicating if the billing address was verifiable
   * @type {string}
   * @memberof AuthorizeDotNetEventWebhookRequestBodyPayload
   */
  avsResponse?: string
  /**
   * amount authorized for the transaction
   * @type {number}
   * @memberof AuthorizeDotNetEventWebhookRequestBodyPayload
   */
  authAmount?: number
  /**
   * Type of the action which triggered the notification
   * @type {string}
   * @memberof AuthorizeDotNetEventWebhookRequestBodyPayload
   */
  entityName?: string
  /**
   * The transaction id for an attempted or completed payment
   * @type {string}
   * @memberof AuthorizeDotNetEventWebhookRequestBodyPayload
   */
  id: string
}

export function AuthorizeDotNetEventWebhookRequestBodyPayloadFromJSON(
  json: any
): AuthorizeDotNetEventWebhookRequestBodyPayload {
  return AuthorizeDotNetEventWebhookRequestBodyPayloadFromJSONTyped(json, false)
}

export function AuthorizeDotNetEventWebhookRequestBodyPayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AuthorizeDotNetEventWebhookRequestBodyPayload {
  if (json === undefined || json === null) {
    return json
  }
  return {
    responseCode: json['responseCode'],
    merchantReferenceId: !exists(json, 'merchantReferenceId')
      ? undefined
      : json['merchantReferenceId'],
    authCode: !exists(json, 'authCode') ? undefined : json['authCode'],
    avsResponse: !exists(json, 'avsResponse') ? undefined : json['avsResponse'],
    authAmount: !exists(json, 'authAmount') ? undefined : json['authAmount'],
    entityName: !exists(json, 'entityName') ? undefined : json['entityName'],
    id: json['id'],
  }
}

export function AuthorizeDotNetEventWebhookRequestBodyPayloadToJSON(
  value?: AuthorizeDotNetEventWebhookRequestBodyPayload | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    responseCode: value.responseCode,
    merchantReferenceId: value.merchantReferenceId,
    authCode: value.authCode,
    avsResponse: value.avsResponse,
    authAmount: value.authAmount,
    entityName: value.entityName,
    id: value.id,
  }
}
