/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  Address,
  AddressFromJSON,
  AddressToJSON,
  CashAmount,
  CashAmountFromJSON,
  CashAmountToJSON,
  Enrollment1,
  Enrollment1FromJSON,
  Enrollment1ToJSON,
} from './'

/**
 *
 * @export
 * @interface TuitionInvoice
 */
export interface TuitionInvoice {
  /**
   * Unique identifier of the parent user to generate a tuition invoice for the entire family.
   * @type {number}
   * @memberof TuitionInvoice
   */
  parentUserKey: number
  /**
   * The parent's name.
   * @type {string}
   * @memberof TuitionInvoice
   */
  parentName: string
  /**
   * The parent's Email address.
   * @type {string}
   * @memberof TuitionInvoice
   */
  parentEmail: string
  /**
   * The parent's phone number.
   * @type {string}
   * @memberof TuitionInvoice
   */
  parentPhoneNumber?: string
  /**
   *
   * @type {Address}
   * @memberof TuitionInvoice
   */
  parentAddress?: Address
  /**
   * Identify whether the community for this invoice follows the sublicensed model.
   * @type {boolean}
   * @memberof TuitionInvoice
   */
  isSubLicensedCommunity?: boolean
  /**
   * Indicates whether a tutor has been assigned to at least one enrollment in the community.
   * @type {boolean}
   * @memberof TuitionInvoice
   */
  hasAssignedTutor?: boolean
  /**
   * Unique identifier for the generated invoice to be sent to the family.
   * @type {number}
   * @memberof TuitionInvoice
   */
  invoiceKey?: number
  /**
   * Date of when the invoice was generated.
   * @type {Date}
   * @memberof TuitionInvoice
   */
  invoiceDate?: Date
  /**
   * Comments provided by the director or tutor on payment instructions or any other relevant info.
   * @type {string}
   * @memberof TuitionInvoice
   */
  comment?: string
  /**
   *
   * @type {string}
   * @memberof TuitionInvoice
   */
  currencyCode: string
  /**
   *
   * @type {CashAmount}
   * @memberof TuitionInvoice
   */
  total: CashAmount
  /**
   *
   * @type {Array<Enrollment1>}
   * @memberof TuitionInvoice
   */
  enrollments: Array<Enrollment1>
}

export function TuitionInvoiceFromJSON(json: any): TuitionInvoice {
  return TuitionInvoiceFromJSONTyped(json, false)
}

export function TuitionInvoiceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TuitionInvoice {
  if (json === undefined || json === null) {
    return json
  }
  return {
    parentUserKey: json['parentUserKey'],
    parentName: json['parentName'],
    parentEmail: json['parentEmail'],
    parentPhoneNumber: !exists(json, 'parentPhoneNumber')
      ? undefined
      : json['parentPhoneNumber'],
    parentAddress: !exists(json, 'parentAddress')
      ? undefined
      : AddressFromJSON(json['parentAddress']),
    isSubLicensedCommunity: !exists(json, 'isSubLicensedCommunity')
      ? undefined
      : json['isSubLicensedCommunity'],
    hasAssignedTutor: !exists(json, 'hasAssignedTutor')
      ? undefined
      : json['hasAssignedTutor'],
    invoiceKey: !exists(json, 'invoiceKey') ? undefined : json['invoiceKey'],
    invoiceDate: !exists(json, 'invoiceDate')
      ? undefined
      : new Date(json['invoiceDate']),
    comment: !exists(json, 'comment') ? undefined : json['comment'],
    currencyCode: json['currencyCode'],
    total: CashAmountFromJSON(json['total']),
    enrollments: (json['enrollments'] as Array<any>).map(Enrollment1FromJSON),
  }
}

export function TuitionInvoiceToJSON(value?: TuitionInvoice | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    parentUserKey: value.parentUserKey,
    parentName: value.parentName,
    parentEmail: value.parentEmail,
    parentPhoneNumber: value.parentPhoneNumber,
    parentAddress: AddressToJSON(value.parentAddress),
    isSubLicensedCommunity: value.isSubLicensedCommunity,
    hasAssignedTutor: value.hasAssignedTutor,
    invoiceKey: value.invoiceKey,
    invoiceDate:
      value.invoiceDate === undefined
        ? undefined
        : value.invoiceDate.toISOString().substr(0, 10),
    comment: value.comment,
    currencyCode: value.currencyCode,
    total: CashAmountToJSON(value.total),
    enrollments: (value.enrollments as Array<any>).map(Enrollment1ToJSON),
  }
}
