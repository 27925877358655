/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  PaginationResponse,
  PaginationResponseFromJSON,
  PaginationResponseToJSON,
  UserAccountListing,
  UserAccountListingFromJSON,
  UserAccountListingToJSON,
} from './'

/**
 *
 * @export
 * @interface UserTeam
 */
export interface UserTeam {
  /**
   * Provides all members a user supports. Each of them includes `user` and `role` information.
   * @type {Array<UserAccountListing>}
   * @memberof UserTeam
   */
  directReports: Array<UserAccountListing>
  /**
   * Provides all Actors who supports the user.
   * @type {Array<UserAccountListing>}
   * @memberof UserTeam
   */
  supervisors: Array<UserAccountListing>
  /**
   *
   * @type {PaginationResponse}
   * @memberof UserTeam
   */
  pagination?: PaginationResponse
}

export function UserTeamFromJSON(json: any): UserTeam {
  return UserTeamFromJSONTyped(json, false)
}

export function UserTeamFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserTeam {
  if (json === undefined || json === null) {
    return json
  }
  return {
    directReports: (json['directReports'] as Array<any>).map(
      UserAccountListingFromJSON
    ),
    supervisors: (json['supervisors'] as Array<any>).map(
      UserAccountListingFromJSON
    ),
    pagination: !exists(json, 'pagination')
      ? undefined
      : PaginationResponseFromJSON(json['pagination']),
  }
}

export function UserTeamToJSON(value?: UserTeam | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    directReports: (value.directReports as Array<any>).map(
      UserAccountListingToJSON
    ),
    supervisors: (value.supervisors as Array<any>).map(
      UserAccountListingToJSON
    ),
    pagination: PaginationResponseToJSON(value.pagination),
  }
}
