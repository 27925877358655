/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  AvailableProgramTypes,
  AvailableProgramTypesFromJSON,
  BusinessContentOptions,
  BusinessContentOptionsFromJSON,
  ContentAsset,
  ContentAssetFromJSON,
  FavoriteParam,
  FavoriteParamToJSON,
  FetchDownloadUrl,
  FetchDownloadUrlFromJSON,
  FetchLearningCenterContentResponse,
  FetchLearningCenterContentResponseFromJSON,
  LearningCenterOptions,
  LearningCenterOptionsFromJSON,
  LearningPath,
  LearningPathFromJSON,
  NdaAcknowledgementParam,
  NdaAcknowledgementParamToJSON,
  RegionContentTags,
  RegionContentTagsFromJSON,
  StarRatingBody,
  StarRatingBodyToJSON,
} from '../models'
import * as runtime from '../runtime'

export interface FavoritePathRequest extends runtime.BaseRequestParameters {
  body?: FavoriteParam
}

export interface FetchBusinessContentRequest
  extends runtime.BaseRequestParameters {
  search?: string
  category?: string
  role?: string
  filetype?: string
  programType?: string
  showOnlyFavorites?: boolean
}

export interface FetchBusinessLearningPathRequest
  extends runtime.BaseRequestParameters {
  assetKey: number
}

export interface FetchContentDetailRequest
  extends runtime.BaseRequestParameters {
  assetKey: number
  academicYear?: number
}

export interface FetchContentTagOptionsRequest
  extends runtime.BaseRequestParameters {
  regionKey: number
}

export interface FetchDownloadUrlRequest extends runtime.BaseRequestParameters {
  assetKey: number
}

export interface FetchLearningCenterAvailableProgramTypesRequest
  extends runtime.BaseRequestParameters {
  year?: number
}

export interface FetchLearningCenterContentRequest
  extends runtime.BaseRequestParameters {
  page?: number
  pageSize?: number
  orderBy?: Array<string>
  welcomeCenterContent?: boolean
  programType?: FetchLearningCenterContentProgramTypeEnum
  filetype?: string
  category?: string
  onlyIncludeFavorites?: boolean
  searchQuery?: string
  academicYear?: number
  week?: number
}

export interface FetchLearningCenterLearningPathRequest
  extends runtime.BaseRequestParameters {
  assetKey: number
}

export interface FetchLearningCenterOptionsRequest
  extends runtime.BaseRequestParameters {
  programType: FetchLearningCenterOptionsProgramTypeEnum
}

export interface NdaAcknowledgementPathRequest
  extends runtime.BaseRequestParameters {
  body?: NdaAcknowledgementParam
}

export interface StarRatingRequest extends runtime.BaseRequestParameters {
  body?: StarRatingBody
}

/**
 *
 */
export class ContentApi extends runtime.BaseAPI {
  /**
   * Create or Update the the favorites flag for a asset by a user.
   * Update a favorite flag on an asset
   */
  async favoritePathRaw(
    requestParameters: FavoritePathRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/content/favorite`,
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: FavoriteParamToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Create or Update the the favorites flag for a asset by a user.
   * Update a favorite flag on an asset
   */
  async favoritePath(requestParameters: FavoritePathRequest): Promise<void> {
    await this.favoritePathRaw(requestParameters)
  }

  /**
   * Find business content, filter by: role, category, or filetype.
   */
  async fetchBusinessContentRaw(
    requestParameters: FetchBusinessContentRequest
  ): Promise<runtime.ApiResponse<Array<ContentAsset>>> {
    const queryParameters: any = {}

    if (requestParameters.search !== undefined) {
      queryParameters['search'] = requestParameters.search
    }

    if (requestParameters.category !== undefined) {
      queryParameters['category'] = requestParameters.category
    }

    if (requestParameters.role !== undefined) {
      queryParameters['role'] = requestParameters.role
    }

    if (requestParameters.filetype !== undefined) {
      queryParameters['filetype'] = requestParameters.filetype
    }

    if (requestParameters.programType !== undefined) {
      queryParameters['programType'] = requestParameters.programType
    }

    if (requestParameters.showOnlyFavorites !== undefined) {
      queryParameters['showOnlyFavorites'] = requestParameters.showOnlyFavorites
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/content/business`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(ContentAssetFromJSON)
    )
  }

  /**
   * Find business content, filter by: role, category, or filetype.
   */
  async fetchBusinessContent(
    requestParameters: FetchBusinessContentRequest
  ): Promise<Array<ContentAsset>> {
    const response = await this.fetchBusinessContentRaw(requestParameters)
    return await response.value()
  }

  /**
   * Provides a list of all roles, categories, and filetypes for business content.
   * List of all roles, categories, and filetypes for business content.
   */
  async fetchBusinessContentOptionsRaw(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<runtime.ApiResponse<BusinessContentOptions>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/content/business/options`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BusinessContentOptionsFromJSON(jsonValue)
    )
  }

  /**
   * Provides a list of all roles, categories, and filetypes for business content.
   * List of all roles, categories, and filetypes for business content.
   */
  async fetchBusinessContentOptions(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<BusinessContentOptions> {
    const response = await this.fetchBusinessContentOptionsRaw(
      requestParameters
    )
    return await response.value()
  }

  /**
   * Fetch all the assets within a learning path by providing an assetKey of any asset within the learning path.
   */
  async fetchBusinessLearningPathRaw(
    requestParameters: FetchBusinessLearningPathRequest
  ): Promise<runtime.ApiResponse<LearningPath>> {
    if (
      requestParameters.assetKey === null ||
      requestParameters.assetKey === undefined
    ) {
      throw new runtime.RequiredError(
        'assetKey',
        'Required parameter requestParameters.assetKey was null or undefined when calling fetchBusinessLearningPath.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/content/business/learningPath/{assetKey}`.replace(
        `{${'assetKey'}}`,
        encodeURIComponent(String(requestParameters.assetKey))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LearningPathFromJSON(jsonValue)
    )
  }

  /**
   * Fetch all the assets within a learning path by providing an assetKey of any asset within the learning path.
   */
  async fetchBusinessLearningPath(
    requestParameters: FetchBusinessLearningPathRequest
  ): Promise<LearningPath> {
    const response = await this.fetchBusinessLearningPathRaw(requestParameters)
    return await response.value()
  }

  /**
   * Get details for a specific content asset with the provided assetKey.
   */
  async fetchContentDetailRaw(
    requestParameters: FetchContentDetailRequest
  ): Promise<runtime.ApiResponse<ContentAsset>> {
    if (
      requestParameters.assetKey === null ||
      requestParameters.assetKey === undefined
    ) {
      throw new runtime.RequiredError(
        'assetKey',
        'Required parameter requestParameters.assetKey was null or undefined when calling fetchContentDetail.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.academicYear !== undefined) {
      queryParameters['academicYear'] = requestParameters.academicYear
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/content/{assetKey}`.replace(
        `{${'assetKey'}}`,
        encodeURIComponent(String(requestParameters.assetKey))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ContentAssetFromJSON(jsonValue)
    )
  }

  /**
   * Get details for a specific content asset with the provided assetKey.
   */
  async fetchContentDetail(
    requestParameters: FetchContentDetailRequest
  ): Promise<ContentAsset> {
    const response = await this.fetchContentDetailRaw(requestParameters)
    return await response.value()
  }

  /**
   * Get content tag values for the current region.
   */
  async fetchContentTagOptionsRaw(
    requestParameters: FetchContentTagOptionsRequest
  ): Promise<runtime.ApiResponse<RegionContentTags>> {
    if (
      requestParameters.regionKey === null ||
      requestParameters.regionKey === undefined
    ) {
      throw new runtime.RequiredError(
        'regionKey',
        'Required parameter requestParameters.regionKey was null or undefined when calling fetchContentTagOptions.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/regions/{regionKey}/contentTags`.replace(
        `{${'regionKey'}}`,
        encodeURIComponent(String(requestParameters.regionKey))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RegionContentTagsFromJSON(jsonValue)
    )
  }

  /**
   * Get content tag values for the current region.
   */
  async fetchContentTagOptions(
    requestParameters: FetchContentTagOptionsRequest
  ): Promise<RegionContentTags> {
    const response = await this.fetchContentTagOptionsRaw(requestParameters)
    return await response.value()
  }

  /**
   * Fetch the download url for a content asset
   */
  async fetchDownloadUrlRaw(
    requestParameters: FetchDownloadUrlRequest
  ): Promise<runtime.ApiResponse<FetchDownloadUrl>> {
    if (
      requestParameters.assetKey === null ||
      requestParameters.assetKey === undefined
    ) {
      throw new runtime.RequiredError(
        'assetKey',
        'Required parameter requestParameters.assetKey was null or undefined when calling fetchDownloadUrl.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/content/{assetKey}/downloadUrl`.replace(
        `{${'assetKey'}}`,
        encodeURIComponent(String(requestParameters.assetKey))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FetchDownloadUrlFromJSON(jsonValue)
    )
  }

  /**
   * Fetch the download url for a content asset
   */
  async fetchDownloadUrl(
    requestParameters: FetchDownloadUrlRequest
  ): Promise<FetchDownloadUrl> {
    const response = await this.fetchDownloadUrlRaw(requestParameters)
    return await response.value()
  }

  /**
   * Provides a list of all program types the user can access for learning center content.
   * List of all program types available to the user for learning center content.
   */
  async fetchLearningCenterAvailableProgramTypesRaw(
    requestParameters: FetchLearningCenterAvailableProgramTypesRequest
  ): Promise<runtime.ApiResponse<AvailableProgramTypes>> {
    const queryParameters: any = {}

    if (requestParameters.year !== undefined) {
      queryParameters['year'] = requestParameters.year
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/content/learningCenter/programTypes`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AvailableProgramTypesFromJSON(jsonValue)
    )
  }

  /**
   * Provides a list of all program types the user can access for learning center content.
   * List of all program types available to the user for learning center content.
   */
  async fetchLearningCenterAvailableProgramTypes(
    requestParameters: FetchLearningCenterAvailableProgramTypesRequest
  ): Promise<AvailableProgramTypes> {
    const response = await this.fetchLearningCenterAvailableProgramTypesRaw(
      requestParameters
    )
    return await response.value()
  }

  /**
   * Fetch assets that the calling parent/actor is allowed to view. When the request has a header cc-api-client :mobile only assets with type video or Audio will be fetched
   */
  async fetchLearningCenterContentRaw(
    requestParameters: FetchLearningCenterContentRequest
  ): Promise<runtime.ApiResponse<FetchLearningCenterContentResponse>> {
    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters['pageSize'] = requestParameters.pageSize
    }

    if (requestParameters.orderBy) {
      queryParameters['orderBy'] = requestParameters.orderBy.join(
        runtime.COLLECTION_FORMATS['csv']
      )
    }

    if (requestParameters.welcomeCenterContent !== undefined) {
      queryParameters['welcomeCenterContent'] =
        requestParameters.welcomeCenterContent
    }

    if (requestParameters.programType !== undefined) {
      queryParameters['programType'] = requestParameters.programType
    }

    if (requestParameters.filetype !== undefined) {
      queryParameters['filetype'] = requestParameters.filetype
    }

    if (requestParameters.category !== undefined) {
      queryParameters['category'] = requestParameters.category
    }

    if (requestParameters.onlyIncludeFavorites !== undefined) {
      queryParameters['onlyIncludeFavorites'] =
        requestParameters.onlyIncludeFavorites
    }

    if (requestParameters.searchQuery !== undefined) {
      queryParameters['searchQuery'] = requestParameters.searchQuery
    }

    if (requestParameters.academicYear !== undefined) {
      queryParameters['academicYear'] = requestParameters.academicYear
    }

    if (requestParameters.week !== undefined) {
      queryParameters['week'] = requestParameters.week
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/content/learningCenterContent`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FetchLearningCenterContentResponseFromJSON(jsonValue)
    )
  }

  /**
   * Fetch assets that the calling parent/actor is allowed to view. When the request has a header cc-api-client :mobile only assets with type video or Audio will be fetched
   */
  async fetchLearningCenterContent(
    requestParameters: FetchLearningCenterContentRequest
  ): Promise<FetchLearningCenterContentResponse> {
    const response = await this.fetchLearningCenterContentRaw(requestParameters)
    return await response.value()
  }

  /**
   * Fetch all the assets within a learning path by providing an assetKey of any asset within the learning path.
   */
  async fetchLearningCenterLearningPathRaw(
    requestParameters: FetchLearningCenterLearningPathRequest
  ): Promise<runtime.ApiResponse<LearningPath>> {
    if (
      requestParameters.assetKey === null ||
      requestParameters.assetKey === undefined
    ) {
      throw new runtime.RequiredError(
        'assetKey',
        'Required parameter requestParameters.assetKey was null or undefined when calling fetchLearningCenterLearningPath.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/content/learningCenter/learningPath/{assetKey}`.replace(
        `{${'assetKey'}}`,
        encodeURIComponent(String(requestParameters.assetKey))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LearningPathFromJSON(jsonValue)
    )
  }

  /**
   * Fetch all the assets within a learning path by providing an assetKey of any asset within the learning path.
   */
  async fetchLearningCenterLearningPath(
    requestParameters: FetchLearningCenterLearningPathRequest
  ): Promise<LearningPath> {
    const response = await this.fetchLearningCenterLearningPathRaw(
      requestParameters
    )
    return await response.value()
  }

  /**
   * Provides a list of all categories and filetypes for learning center content.
   * List of all categories and filetypes for learning center content.
   */
  async fetchLearningCenterOptionsRaw(
    requestParameters: FetchLearningCenterOptionsRequest
  ): Promise<runtime.ApiResponse<LearningCenterOptions>> {
    if (
      requestParameters.programType === null ||
      requestParameters.programType === undefined
    ) {
      throw new runtime.RequiredError(
        'programType',
        'Required parameter requestParameters.programType was null or undefined when calling fetchLearningCenterOptions.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.programType !== undefined) {
      queryParameters['programType'] = requestParameters.programType
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/content/learningCenter/options`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LearningCenterOptionsFromJSON(jsonValue)
    )
  }

  /**
   * Provides a list of all categories and filetypes for learning center content.
   * List of all categories and filetypes for learning center content.
   */
  async fetchLearningCenterOptions(
    requestParameters: FetchLearningCenterOptionsRequest
  ): Promise<LearningCenterOptions> {
    const response = await this.fetchLearningCenterOptionsRaw(requestParameters)
    return await response.value()
  }

  /**
   * Updates the ndaAcknowledged flag for a asset by a user.
   * Records the user\'s agreement of an NDA for a asset that requires it.
   */
  async ndaAcknowledgementPathRaw(
    requestParameters: NdaAcknowledgementPathRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/content/acknowledgeNDA`,
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: NdaAcknowledgementParamToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Updates the ndaAcknowledged flag for a asset by a user.
   * Records the user\'s agreement of an NDA for a asset that requires it.
   */
  async ndaAcknowledgementPath(
    requestParameters: NdaAcknowledgementPathRequest
  ): Promise<void> {
    await this.ndaAcknowledgementPathRaw(requestParameters)
  }

  /**
   * Create or Update a user\'s star rating for an asset.
   * Update a user\'s rating for an asset
   */
  async starRatingRaw(
    requestParameters: StarRatingRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/content/starRating`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: StarRatingBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Create or Update a user\'s star rating for an asset.
   * Update a user\'s rating for an asset
   */
  async starRating(requestParameters: StarRatingRequest): Promise<void> {
    await this.starRatingRaw(requestParameters)
  }
}

/**
 * @export
 * @enum {string}
 */
export enum FetchLearningCenterContentProgramTypeEnum {
  Foundations = 'Foundations',
  Scribblers = 'Scribblers',
  Essentials = 'Essentials',
  ChallengeA = 'Challenge A',
  ChallengeB = 'Challenge B',
  ChallengeI = 'Challenge I',
  ChallengeIi = 'Challenge II',
  ChallengeIii = 'Challenge III',
  ChallengeIv = 'Challenge IV',
}
/**
 * @export
 * @enum {string}
 */
export enum FetchLearningCenterOptionsProgramTypeEnum {
  Foundations = 'Foundations',
  Scribblers = 'Scribblers',
  Essentials = 'Essentials',
  ChallengeA = 'Challenge A',
  ChallengeB = 'Challenge B',
  ChallengeI = 'Challenge I',
  ChallengeIi = 'Challenge II',
  ChallengeIii = 'Challenge III',
  ChallengeIv = 'Challenge IV',
}
