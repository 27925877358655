import React, { CSSProperties, ReactElement } from 'react'
import Typography from '@mui/material/Typography'
import { Box, useTheme } from '@mui/material'

export enum HeaderVariant {
  Card = 'Card',
  AdventureCard = 'Adventure Card',
  Divider = 'Divider',
  Subheader = 'Subheader',
  SettingsHeader = 'SettingsHeader',
  UserAccountCard = 'User Account Card',
  Business = 'Business',
}

export interface HeaderProps {
  id?: string
  headerName?: string
  component?: React.ElementType
  variant?: HeaderVariant
  css?: CSSProperties
  endAdornment?: ReactElement
}

const Header: React.FC<HeaderProps> = ({
  id,
  headerName,
  component,
  variant,
  css,
  endAdornment,
}) => {
  const theme = useTheme()
  let defaultStyles = {}
  // classes.header
  let typographyVariant: 'button' | 'h5' | 'h6' = 'h6'
  switch (variant) {
    case HeaderVariant.AdventureCard:
      defaultStyles = {
        padding: theme.spacing(0, 0, 3),
        display: 'inline-flex',
      }
      break
    case HeaderVariant.Card:
      defaultStyles = {
        padding: theme.spacing(0, 0, 3),
        display: 'flex',
        alignItems: 'center',
      }
      break
    case HeaderVariant.Divider:
      defaultStyles = {
        padding: theme.spacing(0, 0, 3),
      }
      break
    case HeaderVariant.Subheader:
      defaultStyles = {
        padding: theme.spacing(1, 0),
        color: theme.palette.textOrIcon.subheader,
      }
      typographyVariant = 'button'
      break
    case HeaderVariant.SettingsHeader:
      defaultStyles = {
        padding: theme.spacing(0),
      }
      break
    case HeaderVariant.UserAccountCard:
      typographyVariant = 'h5'
      break
    case HeaderVariant.Business:
      defaultStyles = {
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(4),
      }
      typographyVariant = 'h6'
      break
    default:
      defaultStyles = {
        color: theme.palette.primary.main,
        padding: theme.spacing(4, 0, 3),
      }
      typographyVariant = 'h6'
  }

  return (
    <header
      id={id}
      style={{
        ...defaultStyles,
        ...css,
      }}
    >
      <Typography variant={typographyVariant} component={component ?? 'p'}>
        {headerName}
      </Typography>
      <Box pl={1}>{endAdornment}</Box>
    </header>
  )
}

export default Header
