/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface CommunitySpecificProgramOptionsDefaultStartDates
 */
export interface CommunitySpecificProgramOptionsDefaultStartDates {
  /**
   * The default starting date for the program's first semester in date format: `yyyy-MM-dd`.
   * @type {Date}
   * @memberof CommunitySpecificProgramOptionsDefaultStartDates
   */
  semesterOneStartDate: Date
  /**
   * The default starting date for the program's second semester in date format: `yyyy-MM-dd`.
   * @type {Date}
   * @memberof CommunitySpecificProgramOptionsDefaultStartDates
   */
  semesterTwoStartDate: Date
}

export function CommunitySpecificProgramOptionsDefaultStartDatesFromJSON(
  json: any
): CommunitySpecificProgramOptionsDefaultStartDates {
  return CommunitySpecificProgramOptionsDefaultStartDatesFromJSONTyped(
    json,
    false
  )
}

export function CommunitySpecificProgramOptionsDefaultStartDatesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CommunitySpecificProgramOptionsDefaultStartDates {
  if (json === undefined || json === null) {
    return json
  }
  return {
    semesterOneStartDate: new Date(json['semesterOneStartDate']),
    semesterTwoStartDate: new Date(json['semesterTwoStartDate']),
  }
}

export function CommunitySpecificProgramOptionsDefaultStartDatesToJSON(
  value?: CommunitySpecificProgramOptionsDefaultStartDates | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    semesterOneStartDate: value.semesterOneStartDate
      .toISOString()
      .substr(0, 10),
    semesterTwoStartDate: value.semesterTwoStartDate
      .toISOString()
      .substr(0, 10),
  }
}
