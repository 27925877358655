/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface LoginUpdateRequestBody
 */
export interface LoginUpdateRequestBody {
  /**
   * Unique identifier for the actorKey the user will act as in this session. If not provided, the user will be logged in as 'parent'. Any requested actor must be an actor that belongs to the user and is currently valid.
   * @type {number}
   * @memberof LoginUpdateRequestBody
   */
  actorKey?: number
}

export function LoginUpdateRequestBodyFromJSON(
  json: any
): LoginUpdateRequestBody {
  return LoginUpdateRequestBodyFromJSONTyped(json, false)
}

export function LoginUpdateRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LoginUpdateRequestBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    actorKey: !exists(json, 'actorKey') ? undefined : json['actorKey'],
  }
}

export function LoginUpdateRequestBodyToJSON(
  value?: LoginUpdateRequestBody | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    actorKey: value.actorKey,
  }
}
