/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface TranscriptYearCourseWork
 */
export interface TranscriptYearCourseWork {
  /**
   * unique identifier for this transcriptYearCourseWork record.
   * @type {number}
   * @memberof TranscriptYearCourseWork
   */
  transcriptYearCourseWorkKey?: number
  /**
   *
   * @type {string}
   * @memberof TranscriptYearCourseWork
   */
  courseName: string
  /**
   *
   * @type {string}
   * @memberof TranscriptYearCourseWork
   */
  letterGrade: string
  /**
   * Credits received for the course.
   * @type {number}
   * @memberof TranscriptYearCourseWork
   */
  credits: number
}

export function TranscriptYearCourseWorkFromJSON(
  json: any
): TranscriptYearCourseWork {
  return TranscriptYearCourseWorkFromJSONTyped(json, false)
}

export function TranscriptYearCourseWorkFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TranscriptYearCourseWork {
  if (json === undefined || json === null) {
    return json
  }
  return {
    transcriptYearCourseWorkKey: !exists(json, 'transcriptYearCourseWorkKey')
      ? undefined
      : json['transcriptYearCourseWorkKey'],
    courseName: json['courseName'],
    letterGrade: json['letterGrade'],
    credits: json['credits'],
  }
}

export function TranscriptYearCourseWorkToJSON(
  value?: TranscriptYearCourseWork | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    transcriptYearCourseWorkKey: value.transcriptYearCourseWorkKey,
    courseName: value.courseName,
    letterGrade: value.letterGrade,
    credits: value.credits,
  }
}
