import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { Program, ProgramStatus } from '../../swagger'
import { dateToSlashString } from '../../utils/dateUtility'
import CardActionArea from '@mui/material/CardActionArea'
import { Box, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { Can } from '@casl/react'
import { useAuth } from '../Routes/AuthProvider'

export enum ElevatedCardVariants {
  Programs = 'Programs',
  DirectorDashboard = 'DirectorDashboard',
}
interface ProgramCard extends Program {
  variant: ElevatedCardVariants.Programs
  navigateToProgramDetails: (programsKey: number, communityId: number) => void
}

interface DirectorDashboard {
  variant: ElevatedCardVariants.DirectorDashboard
  communityName: string
  type: string
  programKey: number
  communityKey: number
  year: number
  total: number
  isOutstandingTuition: boolean
  isPendingEnrollment: boolean
  isInvitation: boolean
  navigateToProgram: (programsKey: number, communityId: number) => void
}

export const daysOfTheWeekMap = new Map([
  [1, 'Monday'],
  [2, 'Tuesday'],
  [3, 'Wednesday'],
  [4, 'Thursday'],
  [5, 'Friday'],
  [6, 'Saturday'],
  [7, 'Sunday'],
])

export type CardProgram = Pick<
  ProgramCard,
  | 'type'
  | 'director'
  | 'communityName'
  | 'address'
  | 'status'
  | 'communityId'
  | 'programsKey'
  | 'semesterOneStartDate'
  | 'dayOfTheWeek'
  | 'dayOfTheWeek'
  | 'navigateToProgramDetails'
  | 'variant'
>

type ElevatedCardProps = CardProgram | DirectorDashboard

export const ElevatedCardContent = styled(CardContent)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  textAlign: 'left',
  paddingBottom: `${theme.spacing(2)}px !important`,
  color: theme.palette.primary.main,
}))

export const StyledCard = styled(Card)(({ theme }) => ({
  minHeight: 200,
  width: 320,
  color: theme.palette.primary.main,
}))

/**
 * !!! Attempting to use component prop on Typography results in an error in the
 * form of TypeScript limitation regarding argument interface and overload
 * function signatures
 *
 * See known issues + workaround https://github.com/mui/material-ui/issues/15759#issuecomment-493994852
 */
const InlineAndDark = styled(Typography)(({ theme }) => ({
  display: 'inline',
  color: theme.palette.primary.dark,
})) as typeof Typography

/**
 * !!! Attempting to use component prop on Typography results in an error in the
 * form of TypeScript limitation regarding argument interface and overload
 * function signatures
 *
 * See known issues + workaround https://github.com/mui/material-ui/issues/15759#issuecomment-493994852
 */
export const InlineAndMain = styled(Typography)(({ theme }) => ({
  display: 'inline',
  color: theme.palette.primary.main,
})) as typeof Typography

/**
 * !!! Attempting to use component prop on Typography results in an error in the
 * form of TypeScript limitation regarding argument interface and overload
 * function signatures
 *
 * See known issues + workaround https://github.com/mui/material-ui/issues/15759#issuecomment-493994852
 */
export const DarkAndMargined = styled(Typography)(({ theme }) => ({
  display: 'inline',
  color: theme.palette.primary.main,
  margin: theme.spacing(1, 0),
})) as typeof Typography

/**
 * !!! Attempting to use component prop on Typography results in an error in the
 * form of TypeScript limitation regarding argument interface and overload
 * function signatures
 *
 * See known issues + workaround https://github.com/mui/material-ui/issues/15759#issuecomment-493994852
 */
const CardText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: theme.spacing(1, 0),
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  /**
   * Set the height to twice the text height to maintain proper spacing.
   * Calculate height using: 2 * lineHeight * fontSize
   */
  height: 'calc(2 * 1.57 * 0.875rem)',
})) as typeof Typography

const ElevatedCard: React.FC<ElevatedCardProps> = (props) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const { permissionAbility } = useAuth()

  const localizedNoneAssigned = t(
    'Card.Director.NoneAssigned',
    '[None Assigned]'
  )

  const borderColor =
    props.type === 'Foundations'
      ? theme.palette.program.foundations
      : props.type === 'Essentials'
      ? theme.palette.program.essentials
      : props.type === 'Scribblers'
      ? theme.palette.program.scribblers
      : theme.palette.program.challenge

  if (props.variant === ElevatedCardVariants.DirectorDashboard) {
    return (
      <CustomCard
        navigateFn={() =>
          props.navigateToProgram(props.programKey, props.communityKey)
        }
        cardKey={props.programKey}
        borderColor={`${borderColor}`}
      >
        <>
          <Typography variant="h6" component="h2">
            {props.type}
          </Typography>
          <DarkAndMargined variant="body2" component="span">
            {t('Card.Year', 'Year: ')}
            <InlineAndMain variant="subtitle2" component="p">
              {props.year}
            </InlineAndMain>
          </DarkAndMargined>
          <CardText variant="body2" component="span">
            {t('Card.CommunityName', 'Community Name: ')}
            <InlineAndMain variant="subtitle2" component="p">
              {props.communityName}
            </InlineAndMain>
          </CardText>
          {props.isPendingEnrollment && (
            <CardText variant="body2" component="span">
              {t(
                'Card.PendingEnrollments.Total',
                'Pending Enrollments Total: '
              )}
              <InlineAndMain variant="subtitle2" component="p">
                {props.total}
              </InlineAndMain>
            </CardText>
          )}
          {props.isOutstandingTuition && (
            <CardText variant="body2" component="span">
              {t(
                'Card.OutstandingTuition.Total',
                'Outstanding Tuition Total: '
              )}
              <InlineAndMain variant="subtitle2" component="p">
                {props.total}
              </InlineAndMain>
            </CardText>
          )}
          {props.isInvitation && (
            <CardText variant="body2" component="span">
              {t(
                'Card.InProgressInvitations.Total',
                'In Progress Invitations Total: '
              )}
              <InlineAndMain variant="subtitle2" component="p">
                {props.total}
              </InlineAndMain>
            </CardText>
          )}
        </>
      </CustomCard>
    )
  }

  const programStatusLabel = {
    [ProgramStatus.Draft]: t('Card.Status.Draft', 'Draft'),
    [ProgramStatus.Published]: t('Card.Status.Published', 'Published'),
    [ProgramStatus.Complete]: t('Card.Status.Complete', 'Complete'),
    [ProgramStatus.Archived]: t('Card.Status.Archived', 'Archived'),
  }

  return (
    <CustomCard
      navigateFn={() =>
        props.navigateToProgramDetails(props.programsKey, props.communityId)
      }
      cardKey={props.programsKey}
      borderColor={`${borderColor}`}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <InlineAndDark variant="overline">
          {programStatusLabel[props.status]}
        </InlineAndDark>
      </Box>

      <Typography variant="h6" component="h2">
        {props.type}
      </Typography>
      <DarkAndMargined variant="body2" component="span">
        {t('Card.Director', 'Director: ')}
        <InlineAndMain variant="subtitle2" component="p">
          {props.director ? props.director : localizedNoneAssigned}
        </InlineAndMain>
      </DarkAndMargined>
      <Can I="editProgramDates" on="Program" ability={permissionAbility}>
        <DarkAndMargined variant="body2" component="span">
          {t('Card.StartDate', 'Start Date: ')}
          <InlineAndMain variant="subtitle2" component="p">
            {dateToSlashString(props.semesterOneStartDate, true)}
          </InlineAndMain>
        </DarkAndMargined>
      </Can>
      <DarkAndMargined variant="body2" component="span">
        {t('Card.MeetingDay', 'Meets on: ')}
        <InlineAndMain variant="subtitle2" component="p">
          {daysOfTheWeekMap.get(props.dayOfTheWeek as number)}
        </InlineAndMain>
      </DarkAndMargined>
      <CardText variant="body2" component="span">
        {t('Card.CommunityName', 'Community Name: ')}
        <InlineAndMain variant="subtitle2" component="p">
          {props.communityName}
        </InlineAndMain>
      </CardText>
    </CustomCard>
  )
}

export default ElevatedCard

export const CustomCard: React.FC<{
  children: JSX.Element | JSX.Element[]
  cardKey: number | string
  navigateFn: () => void
  borderColor: string
}> = ({ children, cardKey, navigateFn, borderColor }) => {
  return (
    <StyledCard
      data-testid="programCard"
      variant="elevation"
      sx={{ borderTop: `12px solid ${borderColor}` }}
    >
      <CardActionArea onClick={() => navigateFn()} key={cardKey}>
        <ElevatedCardContent>{children}</ElevatedCardContent>
      </CardActionArea>
    </StyledCard>
  )
}
