import React, { useContext, useState } from 'react'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import CardFormHeader from './CardFormHeader'
import TranscriptsTable from '../Transcripts/TranscriptsTable'
import { Page } from '../Elements/PageMargins'
import Header, { HeaderVariant } from '../Elements/Header'
import { useTranslation } from 'react-i18next'
import { Chip, IconButton, Tooltip, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { PictureAsPdf, Sort } from '@mui/icons-material'
import { useNavigate } from 'react-router'
import {
  TranscriptFlowStages,
  useTranscriptContext,
} from '../Context/TranscriptContext'
import { useMountEffect } from '../../hooks/useMountEffect'
import DynamicBreadcrumbs from '../Elements/DynamicBreadcrumbs'
import TranscriptFilterModal from '../Modals/TranscriptFilterModal'
import { DropDownFilterOption } from '../Modals/FilterModal'
import { useFetchTranscripts } from '../../hooks/useFetchTranscripts'
import { LoadingContext } from '../Context/LoadingContext'
import { useAccountContext } from '../Context/AccountContext'
import {
  FetchTranscriptsRequestStatusEnum,
  OrderByDirection,
  PaginationResponse,
} from '../../swagger'
import { useAuth } from '../Routes/AuthProvider'
import { DEFAULT_PAGE_SIZE } from '../../utils/constants'
import SearchBar from '../Search/SearchBar'
import { GridRowSelectionModel } from '@mui/x-data-grid'
import getOrderByClause from '../../utils/getOrderByClause'
import BasicModal from '../Modals/BasicModal'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import ActionButtons from '../Buttons/ActionButtons'
import useLocalStorage from '../../hooks/useLocalStorage'
import { useFilterContext } from '../Context/FilterContext'

// These enums could probably be getter methods,
// But we probably won't ever change these.
enum TranscriptFilterByTypes {
  filterChildName = 'filterChildName',
  filterParchmentRequestStatus = 'filterParchmentRequestStatus',
}

enum FilterAllOptions {
  allChildren = 'allChildren',
  allStatuses = 'allStatuses',
}

export const TranscriptsCard: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const { permissionAbility, userDetails } = useAuth()
  const [disclaimerModal, setDisclaimerModal] = useLocalStorage<
    Array<number | undefined>
  >('disclaimerTranscriptModal', [])

  const canCreateTranscript =
    permissionAbility.can('create', 'Transcript') ||
    userDetails.actingAs === 'parent'
  const canPrintTranscript = permissionAbility.can('print', 'Transcript')

  const { students } = useAccountContext()

  const { transcripts } = useTranscriptContext()
  const { breadcrumbs, updateBreadcrumbs } = useTranscriptContext()

  const { fetchTranscriptsParams, setFetchTranscriptParams } =
    useFilterContext()

  const [searchValue, setSearchValue] = useState('')

  const handleCloseModal = () => {
    setDisclaimerModal([...disclaimerModal, userDetails.userKey])
    setShowModal(false)
  }

  useMountEffect(() => {
    updateBreadcrumbs(TranscriptFlowStages.Transcripts)

    if (
      !!disclaimerModal &&
      disclaimerModal.some((userKey) => userKey === userDetails.userKey)
    ) {
      return setShowModal(false)
    }

    userDetails.actingAs === 'parent' && setShowModal(true)
  })

  const { addLoadingIds } = useContext(LoadingContext)

  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const [pagination, setPagination] = useState<PaginationResponse>({
    orderBy: [{ transcriptKey: OrderByDirection.Asc }],
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    totalCount: 0,
  })
  const [filteredStudent, setFilteredStudent] = useState<string | undefined>()

  const { loadingId } = useFetchTranscripts(
    {
      ...fetchTranscriptsParams,
      page: pagination.page,
      pageSize: pagination.pageSize,
      orderBy: getOrderByClause(pagination.orderBy),
      search: searchValue,
    },
    (pagination: PaginationResponse) =>
      setPagination((prevState) => ({
        ...prevState,
        page: pagination.page,
        pageSize: pagination.pageSize,
        totalCount: pagination.totalCount,
      }))
  )

  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([])

  const onRowSelectionModelChange = (
    newRowSelectionModel: GridRowSelectionModel
  ) => {
    setRowSelectionModel(newRowSelectionModel)
  }

  const parseFilters = (output: DropDownFilterOption[]) => {
    const filterObj = { ...fetchTranscriptsParams }
    for (const option of output) {
      switch (option.id) {
        case TranscriptFilterByTypes.filterChildName:
          switch (option.valueId) {
            case FilterAllOptions.allChildren:
              filterObj.studentKey = undefined
              break
            default:
              /**
               * We should use this and the student name to make a chip, not just the key
               * so let's use the option.value which is the student's name and we'll append
               * them in the chip.
               */
              filterObj.studentKey = +option.valueId
              setFilteredStudent(option.value)
              break
          }
          break
        case TranscriptFilterByTypes.filterParchmentRequestStatus:
          switch (option.valueId) {
            case FilterAllOptions.allStatuses:
              filterObj.requestStatus = undefined
              break
            default:
              filterObj.requestStatus =
                option.valueId as FetchTranscriptsRequestStatusEnum
              break
          }
          break
      }
    }
    setFetchTranscriptParams({ ...filterObj })
    addLoadingIds([loadingId])
  }

  const handlePagination = (p: PaginationResponse) => {
    if (pagination.page < 1) return
    setPagination({ ...p, page: p.page + 1 })
    addLoadingIds([loadingId])
  }

  return (
    <Page>
      <BasicModal
        isOpen={showModal}
        maxWidth={'xs'}
        dialogContent={
          <Box maxWidth={300} mx="auto" textAlign="center">
            <Typography component="p">
              {t(
                'TranscriptCard.ConfirmationModal.Content',
                'By using this service, you acknowledge that Classical Conversations and its affiliates are not custodians of records.'
              )}
            </Typography>
          </Box>
        }
        handleFormSubmit={handleCloseModal}
        dialogActions={
          <ActionButtons primaryButtonLabel={ContainedButtonVariant.Ok} />
        }
      />
      <DynamicBreadcrumbs breadcrumbs={breadcrumbs} />
      <TranscriptFilterModal
        isOpen={filterModalOpen}
        closeModal={() => {
          setFilterModalOpen(false)
        }}
        parseFilters={parseFilters}
      /** Rather than base the modal appearing on the children, just update the students passed. */
        students={students.length > 0 ? students : []}
      />
      <Card>
        <CardFormHeader
          header={
            <Header
              id="transcripts-header"
              headerName={
                userDetails.actingAs === 'parent'
                  ? t('Transcripts.Card.Header', 'My Transcripts')
                  : t('Transcripts.Card.Header', 'Transcripts')
              }
              component="h2"
              variant={HeaderVariant.Card}
            />
          }
          buttons={
            <Box mr={2} sx={{ display: 'flex', gap: '4px' }}>
              {/* https://www.youtube.com/watch?v=KRUk4aR3MEU&t=52s */}
              {Object.values(fetchTranscriptsParams).map((param) => {
                if (!param) return null

                /** Append student name and key as filter, or status */
                return (
                  <Chip
                    label={`${
                      typeof param === 'number'
                        ? filteredStudent?.concat(` ${param}`)
                        : param
                    }`}
                    sx={{ mr: 1 }}
                    key={param}
                  />
                )
              })}
              {canCreateTranscript && (
                <Tooltip title={
                  t('Transcripts.TranscriptsCard.AddTranscript',
                    'Add Transcript'
                  )
                }>
                  <IconButton
                    aria-label="Add Transcript"
                    onClick={() =>
                      navigate(
                        {
                          pathname: '/transcripts/add-transcript',
                        },
                        {
                          replace: true,
                        }
                      )
                    }
                  >
                    <AddIcon />
                </IconButton>
                </Tooltip>
              )}
              {canPrintTranscript && (
                <Tooltip title={
                  t('Transcripts.TranscriptsCard.ViewTranscriptPDF',
                    'View Transcript PDF'
                  )
                }>
                  <IconButton
                    aria-label="View Transcript PDF"
                    onClick={() => {
                      navigate(
                        {
                          pathname: '/transcripts/view-selected',
                        },
                        {
                          replace: true,
                          state: {
                            /** Provide transcriptKeys to the DownloadTranscriptsPreview */
                            transcriptKeys: rowSelectionModel.reduce(
                              (acc, curr) => {
                                const index: number = +curr
                                acc.push(transcripts[index].transcriptKey)
                                return acc
                              },
                              [] as number[]
                            ),
                          },
                        }
                      )
                    }}
                    disabled={rowSelectionModel.length === 0}
                  >
                    <PictureAsPdf />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title={
                t('Transcripts.TranscriptsCard.FilterBy',
                  'Filter By'
                )
              }>
                <IconButton
                  aria-label="Filter By"
                  onClick={() => setFilterModalOpen(true)}
                >
                  <Sort />
                </IconButton>
              </Tooltip>
              <SearchBar
                handleSearch={(value) => {
                  setSearchValue(value)
                  addLoadingIds([loadingId])
                }}
                placeholder={t(
                  'Transcripts.Card.SearchBar',
                  'Search Transcripts'
                )}
                sx={{ '& .MuiOutlinedInput-root': { marginBottom: 0 } }}
                searchDebounce
              />
            </Box>
          }
        />
        <Box>
          <TranscriptsTable
            ariaLabelledBy="transcripts-header"
            handlePaginationChange={handlePagination}
            pagination={{
              ...pagination,
              page: pagination.page - 1,
            }}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={onRowSelectionModelChange}
            transcripts={transcripts}
            canCreateTranscript={canCreateTranscript}
            triggerRefresh={() => {
              addLoadingIds([loadingId])
            }}
          />
        </Box>
      </Card>
    </Page>
  )
}

export default TranscriptsCard
