import InfoIcon from '@mui/icons-material/Info'
import { Tooltip, useTheme } from '@mui/material'
import React from 'react'

export const InfoTooltip: React.FC<{ message: string }> = ({ message }) => {
  const theme = useTheme()
  return (
    <Tooltip title={message}>
      <InfoIcon
        aria-label="tooltipIcon"
        sx={{
          fontSize: '24px',
          color: theme.palette.textOrIcon.tooltipButton,
        }}
      />
    </Tooltip>
  )
}
