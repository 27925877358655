/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Credentials, CredentialsFromJSON, CredentialsToJSON } from './'

/**
 *
 * @export
 * @interface SignUpConfirmTokenRequestBody
 */
export interface SignUpConfirmTokenRequestBody {
  /**
   *
   * @type {Credentials}
   * @memberof SignUpConfirmTokenRequestBody
   */
  credentials: Credentials
  /**
   * The first name of the new user. minLength: 1
   * @type {string}
   * @memberof SignUpConfirmTokenRequestBody
   */
  firstName: string
  /**
   * The last name of the new user. minLength: 1
   * @type {string}
   * @memberof SignUpConfirmTokenRequestBody
   */
  lastName: string
  /**
   * The 16-digit one-time password sent to the account registrant’s specified email address via the `sendTokenForSignup` route.
   * @type {string}
   * @memberof SignUpConfirmTokenRequestBody
   */
  token: string
  /**
   * The phone number provided by the user.
   * @type {string}
   * @memberof SignUpConfirmTokenRequestBody
   */
  phone: string
}

export function SignUpConfirmTokenRequestBodyFromJSON(
  json: any
): SignUpConfirmTokenRequestBody {
  return SignUpConfirmTokenRequestBodyFromJSONTyped(json, false)
}

export function SignUpConfirmTokenRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SignUpConfirmTokenRequestBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    credentials: CredentialsFromJSON(json['credentials']),
    firstName: json['firstName'],
    lastName: json['lastName'],
    token: json['token'],
    phone: json['phone'],
  }
}

export function SignUpConfirmTokenRequestBodyToJSON(
  value?: SignUpConfirmTokenRequestBody | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    credentials: CredentialsToJSON(value.credentials),
    firstName: value.firstName,
    lastName: value.lastName,
    token: value.token,
    phone: value.phone,
  }
}
