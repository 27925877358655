/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  CreateTranscriptYearCourseWork,
  CreateTranscriptYearCourseWorkFromJSON,
  CreateTranscriptYearCourseWorkToJSON,
} from './'

/**
 * A year for a transcript to relay the year, the course work, and any additional information.
 * @export
 * @interface CreateTranscriptYear
 */
export interface CreateTranscriptYear {
  /**
   *
   * @type {number}
   * @memberof CreateTranscriptYear
   */
  academicYear?: number
  /**
   * Additional information about the coursework for a year.
   * @type {string}
   * @memberof CreateTranscriptYear
   */
  notes?: string
  /**
   * The list of classes done for the academic year.
   * @type {Array<CreateTranscriptYearCourseWork>}
   * @memberof CreateTranscriptYear
   */
  transcriptYearCourseWork?: Array<CreateTranscriptYearCourseWork>
  /**
   * Total credits for the transcriptYear, this field is calculated on the frontend
   * @type {number}
   * @memberof CreateTranscriptYear
   */
  totalCredits?: number
  /**
   * GPA of the transcriptYear, this field is calculated on the frontend
   * @type {number}
   * @memberof CreateTranscriptYear
   */
  gpa?: number
}

export function CreateTranscriptYearFromJSON(json: any): CreateTranscriptYear {
  return CreateTranscriptYearFromJSONTyped(json, false)
}

export function CreateTranscriptYearFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateTranscriptYear {
  if (json === undefined || json === null) {
    return json
  }
  return {
    academicYear: !exists(json, 'academicYear')
      ? undefined
      : json['academicYear'],
    notes: !exists(json, 'notes') ? undefined : json['notes'],
    transcriptYearCourseWork: !exists(json, 'transcriptYearCourseWork')
      ? undefined
      : (json['transcriptYearCourseWork'] as Array<any>).map(
          CreateTranscriptYearCourseWorkFromJSON
        ),
    totalCredits: !exists(json, 'totalCredits')
      ? undefined
      : json['totalCredits'],
    gpa: !exists(json, 'gpa') ? undefined : json['gpa'],
  }
}

export function CreateTranscriptYearToJSON(
  value?: CreateTranscriptYear | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    academicYear: value.academicYear,
    notes: value.notes,
    transcriptYearCourseWork:
      value.transcriptYearCourseWork === undefined
        ? undefined
        : (value.transcriptYearCourseWork as Array<any>).map(
            CreateTranscriptYearCourseWorkToJSON
          ),
    totalCredits: value.totalCredits,
    gpa: value.gpa,
  }
}
