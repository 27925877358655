/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface InviteFamilyCommunitiesOptions
 */
export interface InviteFamilyCommunitiesOptions {
  /**
   * Unique identifier for a community
   * @type {number}
   * @memberof InviteFamilyCommunitiesOptions
   */
  communityKey: number
  /**
   * Name of a community
   * @type {string}
   * @memberof InviteFamilyCommunitiesOptions
   */
  communityName: string
}

export function InviteFamilyCommunitiesOptionsFromJSON(
  json: any
): InviteFamilyCommunitiesOptions {
  return InviteFamilyCommunitiesOptionsFromJSONTyped(json, false)
}

export function InviteFamilyCommunitiesOptionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InviteFamilyCommunitiesOptions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    communityKey: json['communityKey'],
    communityName: json['communityName'],
  }
}

export function InviteFamilyCommunitiesOptionsToJSON(
  value?: InviteFamilyCommunitiesOptions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    communityKey: value.communityKey,
    communityName: value.communityName,
  }
}
