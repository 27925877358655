import Box from '@mui/material/Box'
import React, { CSSProperties, PropsWithChildren, ReactNode } from 'react'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'

interface SpaceBetweenSectionProps extends PropsWithChildren {
  left?: ReactNode
  right?: ReactNode
  center?: ReactNode
  isCentered?: boolean
  marginRight?: number
  marginLeft?: number
  containerStyles?: CSSProperties
  leftStyle?: CSSProperties
  rightStyle?: CSSProperties
  centerStyle?: CSSProperties
  leftAlignContent?: string
}

export const SpaceBetweenSection: React.FC<SpaceBetweenSectionProps> = ({
  left = <></>,
  right = <></>,
  center = <></>,
  isCentered,
  marginRight = 0,
  marginLeft = 0,
  containerStyles,
  leftAlignContent = 'auto',
  children,
  leftStyle,
  rightStyle,
  centerStyle,
}) => {
  const showOnDesktop = useShowOnDesktop()

  const defaultChildren = (
    <>
      <Box
        ml={!!marginLeft ? marginLeft : 0}
        aria-label="left-item"
        alignContent={leftAlignContent}
        sx={leftStyle}
        mb={!showOnDesktop ? 1.25 : 0}
      >
        {left}
      </Box>
      {!!center && (
        <Box aria-label="center-item" sx={centerStyle}>
          {center}
        </Box>
      )}
      <Box
        aria-label="right-item"
        display="flex"
        flexDirection={showOnDesktop ? 'row' : 'column'}
        mr={!!marginRight ? marginRight : 0}
        ml={!showOnDesktop ? marginLeft : 0}
        sx={rightStyle}
      >
        {right}
      </Box>
    </>
  )

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: isCentered && showOnDesktop ? 'center' : 'initial',
        flexDirection: showOnDesktop ? 'row' : 'column',
        ...containerStyles,
      }}
    >
      {children ? children : defaultChildren}
    </Box>
  )
}

export default SpaceBetweenSection
