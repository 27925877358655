import { useState, useEffect } from 'react'

/**
 * A custom React hook for managing state synchronized with `localStorage`.
 *
 * @template T The type of the value to be stored.
 * @param {string} key - The `localStorage` key to store and retrieve the value.
 * @param {T} initialValue - The initial value to use if the key is not found in `localStorage`.
 * @returns {readonly [T, (value: T | ((val: T) => T)) => void]}
 * A tuple with:
 *  - `storedValue` (T): The current value stored in `localStorage` or the initial value if no value exists.
 *  - `setValue` (function): A function to update the value in both state and `localStorage`.
 *
 * ### Behavior:
 * - Initializes state with the value in `localStorage` if it exists; otherwise, uses `initialValue`.
 * - Updates `localStorage` whenever the `setValue` function is called.
 * - Reacts to external `localStorage` changes (e.g., via other browser tabs) and updates the state accordingly.
 * - Handles JSON parsing errors and logs them to the console.
 *
 * ### Example Usage:
 * ```tsx
 * const [storedValue, setValue] = useLocalStorage<boolean>('mayI', 'true');
 *
 * console.log(storedValue); // Outputs true or the value stored in localStorage for 'mayI'.
 *
 * setValue('false'); // Updates the state and localStorage with 'false'.
 * ```
 */

function useLocalStorage<T>(
  key: string,
  initialValue: T
): readonly [T, (value: T | ((val: T) => T)) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      console.error('Error reading localStorage key:', key, error)
      return initialValue
    }
  })

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      console.error('Error setting localStorage key:', key, error)
    }
  }

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === key) {
        try {
          const newValue = event.newValue ? JSON.parse(event.newValue) : null
          setStoredValue(newValue)
        } catch (error) {
          console.error('Error parsing localStorage key:', key, error)
        }
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [key])

  return [storedValue, setValue] as const
}

export default useLocalStorage
