import React from 'react'
import { ActionableTable } from '../Table/ActionableTable'
import { useTranslation } from 'react-i18next'

export interface UnassignedDirectorsTableProps {
  rows: {
    roleName: string
    fullName: string
    validFrom?: string
    validTo?: string
  }[]
}

export const UnassignedDirectorsTable: React.FC<
  UnassignedDirectorsTableProps
> = ({ rows }) => {
  const { t } = useTranslation()
  return (
    <ActionableTable
      rowHeight={35}
      columns={[
        {
          fieldName: 'roleName',
          columnHeaderName: t(
            'Dashboard.UnassignedDirectorsTable.Column.RoleName',
            'Role Name'
          ),
        },
        {
          fieldName: 'fullName',
          columnHeaderName: t(
            'Dashboard.UnassignedDirectorsTable.Column.FullName',
            'Full Name'
          ),
        },
        /** These fields follow the Roles table columns on the User Account screen */
        {
          fieldName: 'validFrom',
          columnHeaderName: t(
            'Dashboard.UnassignedDirectorsTable.Column.StartDate',
            'Start Date'
          ),
        },
        {
          fieldName: 'validTo',
          columnHeaderName: t(
            'Dashboard.UnassignedDirectorsTable.Column.EndDate',
            'End Date'
          ),
        },
      ]}
      noResultsMessage={t(
        'Dashboard.UnassignedDirectorsTable.Column.FullName',
        'No Results Found'
      )}
      rows={rows}
    />
  )
}
