/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  AgreementWebhookRequestBody,
  AgreementWebhookRequestBodyToJSON,
  AuthorizeDotNetEventWebhookRequestBody,
  AuthorizeDotNetEventWebhookRequestBodyToJSON,
} from '../models'
import * as runtime from '../runtime'

export interface AuthorizeDotNetEventWebhookRequest
  extends runtime.BaseRequestParameters {
  body?: AuthorizeDotNetEventWebhookRequestBody
}

export interface CompleteAgreementWebhookRequest
  extends runtime.BaseRequestParameters {
  body?: AgreementWebhookRequestBody
}

/**
 *
 */
export class WebhooksApi extends runtime.BaseAPI {
  /**
   * Webhook trigger after a user submits their payment via Authorize.net using a credit card.     Updates the enrollments associated with the payment to ENROLLED and the payment status to PAID.     Also, emails the payee with a status notification received from ANet.
   * Receives notifications from Authorize.net involving payment events
   */
  async authorizeDotNetEventWebhookRaw(
    requestParameters: AuthorizeDotNetEventWebhookRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/webhook/authorizeNet/AuthorizeDotNetEvent`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: AuthorizeDotNetEventWebhookRequestBodyToJSON(
        requestParameters.body
      ),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Webhook trigger after a user submits their payment via Authorize.net using a credit card.     Updates the enrollments associated with the payment to ENROLLED and the payment status to PAID.     Also, emails the payee with a status notification received from ANet.
   * Receives notifications from Authorize.net involving payment events
   */
  async authorizeDotNetEventWebhook(
    requestParameters: AuthorizeDotNetEventWebhookRequest
  ): Promise<void> {
    await this.authorizeDotNetEventWebhookRaw(requestParameters)
  }

  /**
   * Webhook trigger after Completed agreement sign, this will create the actor for the user with new role and the contracted validity window and update the agreement status in the database.
   * Complete an open agreement via webhook.
   */
  async completeAgreementWebhookRaw(
    requestParameters: CompleteAgreementWebhookRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/webhook/adobeSign/completeAgreement`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: AgreementWebhookRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Webhook trigger after Completed agreement sign, this will create the actor for the user with new role and the contracted validity window and update the agreement status in the database.
   * Complete an open agreement via webhook.
   */
  async completeAgreementWebhook(
    requestParameters: CompleteAgreementWebhookRequest
  ): Promise<void> {
    await this.completeAgreementWebhookRaw(requestParameters)
  }

  /**
   * This will be triggered when Creating a webhook to verify that the webhook url exist and the adobe client id exist for this application. It will also be triggered when we deactivate an webhook
   * Validate the adobe sign webhook link, when activate or de-active a web hook
   */
  async confirmAdobeSignWebhookRaw(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/webhook/adobeSign/completeAgreement`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * This will be triggered when Creating a webhook to verify that the webhook url exist and the adobe client id exist for this application. It will also be triggered when we deactivate an webhook
   * Validate the adobe sign webhook link, when activate or de-active a web hook
   */
  async confirmAdobeSignWebhook(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<void> {
    await this.confirmAdobeSignWebhookRaw(requestParameters)
  }
}
