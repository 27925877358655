import React, { PropsWithChildren } from 'react'

const defaultDashboardContextValue = {}

export const DashboardContext = React.createContext(
  defaultDashboardContextValue
)

export type TestDashboardConfig = Partial<typeof defaultDashboardContextValue>

export const useDashboardContext = (): typeof defaultDashboardContextValue =>
  React.useContext(DashboardContext)

export interface TestDashboardConfigWithProps extends PropsWithChildren {
  testConfig?: TestDashboardConfig
}

const DashboardProvider: React.FC<TestDashboardConfigWithProps> = ({
  testConfig,
  children,
}) => {
  const value = {
    ...testConfig,
  }
  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  )
}

export default DashboardProvider
