/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface FetchEventsResponse
 */
export interface FetchEventsResponse {
  /**
   * Event unique identifier
   * @type {number}
   * @memberof FetchEventsResponse
   */
  eventKey: number
  /**
   * Name of the event.
   * @type {string}
   * @memberof FetchEventsResponse
   */
  name: string
  /**
   * The type of event:  Information Meeting, Open House, Practicum
   * @type {string}
   * @memberof FetchEventsResponse
   */
  eventType: FetchEventsResponseEventTypeEnum
  /**
   * The city/town where the event is being held
   * @type {string}
   * @memberof FetchEventsResponse
   */
  city?: string
  /**
   * The starting date for the event in date format: `yyyy-MM-dd`.
   * @type {Date}
   * @memberof FetchEventsResponse
   */
  startDate: Date
  /**
   * The ending date for the event in date format: `yyyy-MM-dd`. To support multi-day events.
   * @type {Date}
   * @memberof FetchEventsResponse
   */
  endDate?: Date
  /**
   * The starting time for the event using ISO standard 24 hour format: `hh:mm`.
   * @type {string}
   * @memberof FetchEventsResponse
   */
  startTime: string
  /**
   * The ending time for the event using ISO standard 24 hour format: `hh:mm`.
   * @type {string}
   * @memberof FetchEventsResponse
   */
  endTime: string
  /**
   * The timezone in which the event date/time is taking place. The timezone is in the IANA time zone format.
   * @type {string}
   * @memberof FetchEventsResponse
   */
  timezone: string
  /**
   * Is this an online event? If true, then provide an event link in onlineEventUrl.
   * @type {boolean}
   * @memberof FetchEventsResponse
   */
  isOnline: boolean
  /**
   * The marketing site URL for the event so the user can promote it on social media.
   * @type {string}
   * @memberof FetchEventsResponse
   */
  publicUrl?: string
  /**
   * When false, registrants can no longer register for an event. Defaults to true.
   * @type {boolean}
   * @memberof FetchEventsResponse
   */
  registrationOpen?: boolean
  /**
   * Name of the person that created the event within the app.
   * @type {string}
   * @memberof FetchEventsResponse
   */
  createdBy?: string
  /**
   * The amount of people attending to an event.
   * @type {number}
   * @memberof FetchEventsResponse
   */
  attendees: number
}

/**
 * @export
 * @enum {string}
 */
export enum FetchEventsResponseEventTypeEnum {
  InformationMeeting = 'Information Meeting',
  OpenHouse = 'Open House',
  Practicum = 'Practicum',
}

export function FetchEventsResponseFromJSON(json: any): FetchEventsResponse {
  return FetchEventsResponseFromJSONTyped(json, false)
}

export function FetchEventsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FetchEventsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    eventKey: json['eventKey'],
    name: json['name'],
    eventType: json['eventType'],
    city: !exists(json, 'city') ? undefined : json['city'],
    startDate: new Date(json['startDate']),
    endDate: !exists(json, 'endDate') ? undefined : new Date(json['endDate']),
    startTime: json['startTime'],
    endTime: json['endTime'],
    timezone: json['timezone'],
    isOnline: json['isOnline'],
    publicUrl: !exists(json, 'publicUrl') ? undefined : json['publicUrl'],
    registrationOpen: !exists(json, 'registrationOpen')
      ? undefined
      : json['registrationOpen'],
    createdBy: !exists(json, 'createdBy') ? undefined : json['createdBy'],
    attendees: json['attendees'],
  }
}

export function FetchEventsResponseToJSON(
  value?: FetchEventsResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    eventKey: value.eventKey,
    name: value.name,
    eventType: value.eventType,
    city: value.city,
    startDate: value.startDate.toISOString().substr(0, 10),
    endDate:
      value.endDate === undefined
        ? undefined
        : value.endDate.toISOString().substr(0, 10),
    startTime: value.startTime,
    endTime: value.endTime,
    timezone: value.timezone,
    isOnline: value.isOnline,
    publicUrl: value.publicUrl,
    registrationOpen: value.registrationOpen,
    createdBy: value.createdBy,
    attendees: value.attendees,
  }
}
