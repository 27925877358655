/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface FetchDownloadUrl
 */
export interface FetchDownloadUrl {
  /**
   * The download url for a content asset.
   * @type {string}
   * @memberof FetchDownloadUrl
   */
  downloadUrl: string
}

export function FetchDownloadUrlFromJSON(json: any): FetchDownloadUrl {
  return FetchDownloadUrlFromJSONTyped(json, false)
}

export function FetchDownloadUrlFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FetchDownloadUrl {
  if (json === undefined || json === null) {
    return json
  }
  return {
    downloadUrl: json['downloadUrl'],
  }
}

export function FetchDownloadUrlToJSON(value?: FetchDownloadUrl | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    downloadUrl: value.downloadUrl,
  }
}
