import { logoutHandler } from '../helpers/logoutHandler'
import {
  AuthenticationApi,
  CommunityApi,
  Configuration,
  FetchParams,
  MetaApi,
  Middleware,
  ModelError,
  ModelErrorFromJSON,
  ProgramsApi,
  RequestContext,
  UsersApi,
  FamiliesApi,
  TeamsApi,
  EventsApi,
  AgreementsApi,
  TimezoneApi,
  AddressApi,
  ContentApi,
  UserApi,
  EnrollmentsApi,
  UserAccountsApi,
  RegionsApi,
  PaymentsApi,
  InvoicesApi,
  RolesApi,
  WebhooksApi,
  TuitionPaymentsApi,
  InvitesApi,
  RegistrantsApi,
  ShopifyApi,
  TranscriptsApi,
  DashboardApi,
} from '../swagger'
import { StudentApi } from '../swagger/apis/StudentApi'
import { ChangeActorMiddleware } from './ChangeActorMiddleware'
import { LogoutSessionMiddleware } from './LogoutSessionMiddleware'
export * from '../swagger/models'

class IncludeCredentialsMiddleware implements Middleware {
  async pre?(context: RequestContext): Promise<FetchParams | void> {
    context.init.credentials = 'include'
    return context
  }
}

export const ignoreLoginRequests = (
  url: string,
  init: RequestInit // XXX: add checks around init information
): boolean => {
  const shouldIgnore = url.match(/login|signup/i) ?? []
  return shouldIgnore.length > 0 && init !== undefined
}

/** Returns an endpoint URL with the default (or supplied) baseUrl. You are responsible for encoding any dynamic URI components! */
export const endpoint = (
  path: string,
  baseUrl = process.env.REACT_APP_API_BASE_URL
): string => baseUrl + path

const buildConfig = (basePath = process.env.REACT_APP_API_BASE_URL) =>
  new Configuration({
    basePath,
    middleware: [
      new IncludeCredentialsMiddleware(),
      new LogoutSessionMiddleware(logoutHandler, ignoreLoginRequests),
      new ChangeActorMiddleware(logoutHandler),
    ],
  })
const config = buildConfig()

export const communities = new CommunityApi(config)
export const users = new UsersApi(config)
export const auth = new AuthenticationApi(config)
export const meta = new MetaApi(config)
export const programs = new ProgramsApi(config)
export const families = new FamiliesApi(config)
export const teams = new TeamsApi(config)
export const events = new EventsApi(config)
export const agreements = new AgreementsApi(config)
export const timezones = new TimezoneApi(config)
export const addressApi = new AddressApi(config)
export const contentApi = new ContentApi(config)
export const user = new UserApi(config)
export const enrollments = new EnrollmentsApi(config)
export const userAccountsApi = new UserAccountsApi(config)
export const regionsApi = new RegionsApi(config)
export const paymentsApi = new PaymentsApi(config)
export const tuitionPaymentsApi = new TuitionPaymentsApi(config)
export const invoicesApi = new InvoicesApi(config)
export const rolesApi = new RolesApi(config)
export const webhooksApi = new WebhooksApi(config)
export const studentApi = new StudentApi(config)
export const invitesApi = new InvitesApi(config)
export const registrantsApi = new RegistrantsApi(config)
export const shopifyApi = new ShopifyApi(config)
export const transcriptsApi = new TranscriptsApi(config)
export const dashboardApi = new DashboardApi(config)

/** Attempts to extract an error for presentation to the user.
 *
 * This can fail, in which case, you should display some default copy appropriate to whatever you were trying to do.
 */
export const extractedErrorObject = async (
  e: unknown
): Promise<ModelError | undefined> => {
  try {
    if (e instanceof Response) {
      const body = await e.json()
      // Fastify tends to return errors as {error: 'SomeCode', message: 'Actual details'}.
      if ('message' in body) {
        const message = body['message']
        const code = body['error']
        return { code, message }
      }
      // Our code instead uses {error: {code, message}}.
      if ('error' in body) {
        const model = ModelErrorFromJSON(body['error'])
        return model
      }
    } else if (e instanceof Error) {
      return {
        code: e.name,
        message: e.message,
      }
    }
  } catch (error) {
    console.warn(
      'Ignoring error thrown while attempting to extract error object',
      {
        errorWeTriedToExtractFrom: e,
        errorWeEncounteredDuringTheAttempt: error,
      }
    )
    return
  }
  return
}

const allTheThings = {
  communities,
  users,
  auth,
  meta,
  programs,
  families,
  teams,
  agreements,
  timezones,
  addressApi,
  user,
  contentApi,
  enrollments,
  userAccountsApi,
  regionsApi,
  paymentsApi,
  invoicesApi,
  rolesApi,
  webhooksApi,
  StudentApi,
  registrantsApi,
  shopifyApi,
  transcriptsApi,
}
export default allTheThings
