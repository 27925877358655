/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  CommunityFilterOption,
  CommunityFilterOptionFromJSON,
  CommunityFilterOptionToJSON,
  DirectorFilterOption,
  DirectorFilterOptionFromJSON,
  DirectorFilterOptionToJSON,
} from './'

/**
 *
 * @export
 * @interface FetchInternationalLicensingFilterOptionsResponse
 */
export interface FetchInternationalLicensingFilterOptionsResponse {
  /**
   * A list of communities for filtering.
   * @type {Array<CommunityFilterOption>}
   * @memberof FetchInternationalLicensingFilterOptionsResponse
   */
  communities: Array<CommunityFilterOption>
  /**
   * A list of directors for filtering.
   * @type {Array<DirectorFilterOption>}
   * @memberof FetchInternationalLicensingFilterOptionsResponse
   */
  directors: Array<DirectorFilterOption>
}

export function FetchInternationalLicensingFilterOptionsResponseFromJSON(
  json: any
): FetchInternationalLicensingFilterOptionsResponse {
  return FetchInternationalLicensingFilterOptionsResponseFromJSONTyped(
    json,
    false
  )
}

export function FetchInternationalLicensingFilterOptionsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FetchInternationalLicensingFilterOptionsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    communities: (json['communities'] as Array<any>).map(
      CommunityFilterOptionFromJSON
    ),
    directors: (json['directors'] as Array<any>).map(
      DirectorFilterOptionFromJSON
    ),
  }
}

export function FetchInternationalLicensingFilterOptionsResponseToJSON(
  value?: FetchInternationalLicensingFilterOptionsResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    communities: (value.communities as Array<any>).map(
      CommunityFilterOptionToJSON
    ),
    directors: (value.directors as Array<any>).map(DirectorFilterOptionToJSON),
  }
}
