import { useMediaQuery, useTheme } from '@mui/material'
import { useAuth } from '../components/Routes/AuthProvider'
import React, { useEffect, useState } from 'react'

export const useSidebarLogic = (): {
  openSidebar: boolean
  toggleOpenSidebar: () => void
  shiftContent: boolean
} => {
  const { isLoggedIn } = useAuth()
  const theme = useTheme()
  const isDesktopOrLarger = useMediaQuery(theme.breakpoints.up('lg'))

  const [wasOpenedDueToWidth, setWasOpenedDueToWidth] =
    useState(isDesktopOrLarger)

  // Closed to start unless both logged-in and on desktop.
  const [open, setOpen] = useState(isLoggedIn && isDesktopOrLarger)

  /* adding useEffect to detect changes before the screen is updated
   * and whenever isDesktopOrLarger changes.
   */
  useEffect(() => {
    // Becomes open if the screen widens to desktop widths.
    if (isLoggedIn && isDesktopOrLarger) {
      if (!wasOpenedDueToWidth) {
        setWasOpenedDueToWidth(true)
      }

      if (!open) {
        setOpen(true)
      }
    }
    // Resets to closed on narrowing below lg breakpoint, but obviously doesn't stay closed forever!
    if (isLoggedIn && wasOpenedDueToWidth && !isDesktopOrLarger) {
      setWasOpenedDueToWidth(false)
      setOpen(false)
    }

    // Resets to closed on logging out.
    if (!isLoggedIn && open) {
      setOpen(false)
    }
  }, [isDesktopOrLarger, isLoggedIn, open, wasOpenedDueToWidth])

  const toggleOpenSidebar = React.useCallback(() => {
    setOpen((it) => !it)
  }, [setOpen])

  const shiftContent = isDesktopOrLarger
  const openSidebar = open
  return { openSidebar, toggleOpenSidebar, shiftContent }
}
