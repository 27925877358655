/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  LicensingInternationalPayment,
  LicensingInternationalPaymentFromJSON,
  LicensingInternationalPaymentToJSON,
  PaginationResponse,
  PaginationResponseFromJSON,
  PaginationResponseToJSON,
} from './'

/**
 *
 * @export
 * @interface FetchLicensingInternationalPaymentsResponse
 */
export interface FetchLicensingInternationalPaymentsResponse {
  /**
   *
   * @type {Array<LicensingInternationalPayment>}
   * @memberof FetchLicensingInternationalPaymentsResponse
   */
  internationalPayments?: Array<LicensingInternationalPayment>
  /**
   *
   * @type {PaginationResponse}
   * @memberof FetchLicensingInternationalPaymentsResponse
   */
  pagination?: PaginationResponse
}

export function FetchLicensingInternationalPaymentsResponseFromJSON(
  json: any
): FetchLicensingInternationalPaymentsResponse {
  return FetchLicensingInternationalPaymentsResponseFromJSONTyped(json, false)
}

export function FetchLicensingInternationalPaymentsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FetchLicensingInternationalPaymentsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    internationalPayments: !exists(json, 'internationalPayments')
      ? undefined
      : (json['internationalPayments'] as Array<any>).map(
          LicensingInternationalPaymentFromJSON
        ),
    pagination: !exists(json, 'pagination')
      ? undefined
      : PaginationResponseFromJSON(json['pagination']),
  }
}

export function FetchLicensingInternationalPaymentsResponseToJSON(
  value?: FetchLicensingInternationalPaymentsResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    internationalPayments:
      value.internationalPayments === undefined
        ? undefined
        : (value.internationalPayments as Array<any>).map(
            LicensingInternationalPaymentToJSON
          ),
    pagination: PaginationResponseToJSON(value.pagination),
  }
}
