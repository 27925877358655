/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface NdaAcknowledgementParam
 */
export interface NdaAcknowledgementParam {
  /**
   * The `assetKey` is the unique identifier for a content asset.
   * @type {number}
   * @memberof NdaAcknowledgementParam
   */
  assetKey: number
  /**
   * Boolean to mark if the item requiring an NDA agreement is acknowledged
   * @type {boolean}
   * @memberof NdaAcknowledgementParam
   */
  ndaAcknowledged: boolean
}

export function NdaAcknowledgementParamFromJSON(
  json: any
): NdaAcknowledgementParam {
  return NdaAcknowledgementParamFromJSONTyped(json, false)
}

export function NdaAcknowledgementParamFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NdaAcknowledgementParam {
  if (json === undefined || json === null) {
    return json
  }
  return {
    assetKey: json['assetKey'],
    ndaAcknowledged: json['ndaAcknowledged'],
  }
}

export function NdaAcknowledgementParamToJSON(
  value?: NdaAcknowledgementParam | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    assetKey: value.assetKey,
    ndaAcknowledged: value.ndaAcknowledged,
  }
}
