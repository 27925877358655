/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 69e5c234 (Wed Jan  8 16:27:40 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  PublicProgramType,
  PublicProgramTypeFromJSON,
  PublicProgramTypeToJSON,
} from './'

/**
 *
 * @export
 * @interface SubmitContactFormParams
 */
export interface SubmitContactFormParams {
  /**
   * A PublicCommunity's id as retrieved from the searchPublicCommunities operation.
   * @type {string}
   * @memberof SubmitContactFormParams
   */
  communityId: string
  /**
   *
   * @type {string}
   * @memberof SubmitContactFormParams
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof SubmitContactFormParams
   */
  lastName?: string
  /**
   *
   * @type {string}
   * @memberof SubmitContactFormParams
   */
  phone?: string
  /**
   *
   * @type {string}
   * @memberof SubmitContactFormParams
   */
  email: string
  /**
   * The querent's postal code.
   * @type {string}
   * @memberof SubmitContactFormParams
   */
  postalCode?: string
  /**
   * The programs the querent is interested in, if they happen to know. Expressing a general interest in the community is also fine.
   * @type {Array<PublicProgramType>}
   * @memberof SubmitContactFormParams
   */
  programs?: Array<PublicProgramType>
  /**
   * When `true`, indicates the querent accepts the Terms of Use and Privacy Policy. Unless set to `true`, a 400 response with error code `InvalidParam` and target of `acceptsTerms` will be returned, with a message indicating the terms must be accepted.
   * @type {boolean}
   * @memberof SubmitContactFormParams
   */
  acceptsTerms?: boolean
}

export function SubmitContactFormParamsFromJSON(
  json: any
): SubmitContactFormParams {
  return SubmitContactFormParamsFromJSONTyped(json, false)
}

export function SubmitContactFormParamsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SubmitContactFormParams {
  if (json === undefined || json === null) {
    return json
  }
  return {
    communityId: json['communityId'],
    firstName: !exists(json, 'firstName') ? undefined : json['firstName'],
    lastName: !exists(json, 'lastName') ? undefined : json['lastName'],
    phone: !exists(json, 'phone') ? undefined : json['phone'],
    email: json['email'],
    postalCode: !exists(json, 'postalCode') ? undefined : json['postalCode'],
    programs: !exists(json, 'programs')
      ? undefined
      : (json['programs'] as Array<any>).map(PublicProgramTypeFromJSON),
    acceptsTerms: !exists(json, 'acceptsTerms')
      ? undefined
      : json['acceptsTerms'],
  }
}

export function SubmitContactFormParamsToJSON(
  value?: SubmitContactFormParams | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    communityId: value.communityId,
    firstName: value.firstName,
    lastName: value.lastName,
    phone: value.phone,
    email: value.email,
    postalCode: value.postalCode,
    programs:
      value.programs === undefined
        ? undefined
        : (value.programs as Array<any>).map(PublicProgramTypeToJSON),
    acceptsTerms: value.acceptsTerms,
  }
}
